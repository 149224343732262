import React from "react";
import {
  Flex,
  Box,
  Divider,
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Center,
  CircularProgress,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import HomeTab from "@layouts/HomeTab";
import HomeHeader from "@layouts/HomeHeader";
import useUser from "@data/useUser";
import { ChevronDownIcon } from "@chakra-ui/icons";
import useEnrollBooks from "@data/useEnrollBooks";
import useBooks from "@data/useBooks";
import moment from "moment";
import useExams from "@data/useExams";
import { useTestModal } from "@hooks/useTestModal";
import { getChapter } from "@services/chapter";
import { Book } from "@type/book";
import { useHistory } from "react-router-dom";
import { convertDateTime } from "@util/time";

const HomeExamPage = () => {
  const history = useHistory();
  const [sort, setSort] = React.useState("recent");
  const { user } = useUser();
  const { enrollBooks } = useEnrollBooks({ id: user.id, test: "test" });
  const book = enrollBooks?.map((el) => `id_in=${el.id}`).join("&");
  const { books } = useBooks({ ids: book });
  const { exams } = useExams({ userId: user?.id, test: "test" });

  const {
    setExam,
    setChapter,
    setType,
    setTest,
    setTitle,
    setSubTitle,
    setRoute,
    TestModal,
    onOpenTestModal,
  } = useTestModal({ testType: "exam" });

  const handleTest = async (book: Book) => {
    const { data } = await getChapter(book.chapters[0].id);
    setChapter(data);
    const currentExams = exams.filter((el) => el.chapter.id === data.id);
    const completed = currentExams.every((el) => el.is_submit);
    const first = currentExams.length === 0;
    const exam = currentExams.find((el) => !el.is_submit);
    const current = exam?.solve_problems.filter((el) => el.resolve).length;

    if (first) {
      setTitle("학습을 시작하시겠습니까?");
      setSubTitle("시작 직후 학습 시간이 카운트 됩니다.");
      setType("new");
    } else if (completed) {
      setTitle(`학습이 완료되었습니다.\n다시 하겠습니까?`);
      setSubTitle("시작 직후 학습 시간이 카운트 됩니다.");
      setType("again");
    } else if (exam && current === 0) {
      setExam(exam);
      setTitle("임시 저장된 학습이력이 있습니다.\n이어서 하시겠어요?");
      const date = exam.solve_problems
        .map((el) => new Date(el.updated_at) as any)
        .sort((a, b) => b - a)
        .pop();
      const text = convertDateTime(date);
      setSubTitle(text);
      setType("never");
    } else if (exam && current) {
      setExam(exam);
      const date = exam.solve_problems
        .map((el) => new Date(el.updated_at) as any)
        .sort((a, b) => b - a)
        .pop();
      const text = convertDateTime(date);
      setTitle(`임시 저장된 학습이력이 있습니다.\n이어서 하시겠어요?`);
      setSubTitle(text);
      setType("continue");
    }

    setTest("test");
    onOpenTestModal();
  };

  if (enrollBooks === undefined && books === undefined ) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  return (
    <>
      <TestModal />
      <Helmet
        title="홈페이지"
        keywords="홈페이지"
        description="홈페이지페이지 입니다."
      />
      <Layout title="모의고사" noArrow>
        <Box>
          <HomeHeader />
          <HomeTab />
          <Divider />
          <Flex p={4} px={6} justify="flex-end">
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    size="sm"
                    bg="#fff"
                    borderWidth={1}
                    rounded="none"
                    color="gray.400"
                    fontSize="14px"
                  >
                    {sort === "recent" ? "최신순" : "이름순"}
                  </MenuButton>
                  <MenuList minW="auto" w="84px" rounded="none">
                    {[
                      { value: "recent", label: "최신순" },
                      { value: "name", label: "이름순" },
                    ].map((el) => (
                      <MenuItem
                        fontSize="14px"
                        color="gray.400"
                        key={el.value}
                        onClick={() => setSort(el.value)}
                      >
                        {el.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
          <Box>
            {books == undefined ? 
            <Box px={6}><Text color="gray.600" fontSize={"15px"}>아직 모의 고사 교재가 없습니다. 등록해주세요.</Text></Box> 
            : books.sort((a, b) => {
                if (sort === "name") {
                  return b.name < a.name ? 1 : -1;
                } else {
                  return +b.id - +a.id;
                }
              })
              .map((el) => (
                <Flex
                  key={el.id}
                  px={6}
                  borderBottomWidth={1}
                  _hover={{ bg: "gray.50" }}
                  cursor="pointer"
                  h="100px"
                  align="center"
                >
                  <Box w="full">
                    <Text fontWeight="500">{el.name}</Text>
                    <Text color="gray.400" fontSize={"12px"}>
                      {`${el.problems.length}문항`}
                    </Text>
                    <Text color="gray.300" fontSize={"10px"}>
                      {moment(el.created_at).format("YY.MM.DD")}
                    </Text>
                    <HStack justify="flex-end">
                      <Button
                        color="primary"
                        size="xs"
                        bg="white"
                        borderWidth={1}
                        borderColor="primary"
                        rounded="sm"
                        fontSize="10px"
                        _hover={{ bg: "gray.100" }}
                        onClick={() => {
                          history.push(
                            `/book/${el.id}/chapter/${el.chapters[0].id}/answer`
                          );
                        }}
                      >
                        해설보기(E)
                      </Button>
                      <Button
                        color="primary"
                        size="xs"
                        bg="white"
                        borderWidth={1}
                        borderColor="primary"
                        rounded="sm"
                        fontSize="10px"
                        _hover={{ bg: "gray.100" }}
                        onClick={() => {
                          setRoute("omr");
                          handleTest(el);
                        }}
                      >
                        OMR
                      </Button>
                      <Button
                        color="white"
                        size="xs"
                        bg="primary"
                        borderWidth={1}
                        borderColor="primary"
                        rounded="sm"
                        fontSize="10px"
                        _hover={{ bg: "primary", opacity: 0.8 }}
                        onClick={() => {
                          setRoute("chapter");
                          handleTest(el);
                        }}
                      >
                        학습하기
                      </Button>
                    </HStack>
                  </Box>
                </Flex>
              ))}
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default HomeExamPage;

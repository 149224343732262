import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  Box,
  Center,
  Divider,
  Heading,
  Text,
  CircularProgress,
  Flex,
  Button,
  Textarea,
  Avatar as Face,
  useDisclosure,
  useToast,
  Image,
  Container,
  Tag,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import useUser from "@data/useUser";
import useQuestion from "@data/useQuestion";
import useAnswers from "@data/useAnswers";
import { deleteAnswer, addAnswer } from "@services/answer";
import { deleteQuestion, updateQuestion } from "@services/question";
import { useCommentModal } from "@hooks/useCommentModal";
import { AnswerPost } from "@type/answer";
import Avatar from "@components/Avatar";
import moment from "moment";
import PostDeleteModal from "@components/modal/PostDeleteModal";
import SelectModal from "@components/modal/SelectModal";

const URL = "https://daesung.s3.ap-northeast-2.amazonaws.com/";

export interface RouteParams {
  questionId: string;
}

const QuestionPage = () => {
  const history = useHistory();
  const toast = useToast();
  const postDelete = useDisclosure();
  const questionSelect = useDisclosure();
  const { questionId } = useParams<RouteParams>();
  const { user } = useUser();
  const { question, questionMutate } = useQuestion({ id: questionId });
  const { answers, answersMutate } = useAnswers(questionId);
  const [answer, setAnswer] = React.useState("");
  const [selectedAnswer, setSelectedAnswer] = React.useState<number | null>(
    null
  );
  const { handleEdit, EditModal } = useCommentModal();

  if (!question || !answers)
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );

  const handleAddAnswer = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!answer)
      return toast({
        title: "포텐업",
        description: "내용을 입력해주세요.",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    const data = {
      question: questionId,
      title: answer,
      writer: user?.id,
      selected: false,
    } as AnswerPost;
    await addAnswer(data);
    setAnswer("");
    answersMutate();
  };

  const handleDeleteAnswer = async (id: string) => {
    await deleteAnswer(id);
    answersMutate();
  };

  const handleSelectAnswer = async () => {
    const body = { answer: selectedAnswer as number };
    await updateQuestion({ id: questionId, body });
    questionMutate();
  };

  const handleDelete = async () => {
    await deleteQuestion(questionId);
    questionMutate();
    history.goBack();
  };

  const isOwner = question.writer.id === user.id;
  const selected = question.answer;

  return (
    <>
      <SelectModal
        isOpen={questionSelect.isOpen}
        onClose={questionSelect.onClose}
        callback={handleSelectAnswer}
      />
      <PostDeleteModal
        isOpen={postDelete.isOpen}
        onClose={postDelete.onClose}
        callback={handleDelete}
      />
      <EditModal />
      <Helmet
        title={`프로그램문의 - ${question.title}`}
        keywords="프로그램문의"
        description="프로그램문의페이지 입니다."
      />
      <Layout title="자유게시판" onBack={()=>history.push('/')}>
        <Box pb={2}>
          <Box px={4}>
            {question.problem && (
              <Container maxW="md" mb={4} py={4}>
                <Image src={URL + question.problem.img} />
              </Container>
            )}
            <Heading size="lg" fontWeight="500" fontSize="20px" mt={5} mb={3}>
              {question.title}
            </Heading>
            <Flex align="center">
              {question.hide_grade ? (
                <Face src={question.writer.avatar} />
              ) : (
                <Avatar user={question.writer} />
              )}
              <Box ml={3} fontSize="12px" color="gray.400">
                <Text>{question.writer.nick_name}</Text>
                <Text>{moment(question.created_at).format("YY.MM.DD")}</Text>
                {isOwner && (
                  <Flex align="center">
                    <Link to={`/questions/${question.id}/edit`}>
                      <Button variant="ghost" size="xs" px={0} fontSize="12px">
                        수정
                      </Button>
                    </Link>
                    <Divider
                      orientation="vertical"
                      mx={1}
                      h="10px"
                      w={"2px"}
                      bg="gray.300"
                    />
                    <Button
                      variant="ghost"
                      size="xs"
                      px={0}
                      fontSize="12px"
                      onClick={postDelete.onOpen}
                    >
                      삭제
                    </Button>
                  </Flex>
                )}
              </Box>
            </Flex>
          </Box>
          <Divider my={2}></Divider>
          <Box py={2} px={4}>
            <div
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{ __html: question.desc }}
            ></div>
          </Box>
          <Divider my={2}></Divider>
          <Text p={2} px={4}>
            {`댓글 ${answers.length}`}
          </Text>
          <Box px={2} mb={8}>
            <form onSubmit={handleAddAnswer}>
              <Box p={2} bg="gray.100">
                <Textarea
                  placeholder="댓글을 입력하세요"
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  border="none"
                />
                <Divider my={2}></Divider>
                <Flex justify="flex-end">
                  <Button type="submit" bg="primary" color="white">
                    등록하기
                  </Button>
                </Flex>
              </Box>
            </form>
          </Box>
          <Box px={4}>
            {answers.map((answer) => (
              <Box py={2} borderBottomWidth={1} key={answer.id}>
                <Flex align="center" justify="space-between">
                  <Flex align="center" fontSize="12px" color="gray.400">
                    {+answer.id === question.answer && (
                      <Tag bg="primary" color="white" size="sm" mr={2}>
                        채택
                      </Tag>
                    )}
                    <Text mr={4}>
                      {answer?.writer?.nick_name || "닉네임없음"}
                    </Text>
                    <Text>{moment(answer.created_at).format("YY.MM.DD")}</Text>
                  </Flex>
                  <Flex align="center" color="gray.400">
                    {answer.writer.id === user.id && (
                      <>
                        <Button
                          variant="ghost"
                          size="xs"
                          px={1}
                          fontSize="12px"
                          onClick={() =>
                            handleEdit({ id: answer.id, value: answer.title })
                          }
                        >
                          수정
                        </Button>
                        <Divider
                          orientation="vertical"
                          mx={1}
                          h="10px"
                          w={"2px"}
                          bg="gray.300"
                        />
                        <Button
                          variant="ghost"
                          size="xs"
                          px={1}
                          fontSize="12px"
                          onClick={() => handleDeleteAnswer(answer.id)}
                        >
                          삭제
                        </Button>
                      </>
                    )}
                    {!selected && (
                      <>
                        <Button
                          size="xs"
                          ml={2}
                          fontSize="12px"
                          onClick={() => {
                            questionSelect.onOpen();
                            setSelectedAnswer(+answer.id);
                          }}
                        >
                          채택
                        </Button>
                      </>
                    )}
                  </Flex>
                </Flex>
                <Text color="gray.900">{answer.title}</Text>
              </Box>
            ))}
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default QuestionPage;

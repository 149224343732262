import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Heading,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Helmet from "@layouts/Helmet";
import { forgetPassword } from "@services/auth";
import AuthContainer from "@components/AuthContainer";
import AlertModal from "@components/modal/AlertModal";

const PasswordResetPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [success, setSuccess] = React.useState(false);

  return (
    <>
      <AlertModal
        title="가입되지 않은 이메일입니다."
        subTitle="이메일을 다시 확인해주세요."
        isOpen={isOpen}
        onClose={onClose}
      />
      <Helmet
        title="비밀번호 재설정"
        keywords="비밀번호 재설정"
        description="비밀번호 재설정페이지 입니다."
      />
      <AuthContainer>
        {!success ? (
          <>
            <Box textAlign="center" py={4} color={"gray.800"}>
              <Heading fontSize={"20px"}>비밀번호 재설정</Heading>
              <Text mt={2} fontSize="14px" color="gray.400" px={"10px"}>
                포텐셜 버전 업그레이드로 인해
                <br />
                기존 회원의 비밀번호 재설정이 필요합니다.
                <br />
                재설정 후 새로워진 포텐셜을 만나보세요.
              </Text>
            </Box>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("이메일을 확인하세요.")
                  .required("이메일을 입력하세요."),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                const { email } = values;
                try {
                  await forgetPassword({ email });
                  setSubmitting(false);
                  setSuccess(true);
                } catch (err) {
                  onOpen();
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {({ isValid, dirty, isSubmitting }) => (
                <Form>
                  <Field name="email">
                    {({ field, form }: any) => (
                      <FormControl
                        h="100px"
                        isInvalid={form.errors.email && form.touched.email}
                      >
                        <FormLabel color={"gray.400"} fontSize="14px">
                          회원가입한 이메일
                        </FormLabel>
                        <Input
                          {...field}
                          id="email"
                          placeholder="potential@email.com"
                        />
                        <FormErrorMessage justifyContent="flex-end" mt={1}>
                          {form.errors.email}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Button
                    bg={isValid && dirty ? "primary" : "gray.300"}
                    color={"white"}
                    isLoading={isSubmitting}
                    type="submit"
                    isFullWidth
                    h={"44px"}
                    borderRadius={4}
                    disabled={!(isValid && dirty) || isSubmitting}
                  >
                    비밀번호 재설정 링크 보내기
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <Box textAlign="center" color={"gray.800"}>
            <Box py={12}>
              <Heading fontSize={"18px"} lineHeight="30px">
                입력하신 이메일 주소로
                <br />
                비밀번호 재설정 링크를 보내드렸어요.
              </Heading>
              <Text mt={2} fontSize="14px" color="gray.400">
                비밀번호를 재설정한 뒤 다시 로그인하세요.
              </Text>
            </Box>
            <Link to="/login">
              <Button
                px={8}
                bg={"primary"}
                color={"white"}
                type="submit"
                isFullWidth
                mt={8}
                h={"44px"}
                borderRadius={4}
              >
                로그인으로 돌아가기
              </Button>
            </Link>
          </Box>
        )}
      </AuthContainer>
    </>
  );
};

export default PasswordResetPage;

import useSWR from "swr";
import { Note } from "@type/note";

const useNote = ({ id }: { id: string | undefined }) => {
  const { data, error, mutate } = useSWR(id ? `notes/${id}` : null);
  return {
    isLoading: !error && !data,
    isError: error,
    note: data,
    noteMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    note: Note;
    noteMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useNote;

import axios from "axios";
import { QuestionPost, QuestionPut } from "../types/question";

export const addQuestion = (body: QuestionPost) => {
  return axios.post(`questions`, body);
};

export const updateQuestion = ({
  id,
  body,
}: {
  id: string;
  body: QuestionPut;
}) => {
  return axios.put(`questions/${id}`, body);
};

export const deleteQuestion = (id: string) => {
  return axios.delete(`questions/${id}`);
};

import useSWR from "swr";
import { NoteProblem } from "@type/noteProblem";

const useNoteProblems = ({
  id,
  note,
}: {
  id: string | undefined;
  note: string | undefined;
}) => {
  const { data, error, mutate } = useSWR(
    id ? `note-problems?user=${id}&note_exam=${note}` : null
  );
  return {
    isLoading: !error && !data,
    isError: error,
    noteProblems: data,
    noteProblemsMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    noteProblems: NoteProblem[];
    noteProblemsMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useNoteProblems;

import React from "react";
import { Flex, Text, Center, Image } from "@chakra-ui/react";
import { FiLock } from "react-icons/fi";
import { Question } from "@type/question";
import moment from "moment";
import { extractSrc } from "@util/reg";

const mapType = {
  book: "교재",
  homework: "과제",
  test: "모의고사",
};

const QuestionCard = ({ question }: { question: Question }) => {
  const answered = question.answer;
  const image = extractSrc(question.desc);

  return (
    <Flex
      w="full"
      bg="white"
      p={4}
      shadow="sm"
      borderBottomWidth={1}
      align="center"
      justify="space-between"
      fontSize="14px"
    >
      <Flex align="center" minW="0">
        <Text color="gray.400" w="9" textAlign="center">
          {question.id}
        </Text>
        {question.hide && <FiLock color="#b0b0b0" />}
        <Text ml={2} mr={2} color="gray.400">
          {mapType[question.type]}
        </Text>
        <Text
          flex={2}
          color="gray.900"
          fontWeight="500"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {question.title}
        </Text>
      </Flex>
      <Flex align="center" flexShrink={0}>
        {image && <Image src={image} h={10} w={10} mr={2}></Image>}
        <Text fontWeight="500" color="gray.400">
          {moment(question.created_at).format("YY.MM.DD")}
        </Text>
        <Center
          w="58px"
          h="22px"
          fontSize="10px"
          bg={answered ? "primary" : "gray.300"}
          color="white"
          ml="3"
        >
          {answered ? "답변 완료" : "답변 대기"}
        </Center>
      </Flex>
    </Flex>
  );
};

export default QuestionCard;

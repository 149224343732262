import useSWR from "swr";
import { Note } from "@type/note";

const useNotes = ({ id }: { id: string | undefined }) => {
  const { data, error, mutate } = useSWR(id ? `notes?user=${id}` : null);
  return {
    isLoading: !error && !data,
    isError: error,
    notes: data,
    notesMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    notes: Note[];
    notesMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useNotes;

import React from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  CircularProgress,
  HStack,
  Text,
  Center,
  Container,
  Flex, Heading,
  Button,
  IconButton,
  useDisclosure,
  Input, Box,
  Spacer, Table,
  Thead,
  Tbody, Wrap,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import OmrHeader from "@layouts/OmrHeader";
import useUser from "@data/useUser";
import usePreventBack from "@hooks/usePreventBack";
import useSolveProblems from "@data/useSolveProblems";
import { convertMin, convertTimeFormat } from "@util/time";
import { SolveProblem } from "@type/solveProblem";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import AnswerButton from "@components/AnswerButton";
import AnswerInput from "@components/AnswerInput";
import SubmitModal from "@components/modal/SubmitModal";
import { updateProblem } from "@services/problem";
import { updateExam } from "@services/exam";
import { AiOutlineLeft } from "react-icons/ai";
import useNote from "@data/useNote";
import moment from "moment";
import useNoteExam from "@data/useNoteExam";

const AnswerPreviewContainer = React.memo(
  ({ problem, mutate }: { problem: SolveProblem; mutate: () => void }) => {
    const { min, sec } = convertTimeFormat(problem.solve_time);
    const [answer, setAnswer] = React.useState(problem.selected);
    const [minute, setMinute] = React.useState(min || 0);
    const [second, setSecond] = React.useState(sec || 0);


    const handleCheck = async (problem: any) => {
      const body = { check: !problem.check };
      await updateProblem({ id: problem.id, body });
      await mutate();
    };

    React.useEffect(() => {
      if (answer === problem.selected && minute === min && second === sec)
        return;
      const body = {
        selected: answer,
        answer: problem.problem.answer === answer,
        timeover: false,
        resolve: true,
        solve_time: minute * 60 + second,
      };
      updateProblem({ id: problem.id, body });
      mutate();
    }, [min, sec, mutate, problem, answer, minute, second]);

    return (
      <Flex align="center">
        <IconButton
          onClick={() => handleCheck(problem)}
          variant="ghost"
          aria-label="check"
          icon={
            problem.check ? (
              <AiFillStar color="#6666FF" />
            ) : (
              <AiOutlineStar color="#DDDDDD" />
            )
          }
        />
        <Center
          w="40px"
          h="44px"
          bg="gray.100"
          fontSize="14px"
          textDecoration="underline"
        >
          {problem.num}
        </Center>
        <HStack ml={2}>
          {problem.problem.type === 1 ? (
            [1, 2, 3, 4, 5].map((selected) => (
              <AnswerButton
                key={selected}
                text={+selected}
                selected={selected === answer}
                handleClick={() => setAnswer(selected)}
              />
            ))
          ) : (
            <AnswerInput value={answer} onChange={setAnswer} omr />
          )}
        </HStack>
        <Spacer />
        <Input
          defaultValue={min}
          fontSize="12px"
          h={6}
          w={6}
          p={1}
          rounded="md"
          textAlign="center"
          placeholder="0"
          type="number"
          onChange={(e) => setMinute(+e.target.value)}
        />
        <Text fontSize="sm" mx={1}>
          분
        </Text>
        <Input
          defaultValue={sec}
          fontSize="12px"
          h={6}
          w={6}
          p={1}
          rounded="md"
          textAlign="center"
          placeholder="0"
          mx={1}
          type="number"
          onChange={(e) => setSecond(+e.target.value)}
        />
        <Text fontSize="sm">초</Text>
      </Flex>
    );
  }
);

export interface RouteParams {
  noteId: string,
  examId: string;
}

const NotePreviewPage = () => {
  const history = useHistory();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { noteId, examId } = useParams<RouteParams>();
  const { noteExam } = useNoteExam({ id: examId });
  const { user } = useUser();
  console.log(noteExam)
  const { solveProblems, solveProblemsMutate } = useSolveProblems({
    id: user?.id,
    exam: examId,
  });
  const { setPrevent, PreventModal } = usePreventBack();

  const handleSubmit = async () => {
    await updateExam({ id: examId, body: { is_submit: true } });
    setPrevent(false);
    history.goBack();
  };

  if (!solveProblems) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }
  const isEnd = solveProblems?.every((el) => el.resolve);
  const total = solveProblems.reduce((acc, cur) => acc + cur.solve_time, 0);

  return (
    <>
      <PreventModal />
      <SubmitModal isOpen={isOpen} onClose={onClose} callback={handleSubmit} />
      <Helmet
        title="오답노트 학습하기"
        keywords="오답노트 학습하기"
        description="오답노트 학습하기 페이지 입니다."
      />
      <Layout title="오답노트 학습하기" hide={false} onBack={()=>history.push('/home-note')}>
        <Flex
          position="sticky"
          top="0"
          bg="white"
          h="44px"
          zIndex={100}
          align="center"
          px={2}
          justify="space-between"
          borderBottomWidth={1}
        >
          <Flex flex={1} h="full">
            <IconButton
              onClick={() => history.push(`/note/${noteId}`)}
              aria-label="back"
              variant="link" icon={<AiOutlineLeft />}
              fontSize={20}
            />
          </Flex>
          <Text flex={1} fontWeight="bold" fontSize="lg" textAlign="center">
            오답노트 학습하기
          </Text>
          <Flex flex={1} justifyContent="flex-end">

          </Flex>
        </Flex>
        <Flex
          px={6}
          h="100px"
          borderBottomWidth={1}
          _hover={{ bg: "gray.50" }}
          cursor="pointer"
          align="left"
          direction="column"
        >
          <Flex
            h="90px"
            align="center"
          >
            <Box w={"50px"} h={"50px"} bg="gray.100" mr={3}>
              <Center h="full" fontSize="xs">
                오답노트
              </Center>
            </Box>
            <Box>
              <Heading fontSize="16px" fontWeight="500" mb={1}>
                {noteExam?.note?.name}
              </Heading>
              <Text color="gray.300" fontSize={"10px"}>
                {moment(noteExam?.created_at).format("YY.MM.DD")}
              </Text>
            </Box>
            <Spacer />
            <Button
              bg="primary"
              color="white"
              size="md"
              onClick={() => { history.push(`/note/${noteId}`) }}
            >
              학습하기
              </Button>
          </Flex>
        </Flex>
        <Container maxW="100%" h="calc(100% - 100px)" overflowY="auto">
          <Box
            p={5}
            m={5}
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
          >
            <Text fontSize="sm">나의 총 풀이 시간: 2분 6초</Text>
            <Text fontSize="sm">동일 등급 총 풀이시간: 5분 53초</Text>
          </Box>
          <Table variant="simple" colorScheme="gray" size="sm">
            <TableCaption>입력하지 않은 문제는 오답처리 됩니다.</TableCaption>
            <Thead>
              <Tr bg="blue" color='gray.50'>
                <Th color='gray.50'>No</Th>
                <Th textAlign="center" color='gray.50'>정답</Th>
                <Th textAlign="center" color='gray.50'>다시<br />풀기</Th>
                <Th textAlign="center" color='gray.50'>풀이<br />시간</Th>
                <Th textAlign="center" color='gray.50'>2등급<br />풀이<br />시간</Th>
                <Th textAlign="center" color='gray.50'>1등급<br />풀이<br />시간</Th>
              </Tr>
            </Thead>
            <Tbody>
              {solveProblems
                .sort((a, b) => a.problem.number - b.problem.number)
                .map((el) => (
                  <Tr key={el.id}>
                    <Td>{`${el.num}`}</Td>
                    <Td textAlign="center">{el.timeover ? (<span style={{color:"#FF7373"}} >T</span>) 
                    : (el.answer?  (<span style={{color:"#6666FF"}} >O</span>) :  (<span style={{color:"#FF7373"}} >X</span>) )}</Td>
                    <Td textAlign="center">{el?.check ? (
                  <div ><div style={{display: 'inline-block', marginBottom: '-2px'}}><AiFillStar color="#6666FF" /> </div> 
                  <span style={{color:"#6666FF"}} ></span></div>
                ) : (
                  <div ><div style={{display: 'inline-block', marginBottom: '-2px'}}><AiFillStar color="#DDDDDD" /> </div> 
                  <span style={{color:"#DDDDDD"}} ></span></div>
                )}</Td>
                    <Td textAlign="center">{`${convertMin(el.solve_time)}`}</Td>
                    <Td textAlign="center">{`${convertMin(el.solve_time)}`}</Td>
                    <Td textAlign="center">{`${convertMin(el.solve_time)}`}</Td>
                  </Tr>
                ))}
            </Tbody>

          </Table>
        </Container>
      </Layout>
    </>
  );
};

export default NotePreviewPage;

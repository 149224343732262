import React from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  CircularProgress,
  Center,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
  InputGroup,Button,
  Input,Image,
  InputRightElement,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import { FiXCircle, FiSearch } from "react-icons/fi";
import { AiOutlineArrowUp } from "react-icons/ai";
import useNote from "@data/useNote";
import MathView2 from '@components/MathView2';
import { TriangleDownIcon } from '@chakra-ui/icons'

export interface RouteParams {
  noteId: string;
}
const URL = "https://daesung.s3.ap-northeast-2.amazonaws.com/";

const AnswerPage = () => {
  const history = useHistory();

  const [search, setSearch] = React.useState("");
  const { noteId } = useParams<RouteParams>();
  const { note } = useNote({ id: noteId });
  const print = () =>{
    window.print();
  }
  if (!note) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  const showToTop = note && note.problems.length > 10;

  return (
    <>
      <Helmet
        title="해설보기"
        keywords="해설보기"
        description="해설보기페이지 입니다."
      />
      <Layout title="해설보기" onBack={()=>{
        history.push(`/home-note`)
      }}>
        <Box flex={1} bg="gray.100" p={4}>
          <Box px={10} pb={4}>
            <InputGroup size="md">
              <Input
                value={search}
                placeholder="문항번호를 조회하세요."
                bg="white"
                rounded="full"
                onChange={(e) => setSearch(e.target.value)}
                _focus={{ borderColor: "primary" }}
              />
              <InputRightElement width="4.5rem">
                <FiXCircle
                  onClick={() => setSearch("")}
                  color={search ? "#6666FF" : "#fff"}
                />
                <Box mx={1} />
                <FiSearch size="20px" color="#6666FF" />
              </InputRightElement>
            </InputGroup>
            <Button onClick={print} leftIcon={<TriangleDownIcon />} 
          colorScheme="teal" variant="solid">
            PDF
          </Button>
          </Box>
            {note.problems.map((el) => (
            
              <div key={el.id}>
              
              <Box flex="1" textAlign="left">
              {`${el.number}번`}
              <h4>문제</h4>
              <Image src={URL + el.img} w="440px" mx={"auto"}/>
              <MathView2 value={el.content}></MathView2>
              <h4>해설</h4>
              <MathView2 value={el.solution}></MathView2>
              <Image src={el.solutionPic?.url} w="440px" mx={"auto"}/>
              <h4>정답</h4>
              {`${el.answer}`}
              </Box>
              <div style={{pageBreakBefore:"always"}}></div>
              <br style={{height:0, lineHeight:0}}/>
            </div>
            ))}
          {showToTop && (
            <>
              <Box h="72px" />
              <Center
                position="fixed"
                bg="white"
                shadow="md"
                rounded="full"
                w="40px"
                h="40px"
                left="calc(50% - 20px)"
                top="calc(100vh - 56px)"
                zIndex={100}
                cursor="pointer"
                _hover={{ bg: "gray.50" }}
                onClick={() =>
                  window.scroll({ top: 0, left: 0, behavior: "smooth" })
                }
              >
                <AiOutlineArrowUp />
              </Center>
            </>
          )}
        </Box>
      </Layout>
    </>
  );
};

export default AnswerPage;

import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Center,
  CircularProgress,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  InputGroup,
  Input,
  InputRightElement,
  Image,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import PostHeader from "@layouts/PostHeader";
import usePosts from "@data/usePosts";
import PostCard from "@components/PostCard";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiEdit } from "react-icons/fi";
import { FiXCircle, FiSearch } from "react-icons/fi";
import rect_lg from "@assets/icons/rect_lg.png";
import rect_sm from "@assets/icons/rect_sm.png";
import MobileDrawer from "@layouts/MobileDrawer";

const PostsPage = () => {
  const history = useHistory();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const inputRef = React.useRef<any>(null);
  const {
    posts,
    sort,
    setSort,
    search,
    setSearch,
    setSize,
    postsMutate,
    isReachingEnd,
    isLoading,
  } = usePosts();

  React.useEffect(() => {
    postsMutate();
  }, [postsMutate]);

  if (!posts)
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );

  const handleMore = () => setSize((value: number) => value + 1);

  return (
    <>
      <MobileDrawer isOpen={isOpen} onClose={onClose} />
      <Helmet
        title="자유게시판"
        keywords="자유게시판"
        description="자유게시판페이지 입니다."
      />
      <Layout title="자유게시판" hide={false} onBack={()=>history.push('/')}>
        <PostHeader title="자유게시판" onOpen={onOpen} theme="primary" />
        <Box pb={2}>
          <Box h="80px" position="relative" overflowY="hidden">
            <Center h="80px" bg="#5544D4">
              <form>
                <InputGroup size="md" w="327px" zIndex={10}>
                  <Input
                    ref={inputRef}
                    color="#fff"
                    bg="#3C2AA9"
                    borderWidth={0}
                    rounded="full"
                    onChange={(e) => setSearch(e.target.value)}
                    _focus={{ borderColor: "primary" }}
                  ></Input>
                  <InputRightElement width="4.5rem">
                    <FiXCircle
                      onClick={() => {
                        setSearch("");
                        if (inputRef && inputRef.current) {
                          inputRef.current.value = "";
                        }
                      }}
                      color={search ? "#fff" : "#3C2AA9"}
                    />
                    <Box mx={1} />
                    <FiSearch size="20px" color="#fff" />
                  </InputRightElement>
                </InputGroup>
              </form>
            </Center>
            <Box position="absolute" top="10px" left="63%">
              <Box position="relative" w="100px" h="100px" ml="-30px">
                <Image
                  src={rect_lg}
                  h="100px"
                  position="absolute"
                  top="0"
                  left="0px"
                />
                <Image
                  src={rect_sm}
                  h="60px"
                  position="absolute"
                  top="40px"
                  left="30px"
                />
              </Box>
            </Box>
          </Box>
          <Flex p={2} px={4} justify="flex-end">
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    size="sm"
                    bg="#fff"
                    borderWidth={1}
                    rounded="none"
                    color="gray.400"
                    fontSize="14px"
                  >
                    {sort === "view" && "조회순"}
                    {sort === "like" && "좋아요순"}
                    {sort === "comment" && "댓글순"}
                    {sort === "recent" && "최신순"}
                  </MenuButton>
                  <MenuList minW="auto" w="84px" rounded="none">
                    {[
                      { value: "view", label: "조회순" },
                      { value: "like", label: "좋아요순" },
                      { value: "comment", label: "댓글순" },
                      { value: "recent", label: "최신순" },
                    ].map((el) => (
                      <MenuItem
                        fontSize="14px"
                        color="gray.400"
                        key={el.value}
                        onClick={() => setSort(el.value)}
                      >
                        {el.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
          <Box borderTopWidth={1}>
            {posts.map((post) => (
              <Link to={`/posts/${post.id}`} key={post.id}>
                <PostCard post={post} />
              </Link>
            ))}
            {!isReachingEnd && !isLoading && (
              <Flex justify="center" my={4}>
                <Button bg="primary" color="white" onClick={handleMore}>
                  더보기
                </Button>
              </Flex>
            )}
          </Box>
        </Box>
        <Link to={`/post-add`}>
          <Center
            position="fixed"
            bg="primary"
            shadow="md"
            rounded="full"
            w="62px"
            h="62px"
            right="24px"
            bottom="30px"
            zIndex={100}
            cursor="pointer"
            onClick={() =>
              window.scroll({ top: 0, left: 0, behavior: "smooth" })
            }
          >
            <FiEdit color="white" fontSize="26px" />
          </Center>
        </Link>
      </Layout>
    </>
  );
};

export default PostsPage;

import useSWR from "swr";
import { NoteExam } from "@type/noteExam";

const useNoteExam = ({
  id,
  type,
}: {
  id: string | undefined;
  type?: string | undefined;
}) => {
  const { data, error, mutate } = useSWR(() => {
    let query = "";
    let isTest = "";
    if (type === "all") query = "";
    if (type === "book") query = "&book.name_ncontains=과제";
    if (type === "homework") query = "&book.name_contains=과제";
    return id ? `note-exams/${id}`: null
  });
  return {
    noteExam: data,
    isLoading: !error && !data,
    isError: error,
    noteExamsMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    noteExam: NoteExam;
    noteExamsMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useNoteExam;

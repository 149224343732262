import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { SWRConfig } from "swr";
import { setAxios, fetcher } from "./utils/axios";
import { theme } from "./utils/theme";

const render = () => {
  const App = require("./App").default;
  setAxios();
  ReactDOM.render(
    <ChakraProvider resetCSS theme={theme}>
      <SWRConfig value={{ fetcher }}>
        <App />
      </SWRConfig>
    </ChakraProvider>,
    document.getElementById("root")
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}

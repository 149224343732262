import {
  Button,
  Alert,
  AlertIcon,
  Container,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import PasswordField from "@components/PasswordField";
import { updateUser } from "@services/auth";
import useUser from "@data/useUser";
import { useHistory } from "react-router-dom";

const MyPasswordPage = () => {
  const { user, userMutate } = useUser();
  const toast = useToast();
  const history = useHistory();
  return (
    <>
      <Helmet
        title="비밀번호 재설정"
        keywords="비밀번호 재설정"
        description="비밀번호 재설정페이지 입니다."
      />
      <Layout title="비밀번호" onBack={()=> history.push('/')}>
        <Container maxW="375px">
          <Heading fontSize="20px" mt={10} mb={5} textAlign="center">
            비밀번호 재설정하기
          </Heading>
          <Formik
            initialValues={{
              password: "",
              passwordConfirm: "",
            }}
            validationSchema={Yup.object({
              password: Yup.string().required("비밀번호를 입력하세요."),
              passwordConfirm: Yup.string()
                .oneOf(
                  [Yup.ref("password"), null],
                  "처음 입력한 비밀번호와 일치하지 않습니다."
                )
                .required("비밀번호를 입력하세요"),
            })}
            onSubmit={async (values, { setStatus, setSubmitting }) => {
              setSubmitting(true);
              setStatus("");
              const { password } = values;
              try {
                const body = { password };
                await updateUser({ id: user.id, body });
                userMutate();
                toast({
                  title: "POTENTIAL",
                  description: "정보를 성공적으로 수정하였습니다.",
                  status: "success",
                  duration: 4000,
                  isClosable: true,
                  position: "top",
                });
              } catch (err) {
                console.log(err);
                setStatus("이메일을 확인하세요");
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, status }) => (
              <Form>
                {status && (
                  <Alert status="error" borderRadius={4}>
                    <AlertIcon />
                    {status}
                  </Alert>
                )}
                <Field name="password">
                  {({ field, form }: any) => (
                    <PasswordField
                      placeholder="패스워드"
                      field={field}
                      form={form}
                      passType="password"
                    />
                  )}
                </Field>
                <Field name="passwordConfirm">
                  {({ field, form }: any) => (
                    <PasswordField
                      placeholder="패스워드 확인"
                      field={field}
                      form={form}
                      passType="passwordConfirm"
                    />
                  )}
                </Field>
                <Button
                  px={8}
                  bg={"primary"}
                  color={"white"}
                  isLoading={isSubmitting}
                  type="submit"
                  isFullWidth
                  h={"44px"}
                  borderRadius={4}
                >
                  비밀번호 재설정하기
                </Button>
              </Form>
            )}
          </Formik>
        </Container>
      </Layout>
    </>
  );
};

export default MyPasswordPage;

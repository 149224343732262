import React from "react";
import { useParams, useHistory, Redirect } from "react-router-dom";
import {
  Box,
  Input,
  Button,
  Flex,
  Divider,
  Checkbox,
  useToast,
  useDisclosure,
  Center,
  CircularProgress,
  Image,
  Container,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import useUser from "@data/useUser";
import useQuestions from "@data/useQuestions";
import { updateQuestion } from "@services/question";
import { QuestionPut } from "@type/question";
import PostAddModal from "@components/modal/PostAddModal";
import QuillEditor from "@components/QuillEditor";
import usePreventEditBack from "@hooks/usePreventEditBack";
import useQuestion from "@data/useQuestion";

const URL = "https://daesung.s3.ap-northeast-2.amazonaws.com/";

export interface RouteParams {
  questionId: string;
}

const QuestionEditPage = () => {
  const { questionId } = useParams<RouteParams>();
  const { question } = useQuestion({ id: questionId });
  const postAdd = useDisclosure();
  const { setFinishStatus, PreventModal } = usePreventEditBack();
  const toast = useToast();
  const { user } = useUser();
  const { questionsMutate } = useQuestions();
  const history = useHistory();

  const [title, setTitle] = React.useState("");
  const [showGrade, setShowGrade] = React.useState(true);
  const [hide, setHide] = React.useState(false);
  const [desc, setDesc] = React.useState("");

  React.useEffect(() => {
    if (question) {
      setTitle(question.title);
      setDesc(question.desc);
      setShowGrade(!question.hide_grade);
      setHide(question.hide);
    }
  }, [question]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!title || !desc) throw new Error("error");
      postAdd.onOpen();
    } catch (err) {
      toast({
        title: "포텐업",
        description: "내용을 입력해주세요.",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      console.log(err);
    }
  };

  const handleUpdate = async () => {
    try {
      const body = {
        title,
        desc,
        hide_grade: !showGrade,
        hide,
      } as QuestionPut;
      await updateQuestion({ id: questionId, body });
      await questionsMutate();
      setFinishStatus(true);
      history.goBack();
    } catch (err) {
      toast({
        title: "포텐업",
        description: "내용을 입력해주세요.",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      console.log(err);
    }
  };

  if (!question || !user)
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );

  if (question.writer.id !== user.id) return <Redirect to="/questions" />;

  return (
    <>
      <PostAddModal
        isOpen={postAdd.isOpen}
        onClose={postAdd.onClose}
        callback={handleUpdate}
      />
      <PreventModal />
      <Helmet
        title="프로그램문의"
        keywords="프로그램문의"
        description="프로그램문의 페이지 입니다."
      />
      <Layout title="글수정하기" noAction onBack={()=>history.push('/')}>
        {question.problem && (
          <Container maxW="md" mb={4} py={4}>
            <Image src={URL + question.problem.img} />
          </Container>
        )}
        <form onSubmit={handleSubmit}>
          <Box p={4}>
            <Input
              fontSize="20px"
              placeholder="제목"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              border="none"
            />
            <Divider mb={"4px"} />
            {question && (
              <QuillEditor
                value={question.desc}
                onChange={(value) => setDesc(value)}
              />
            )}
            {question && (
              <Flex
                justify="space-between"
                w="full"
                bg="gray.100"
                position="absolute"
                bottom="0"
                left="0"
                h="100px"
                borderTopWidth={1}
                pt={4}
                px={4}
              >
                <Flex>
                  <Checkbox
                    defaultChecked={!question.hide_grade}
                    checked={showGrade}
                    onChange={(e) => setShowGrade(e.target.checked)}
                    h="20px"
                    mr={3}
                  >
                    등급 공개
                  </Checkbox>
                  <Checkbox
                    defaultChecked={question.hide}
                    checked={hide}
                    onChange={(e) => setHide(e.target.checked)}
                    h="20px"
                  >
                    질문 비공개
                  </Checkbox>
                </Flex>
                <Button type="submit" bg="primary" color="white" size="sm">
                  등록하기
                </Button>
              </Flex>
            )}
          </Box>
        </form>
      </Layout>
    </>
  );
};

export default QuestionEditPage;

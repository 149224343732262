import axios from "axios";

const uploadImage = async (image: any) => {
  try {
    const { data } = await axios.post("upload", image);
    return data;
  } catch (err) {
    console.log(err.response.data);
  }
};

const uploadFile = async (image: any) => {
  try {
    const { data } = await axios.post(
      "https://costco.icango.site/fileupload",
      image
    );
    return data;
  } catch (err) {
    console.log(err.response.data);
  }
};

export { uploadImage, uploadFile };

import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  HStack,
  Center,
  Flex,
  Button,
  Box,
} from "@chakra-ui/react";
import useUser from "@data/useUser";
import AnswerButton from "@components/AnswerButton";
import AnswerInput from "@components/AnswerInput";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import useSolveProblems from "@data/useSolveProblems";
import { SolveProblem } from "@type/solveProblem";
import { useParams } from "react-router-dom";

interface Prop {
  onClose: () => void;
  setStep: (value: number) => void;
  isOpen: boolean;
  isEnd: boolean;
  submit: any;
}

const AnswerContainer = ({
  problem,
  handleClick,
}: {
  problem: SolveProblem;
  handleClick: () => void;
}) => {
  return (
    <HStack ml={4} spacing={4} onClick={handleClick} cursor="pointer">
      <Center>
        {problem.check ? (
          <AiFillStar color="#6666FF" />
        ) : (
          <AiOutlineStar color="#DDDDDD" />
        )}
      </Center>
      <Center
        w="40px"
        h="44px"
        bg="gray.100"
        fontSize="14px"
        textDecoration="underline"
        cursor="pointer"
      >
        {problem.problem.number}
      </Center>
      {problem.problem.type === 1 ? (
        [1, 2, 3, 4, 5].map((el) => (
          <AnswerButton
            key={el}
            text={el}
            selected={el === problem.selected}
            handleClick={() => {}}
          />
        ))
      ) : (
        <AnswerInput disabled value={problem.selected} onChange={() => {}} />
      )}
    </HStack>
  );
};

export interface RouteParams {
  examId: string;
}

const TestDrawer: React.FC<Prop> = ({
  setStep,
  onClose,
  isOpen,
  submit,
  isEnd,
}) => {
  const { examId } = useParams<RouteParams>();
  const { user } = useUser();
  const { solveProblems } = useSolveProblems({ id: user?.id, exam: examId });

  return (
    <Drawer placement={"right"} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>
          <DrawerBody p={0}>
            <Box h="calc(100vh - 95px)" overflowY="auto">
              <Box h={4}></Box>
              {solveProblems &&
                solveProblems
                  .sort((a, b) => a.problem.number - b.problem.number)
                  .map((el, idx) => (
                    <AnswerContainer
                      problem={el}
                      key={el.id}
                      handleClick={() => {
                        setStep(idx + 1);
                        onClose();
                      }}
                    />
                  ))}
              <Box h={10}></Box>
            </Box>
            <Flex h="60px" justify="center" align="center" borderTopWidth={1}>
              <Button
                disabled={!isEnd}
                bg="primary"
                size="sm"
                color="white"
                onClick={submit.onOpen}
              >
                제출하기
              </Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default TestDrawer;

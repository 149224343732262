import useSWR from "swr";
import { Question } from "@type/question";

const useQuestion = ({ id }: { id: string | undefined }) => {
  const { data, error, mutate } = useSWR(id ? `questions/${id}` : null);
  return {
    question: data,
    isLoading: !error && !data,
    isError: error,
    questionMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    question: Question;
    questionMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useQuestion;

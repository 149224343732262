import React from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  CircularProgress,
  HStack,
  Text,
  Center,
  Container,
  Flex, Heading,
  Button,
  IconButton,
  useDisclosure,
  Input, Box,
  Spacer, Table,
  Thead,Image,
  Tbody, Wrap,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import OmrHeader from "@layouts/OmrHeader";
import useUser from "@data/useUser";
import usePreventBack from "@hooks/usePreventBack";
import useSolveProblems from "@data/useSolveProblems";
import { convertMin, convertTimeFormat } from "@util/time";
import { SolveProblem } from "@type/solveProblem";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import AnswerButton from "@components/AnswerButton";
import AnswerInput from "@components/AnswerInput";
import SubmitModal from "@components/modal/SubmitModal";
import { updateProblem } from "@services/problem";
import { updateExam } from "@services/exam";
import { AiOutlineLeft } from "react-icons/ai";
import useChapter from "@data/useChapter";
import moment from "moment";
import useExam from "@data/useExam";
import { Statistic } from "@type/statistic";
import { AiOutlineRight } from "react-icons/ai";
import { useTestModal } from "@hooks/useTestModal";
import { Chapter } from "@type/chapter";
import { convertDateTime } from "@util/time";
import useExams from "@data/useExams";

const AnswerPreviewContainer = React.memo(
  ({ problem, mutate }: { problem: SolveProblem; mutate: () => void }) => {
    const { min, sec } = convertTimeFormat(problem.solve_time);
    const [answer, setAnswer] = React.useState(problem.selected);
    const [minute, setMinute] = React.useState(min || 0);
    const [second, setSecond] = React.useState(sec || 0);
    


    const handleCheck = async (problem: any) => {
      const body = { check: !problem.check };
      await updateProblem({ id: problem.id, body });
      await mutate();
    };

    React.useEffect(() => {
      if (answer === problem.selected && minute === min && second === sec)
        return;
      const body = {
        selected: answer,
        answer: problem.problem.answer === answer,
        timeover: false,
        resolve: true,
        solve_time: minute * 60 + second,
      };
      updateProblem({ id: problem.id, body });
      mutate();
    }, [min, sec, mutate, problem, answer, minute, second]);

    return (
      <Flex align="center">
        <IconButton
          onClick={() => handleCheck(problem)}
          variant="ghost"
          aria-label="check"
          icon={
            problem.check ? (
              <AiFillStar color="#6666FF" />
            ) : (
              <AiOutlineStar color="#DDDDDD" />
            )
          }
        />
        <Center
          w="40px"
          h="44px"
          bg="gray.100"
          fontSize="14px"
          textDecoration="underline"
        >
          {problem.num}
        </Center>
        <HStack ml={2}>
          {problem.problem.type === 1 ? (
            [1, 2, 3, 4, 5].map((selected) => (
              <AnswerButton
                key={selected}
                text={+selected}
                selected={selected === answer}
                handleClick={() => setAnswer(selected)}
              />
            ))
          ) : (
            <AnswerInput value={answer} onChange={setAnswer} omr />
          )}
        </HStack>
        <Spacer />
        <Input
          defaultValue={min}
          fontSize="12px"
          h={6}
          w={6}
          p={1}
          rounded="md"
          textAlign="center"
          placeholder="0"
          type="number"
          onChange={(e) => setMinute(+e.target.value)}
        />
        <Text fontSize="sm" mx={1}>
          분
        </Text>
        <Input
          defaultValue={sec}
          fontSize="12px"
          h={6}
          w={6}
          p={1}
          rounded="md"
          textAlign="center"
          placeholder="0"
          mx={1}
          type="number"
          onChange={(e) => setSecond(+e.target.value)}
        />
        <Text fontSize="sm">초</Text>
      </Flex>
    );
  }
);

export interface RouteParams {
  bookId: string;
  chapterId: string;
  examId: string;
  isSubmit: string;
}

const ExamPreviewPage = () => {
  const history = useHistory();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { bookId, chapterId, examId, isSubmit } = useParams<RouteParams>();
  const { chapter } = useChapter({ id: chapterId });

  const { user } = useUser();


  const {
    setExam,
    setChapter,
    setType,
    setTitle,
    setSubTitle,
    setRoute,
    TestModal,
    onOpenTestModal,
  } = useTestModal({ testType: "chapter" });
  
  const { exams } = useExams({ userId: user?.id, chapterId: chapterId });

  console.log(chapter);

  const completed = exams?.every((el) => el.is_submit);
  const first = exams?.length === 0;
  const exam = exams ? exams[0] : null;
  const current = exam?.solve_problems ? exam?.solve_problems.filter((el) => el.resolve).length : 0;
  const handleTest = async (chapter: Chapter) => {
    setChapter(chapter);
    const currentExams = exams ? exams.filter((el) => el.chapter.id === chapter.id) : [];
    const completed = currentExams.every((el) => el.is_submit);
    const first = currentExams.length === 0;
    const exam = currentExams.find((el) => !el.is_submit);
    const current = exam?.solve_problems ? exam?.solve_problems.filter((el) => el.resolve).length : 0;
    if (first) {
      setTitle("학습을 시작하시겠습니까?");
      setSubTitle("시작 직후 학습 시간이 카운트 됩니다.");
      setType("new");
    } else if (completed) {
      setTitle(`학습이 완료되었습니다.\n다시 하겠습니까?`);
      setSubTitle("시작 직후 학습 시간이 카운트 됩니다.");
      setType("again");
    } else if (exam && current === 0) {
      setExam(exam);
      setTitle("임시 저장된 학습이력이 있습니다.\n이어서 하시겠어요?");
      const date = exam.solve_problems
        .map((el) => new Date(el.updated_at) as any)
        .sort((a, b) => b - a)
        .pop();
      const text = convertDateTime(date);
      setSubTitle(text);
      setType("never");
    } else if (exam && current) {
      setExam(exam);
      const date = exam.solve_problems
        .map((el) => new Date(el.updated_at) as any)
        .sort((a, b) => b - a)
        .pop();
      const text = convertDateTime(date);
      setTitle(`임시 저장된 학습이력이 있습니다.\n이어서 하시겠어요?`);
      setSubTitle(text);
      setType("continue");
    }
    onOpenTestModal();
  };

  const { solveProblems, solveProblemsMutate } = useSolveProblems({
    id: user?.id,
    exam: exam?.id,
  });
  const { setPrevent, PreventModal } = usePreventBack();

  const [myGradeSolveTimes, setMyGradeSolveTimes] = React.useState<Statistic[]>([]);
  
  // 문제번호 
  // https://ft7t35xzbc.execute-api.ap-northeast-2.amazonaws.com/default/getProblemStatistic
  
  // const params : any = {
  //   filename : moment().subtract(1, 'days').format('YYYY-MM-DD'), // 2021-03-02
  //   grade : user.grade, // 6
  //   problem_ids : solveProblems?.map((el)=>{
  //     return el.problem.id
  //   }).join(',') //14605,14606,14610
  // }
  // var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  // fetch("https://ft7t35xzbc.execute-api.ap-northeast-2.amazonaws.com/default/getProblemStatistic?" + queryString, {
  //   mode: 'cors',
  //   method: "GET",
  //   cache: "no-cache",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }) 
  // .then(response => response.json())
  // .then(
  //   (result) => {
  //     console.log('then')
  //     console.log(result)
  //     setMyGradeSolveTimes(result); // 원하는 데이터를 저장 
  //   }
  // )
  // .catch(error => console.error('Error:', error));


  const handleSubmit = async () => {
    await updateExam({ id: examId, body: { is_submit: true } });
    setPrevent(false);
    history.goBack();
  };

  if (!solveProblems) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }
  const isEnd = solveProblems?.every((el) => el.resolve);
  const total = solveProblems.reduce((acc, cur) => acc + cur.solve_time, 0);

  return (
    <>
      <SubmitModal isOpen={isOpen} onClose={onClose} callback={handleSubmit} />
      <TestModal />
      <Helmet
        title="교재 학습하기"
        keywords="교재 학습하기"
        description="교재 학습하기 페이지 입니다."
      />
      <Layout title="교재 학습하기" hide={false} onBack={()=>history.push('/')}>
        <Flex
          position="sticky"
          top="0"
          bg="white"
          h="44px"
          zIndex={100}
          align="center"
          px={2}
          justify="space-between"
          borderBottomWidth={1}
        >
          <Flex flex={1} h="full">
            <IconButton
              onClick={() => history.push(`/book/${bookId}`)}
              aria-label="back"
              variant="link" icon={<AiOutlineLeft />}
              fontSize={20}
            />
          </Flex>
          {isSubmit == 'submit' ?
          <Text flex={1} fontWeight="bold" fontSize="lg" textAlign="center">
            교재 학습 결과
          </Text>
        :
        <Text flex={1} fontWeight="bold" fontSize="lg" textAlign="center">
            교재 학습하기
          </Text>
            
        }
          <Flex flex={1} justifyContent="flex-end">

          </Flex>
        </Flex>
        <Flex
          px={6}
          h="100px"
          borderBottomWidth={1}
          _hover={{ bg: "gray.50" }}
          cursor="pointer"
          align="left"
          direction="column"
        >
          <Flex
            h="90px"
            align="center"
          >
            <Box w={"50px"} h={"50px"} bg="gray.100" mr={3}>
              <Center h="full" fontSize="xs">
              {
              typeof chapter?.book === "object"?
              <Image 
                src={chapter?.book.image?.url} w="48px"
                h="48px"
              /> : ''
              }
              </Center>
            </Box>
            <Box>
              <Heading fontSize="16px" fontWeight="500" mb={1}>
                {chapter?.name}
              </Heading>
              <Text color="gray.300" fontSize={"10px"}>
                {moment(chapter?.created_at).format("YY.MM.DD")} 
                
              </Text>
              
            {(exam && !exam.is_submit) ?<Text fontSize="sm"> 임시 저장된 학습이력이 있습니다. 이어서 학습 하세요.</Text>: <span></span>}
            </Box>
            <Spacer />
            { isSubmit == 'submit' ?
            
            <Button
            variant="outline"
              size="md"
              onClick={() => { history.push(`/book/${bookId}`) }}
            >
              단원 보기 
              <IconButton
                        aria-label="menu"
                        icon={<AiOutlineRight />}
                        variant="link"
                        fontSize={"16px"}
                        color="gray.400"
                      />
              </Button>
            :   
            <Button
              bg="primary"
              color="white"
              size="md"
              onClick={() => { 
                // history.push(`/book/${exam?.book?.id}/exam/${examId}`) 
              
                setRoute("chapter");
                handleTest(chapter);
              }}
            >
            {(exam && !exam.is_submit) ? <Text fontSize="sm"> 이어서 학습 시작</Text>: <Text fontSize="sm"> 학습 시작 </Text>}
            {(exam && exam.is_submit) ? <Text fontSize="sm"> 다시 학습 시작 </Text> : <span>{exam}</span>}
              </Button>
            }
          </Flex>
        </Flex>
        <Container maxW="100%" h="calc(100% - 100px)" overflowY="auto">
          <Box
            p={5}
            m={5}
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
          >
            {(!exams) ? <Text fontSize="sm">기존 학습 데이터가 없습니다. 학습을 시작해주세요.</Text> : <span></span> }
            {(!isSubmit && exams) ? <Text fontSize="sm"> 총 {exams.length}개의 학습이력이 있습니다.</Text> : <span></span>}
            {(exam) ? <Text fontSize="sm"> 학습ID :  #{exam?.id}</Text> : <span></span>}

            <Text fontSize="sm">나의 총 풀이 시간: {`${convertMin(solveProblems.reduce((sum, currValue) => {
                return sum + currValue.solve_time;
              }, 0))}`}</Text>
            {/* <Text fontSize="sm">동일 등급 총 풀이시간: 5분 53초</Text> */}
          </Box>
          <Table variant="simple" colorScheme="gray" size="sm">
            <TableCaption>입력하지 않은 문제는 오답처리 됩니다.</TableCaption>
            <Thead>
              <Tr style={{backgroundColor:"#6666FF"}} color='gray.50'>
                <Th color='gray.50'>No</Th>
                <Th textAlign="center" color='gray.50'>정답</Th>
                <Th textAlign="center" color='gray.50'>다시<br />풀기</Th>
                <Th textAlign="center" color='gray.50'>풀이<br />시간</Th>
                <Th textAlign="center" color='gray.50'>{user?.grade}등급<br />풀이<br />시간</Th>
                {
                  user?.grade != 1 ? <Th textAlign="center" color='gray.50'>{user?.grade ? user?.grade - 1 : 8}등급<br />풀이{user?.grade}<br />시간</Th>
                  : ''
                }
                
              </Tr>
            </Thead>
            <Tbody>
              {solveProblems
                .sort((a, b) => a.problem.number - b.problem.number)
                .map((el) => (
                  
                  <Tr key={el.id}>
                    <Td>{`${el.num}`}</Td>
                    <Td textAlign="center">{
                      
                     (el.problem.answerTimes? el.problem.answerTimes[user?.grade ? user?.grade - 1 : 8] : 9999) < el.solve_time ? (<span style={{color:"#FF7373"}} >T</span>) 
                    : (el.answer?  (<span style={{color:"#6666FF"}} >O</span>) :  (<span style={{color:"#FF7373"}} >X</span>) )
                    
                    }</Td>
                    <Td textAlign="center">{el?.check ? (
                  <div ><div style={{display: 'inline-block', marginBottom: '-2px'}}><AiFillStar color="#6666FF" /> </div> 
                  <span style={{color:"#6666FF"}} ></span></div>
                ) : (
                  <div ><div style={{display: 'inline-block', marginBottom: '-2px'}}><AiFillStar color="#DDDDDD" /> </div> 
                  <span style={{color:"#DDDDDD"}} ></span></div>
                )}</Td>
                    <Td textAlign="center">{`${convertMin(el.solve_time)}`}</Td>
                    <Td textAlign="center">{`${el.problem.answerTimes ? convertMin(el.problem.answerTimes[user?.grade ? user?.grade - 1 : 8]) : ''}`}</Td>
                    {
                   user?.grade != 1 ?  <Td textAlign="center">{`${el.problem.answerTimes ? convertMin(el.problem.answerTimes[user?.grade ? user?.grade - 1 : 8]) : ''}`}</Td>
                      : ''
                    }
                  </Tr>
                ))}
            </Tbody>

          </Table>
        </Container>
      </Layout>
    </>
  );
};

export default ExamPreviewPage;

import { Center, Image, Avatar as Face } from "@chakra-ui/react";
import { User } from "@type/user";

import start0 from "@assets/avatars/0.png";
import start1 from "@assets/avatars/1.png";
import start2 from "@assets/avatars/2.png";
import start3 from "@assets/avatars/3.png";
import start4 from "@assets/avatars/4.png";
import start5 from "@assets/avatars/5.png";
import start6 from "@assets/avatars/6.png";
import start7 from "@assets/avatars/7.png";
import start8 from "@assets/avatars/8.png";
import start9 from "@assets/avatars/9.png";
import start10 from "@assets/avatars/10.png";
import start11 from "@assets/avatars/11.png";
import start12 from "@assets/avatars/12.png";

import level1 from "@assets/avatars/level1.png";
import level2 from "@assets/avatars/level2.png";
import level3 from "@assets/avatars/level3.png";
import level4 from "@assets/avatars/level4.png";
import level5 from "@assets/avatars/level5.png";
import level6 from "@assets/avatars/level6.png";
import level7 from "@assets/avatars/level7.png";
import level8 from "@assets/avatars/level8.png";
import level9 from "@assets/avatars/level9.png";

const MAP_START = {
  0: start0,
  1: start1,
  2: start2,
  3: start3,
  4: start4,
  5: start5,
  6: start6,
  7: start7,
  8: start8,
  9: start9,
  10: start10,
  11: start11,
  12: start12,
} as {
  [key: number]: any;
};

const MAP_LEVEL = {
  1: level1,
  2: level2,
  3: level3,
  4: level4,
  5: level5,
  6: level6,
  7: level7,
  8: level8,
  9: level9,
} as {
  [key: number]: any;
};

const Avatar = ({ user }: { user: User }) => {
  return (
    <Center minW="82px" minH="82px" position="relative">
      {user && (
        <>
          {user.avatar? 
            <Image style={{
              marginTop: "16px",
              width: "38px"}}
            src={user.avatar.url}
            w="48px"
            h="48px"
            top={0}
            position="absolute"
            zIndex={1}
          /> :
          <Face size={"md"} position="absolute" zIndex={2} />
}
          <Image
            src={MAP_START[user.star as number]}
            w="full"
            h="30px"
            top={0}
            position="absolute"
            zIndex={1}
          />
          <Image
            src={MAP_LEVEL[user.grade as number]}
            w="full"
            bottom={0}
            position="absolute"
            zIndex={11}
          />
        </>
      )}
    </Center>
  );
};

export default Avatar;

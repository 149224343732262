import useSWR from "swr";
import { Chapter } from "@type/chapter";

const useChapter = ({ id }: { id: string | undefined }) => {
  const { data, error, mutate } = useSWR(id ? `chapters/${id}` : null);
  return {
    isLoading: !error && !data,
    isError: error,
    chapter: data,
    chapterMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    chapter: Chapter;
    chapterMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useChapter;

import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const colors = {
  primary: "#6666FF",
  white: "#fff",
  black: "#000",
  gray: {
    900: "#222222",
    800: "#1A202C",
    700: "#2D3748",
    600: "#4A5568",
    500: "#717171",
    400: "#b0b0b0",
    300: "#dddddd",
    200: "#ededed",
    100: "#f7f7f7",
  },
};

const breakpoints = createBreakpoints({
  sm: "375px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
});

export const theme = extendTheme({ colors, breakpoints });

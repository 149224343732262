import React from "react";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalContent,
  ModalBody,
  useDisclosure,
  Button,
  Box,
  Text,
  HStack,
} from "@chakra-ui/react";
import { addExam } from "@services/exam";
import useUser from "@data/useUser";
import useExams from "@data/useExams";
import { addProblem } from "@services/problem";
import { addCalendar } from "@services/calendar";
import { Chapter } from "@type/chapter";
import { Problem } from "@type/problem";
import { Exam } from "@type/exam";
import { Book } from "@type/book";
import { CalendarPost } from "@type/calendar";

type RouteType = "chapter" | "omr" | "answer";
type ExamType = "new" | "never" | "continue" | "again";
type TestType = "chapter" | "note" | "exam";

export const useTestModal = ({ testType }: { testType: TestType }) => {
  const history = useHistory();
  const { user } = useUser();
  const [test, setTest] = React.useState<"book" | "test">("book");
  const [title, setTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [type, setType] = React.useState<ExamType>("new");
  const [route, setRoute] = React.useState<RouteType>("chapter");
  const [chapter, setChapter] = React.useState<Chapter | null>(null);
  const [exam, setExam] = React.useState<Exam | null>(null);
  const { exams, examsMutate } = useExams({ userId: user?.id, test });

  const handleSubmit = React.useCallback(async () => {
    const book = chapter?.book as Book;
    let currentExam = exam;
    let round = 1;
    if (type === "new") {
      let solveProblems;
      if (chapter) {
        solveProblems = await Promise.all(
          chapter.problems.map(async (problem) => {
            const body = {
              num: problem.number,
              problem: problem.id,
              user: user?.id,
            } as { num: number; problem: string; user: string };
            const { data }: { data: Problem } = await addProblem(body);
            return data.id;
          })
        );
      }
      const body = {
        type: testType,
        user: user.id,
        chapter: chapter?.id,
        book: book.id,
        solve_problems: solveProblems,
      };
      const { data } = await addExam(body);
      const calendar = {
        user: user.id,
        title: book.is_test ? "모의고사 학습" : "교재 학습",
        type: book.is_test ? "exam_learn" : "book_learn",
        desc: book.name,
      } as CalendarPost;

      // 모의고사 학습만 표시한다.
      if(book.is_test) {
        await addCalendar(calendar);
      }
      currentExam = data;
    } else if (type === "again") {
      let solveProblems;
      if (chapter) {
        solveProblems = await Promise.all(
          chapter.problems.map(async (problem) => {
            const body = {
              num: problem.number,
              problem: problem.id,
              user: user?.id,
            } as { num: number; problem: string; user: string };
            const { data }: { data: Problem } = await addProblem(body);
            return data.id;
          })
        );
      }
      const current = exams
        .filter((el) => el.chapter.id === chapter?.id)
        .map((el) => el.round)
        .sort((a, b) => a - b)
        .pop();
      round = current ? current + 1 : 2;
      const body = {
        round,
        type: testType,
        user: user.id,
        chapter: chapter?.id,
        book: book.id,
        solve_problems: solveProblems,
      };
      const { data } = await addExam(body);
      const calendar = {
        user: user.id,
        title: book.is_test ? "모의고사 학습" : "교재 학습",
        type: book.is_test ? "exam_learn" : "book_learn",
        desc: book.name,
      } as CalendarPost;
      // 재 학습은 표시하지 않는다.
      // await addCalendar(calendar);
      currentExam = data;
    } else if (type === "continue" || type === "never") {
      const current = exams
        .filter((el) => el.chapter.id === chapter?.id)
        .map((el) => el.round)
        .sort((a, b) => a - b)
        .pop();
      round = current as number;
    }
    await examsMutate();
    if (route === "chapter") {
      history.push(`/book/${currentExam?.book?.id}/chapter/${chapter?.id}/exam/${currentExam?.id}`);
    } else if (route === "omr") {
      history.push(`/exam/${currentExam?.id}/omr`);
    }
  }, [exam, exams, type, chapter, user, history, examsMutate, route, testType]);

  const TestModal = React.memo(() => (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalContent w="320px" shadow="2xl">
          <ModalBody p={8}>
            <Box textAlign="center">
              <Box py={8}>
                <Text fontSize="16px" mb={3} whiteSpace="pre-line">
                  {title}
                </Text>
                <Text color="gray.400" fontSize="14px" mb={4}>
                  {subTitle}
                </Text>
              </Box>
              <HStack w="full">
                <Button
                  isFullWidth
                  bg="#6666FF34"
                  color="primary"
                  onClick={() => onClose()}
                  h="44px"
                >
                  아니오
                </Button>
                <Button
                  isFullWidth
                  bg="primary"
                  color="white"
                  onClick={()=>{
                    handleSubmit()
                  }
                }
                  h="44px"
                >
                  네
                </Button>
              </HStack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  ));
  return {
    setExam,
    setChapter,
    setType,
    setTitle,
    setTest,
    setSubTitle,
    setRoute,
    TestModal,
    onOpenTestModal: onOpen,
  };
};

import React from "react";
import { useParams, useHistory} from "react-router-dom";
import {
  CircularProgress,
  Center,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
  InputGroup,Button,
  Input,Image,
  InputRightElement,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import { FiXCircle, FiSearch } from "react-icons/fi";
import { AiOutlineArrowUp } from "react-icons/ai";
import useNote from "@data/useNote";
import MathView2 from '@components/MathView2';
import { TriangleDownIcon } from '@chakra-ui/icons'

export interface RouteParams {
  noteId: string;
}
const URL = "https://daesung.s3.ap-northeast-2.amazonaws.com/";

// 오답 노트 출력 
const AnswerNotePrintPage = () => {
  const history = useHistory();

  const [search, setSearch] = React.useState("");
  const { noteId } = useParams<RouteParams>();
  const { note } = useNote({ id: noteId });
  const print = () =>{
    window.print();
  }
  if (!note) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  const showToTop = note && note.problems.length > 10;
  return (
    <>
      <Helmet
        title="오답노트출력"
        keywords="오답노트출력"
        description="오답노트출력페이지 입니다."
      />
      <Layout title={`${note.name}`} onBack={()=>
        history.push('/home-note')
      }>
        <Box flex={1} bg="gray.100" p={4}>
          <Box px={10} pb={4}>
            <Button onClick={print} leftIcon={<TriangleDownIcon />} 
          colorScheme="teal" variant="solid">
            PDF
          </Button>
          </Box>
            {note.problems.map((el) => (
            
              <div key={el.id}>
              
              <Box flex="1" textAlign="left">
              {`${el.number}번`}
              <h4>문제</h4>
              <Image src={URL + el.img} w="440px" mx={"auto"}/>
              <MathView2 value={el.content}></MathView2>
              </Box>
              <div style={{pageBreakBefore:"always"}}></div>
              <br style={{height:0, lineHeight:0}}/>
            </div>
            ))}
          {showToTop && (
            <>
              <Box h="72px" />
              <Center
                position="fixed"
                bg="white"
                shadow="md"
                rounded="full"
                w="40px"
                h="40px"
                left="calc(50% - 20px)"
                top="calc(100vh - 56px)"
                zIndex={100}
                cursor="pointer"
                _hover={{ bg: "gray.50" }}
                onClick={() =>
                  window.scroll({ top: 0, left: 0, behavior: "smooth" })
                }
              >
                <AiOutlineArrowUp />
              </Center>
            </>
          )}
        </Box>
      </Layout>
    </>
  );
};

export default AnswerNotePrintPage;

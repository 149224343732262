import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Flex,
  Box,
  Divider,
  Text,
  Center,
  Button,
  CircularProgress,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
  IconButton,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import HistoryTab from "@layouts/HistoryTab";
import useUser from "@data/useUser";
import moment from "moment";
import useProblemsHistory from "@data/useProblemsHistory";
import { convertMinSep } from "@util/time";
import { getProblemStatistic } from "@services/statistic";
import useEnrollBooks from "@data/useEnrollBooks";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { AiOutlineRight } from "react-icons/ai";

const Item = ({ label, value }: { label: string; value: string }) => (
  <Flex align="center" justify="space-between" fontSize="12px">
    <Text mr={2} color="gray.400">
      {label}
    </Text>
    <Text color="gray.900">{value}</Text>
  </Flex>
);

const HistoryNotePage = () => {
  const history = useHistory();

  const { user } = useUser();
  const [filter, setFilter] = React.useState("all");
  const [sort, setSort] = React.useState("recent");
  const { enrollBooks } = useEnrollBooks({ id: user?.id, type: filter });

  return (
    <>
      <Helmet
        title="오답이력"
        keywords="오답이력"
        description="오답이력페이지 입니다."
      />
      <Layout title="학습이력" onBack={()=>history.push('/history-note')}>
        <HistoryTab />
        <Divider />
        <Box bg="gray.100" p={4} pt={2} flex={1}>
          <Flex p={4} px={6} justify="space-between">
            <HStack divider={<Divider orientation="vertical" />}>
              {[
                { value: "all", label: "전체" },
                { value: "book", label: "교재" },
                { value: "homework", label: "과제" },
              ].map((el) => (
                <Text
                  key={el.value}
                  fontSize="14px"
                  cursor={"pointer"}
                  color={filter === el.value ? "black" : "gray.400"}
                  onClick={() => setFilter(el.value)}
                >
                  {el.label}
                </Text>
              ))}
            </HStack>
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    size="sm"
                    bg="#fff"
                    borderWidth={1}
                    rounded="none"
                    color="gray.400"
                    fontSize="14px"
                  >
                    {sort === "recent" ? "최신순" : "이름순"}
                  </MenuButton>
                  <MenuList minW="auto" w="84px" rounded="none">
                    {[
                      { value: "recent", label: "최신순" },
                      { value: "name", label: "이름순" },
                    ].map((el) => (
                      <MenuItem
                        fontSize="14px"
                        color="gray.400"
                        key={el.value}
                        onClick={() => setSort(el.value)}
                      >
                        {el.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
          <Box>
            {enrollBooks?.sort((a, b) => {
              if (sort === "name") {
                return b.name < a.name ? 1 : -1;
              } else {
                return +b.id - +a.id;
              }
            })
              .map((el) => (
                <Link to={`/history-book/${el.id}`} key={el.id}>
                  <Flex
                    px={6}
                    h="90px"
                    borderBottomWidth={1}
                    _hover={{ bg: "gray.50" }}
                    cursor="pointer"
                    align="center"
                  >
                    <Box w={"50px"} h={"50px"} bg="gray.100" mr={3}>
                      <Center h="full" fontSize="xs">
                        Book
                      </Center>
                    </Box>
                    <Box>
                      <Heading fontSize="16px" fontWeight="500" mb={1}>
                        {el.name}
                      </Heading>
                      <Text color="gray.300" fontSize={"10px"}>
                        {moment(el.created_at).format("YY.MM.DD")}
                      </Text>
                    </Box>
                    <Spacer />
                    <IconButton
                      aria-label="menu"
                      icon={<AiOutlineRight />}
                      variant="link"
                      fontSize={"16px"}
                      color="gray.400"
                    />
                  </Flex>
                </Link>
              ))}
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default HistoryNotePage;

import { useEffect, useRef } from 'react';
import Iframe from 'react-iframe'
import React from "react";

const btnStyle = {
  "marginLeft":"auto",
  "marginRight":"auto",
  width:"550px"
}

const MathView2 = ({
  value,
  onLoad
}: {
  value: string,
  onLoad? : () => void
}) => {
  // https://gist.github.com/iest/3b571a6ddcdd9ddab3cf  이거 적용해야함!!
  var valueURI = "https://mathview.potenup.kr/?value="+ encodeURIComponent(value);
  // localStorage.setItem("writing", value);
  return (
    <><div style={btnStyle}>
      <Iframe onLoad={onLoad} url={valueURI}
        width="550px"
        height="450px"
        id="myId"
        className="center"
        position="relative"/>
        </div>
    </>
  );
};

export default MathView2;

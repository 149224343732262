import {
  Button,
  Text,
  Modal,
  ModalContent,
  ModalBody,
  HStack,
  Box,
} from "@chakra-ui/react";

const CloseModal = ({
  isOpen,
  onClose,
  callback,
  cancel,
}: {
  isOpen: boolean;
  onClose: () => void;
  callback: () => void;
  cancel: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalContent w="320px" shadow="2xl">
        <ModalBody p={8} textAlign="center">
          <Box py={8}>
            <Text fontSize="16px" mb={3} whiteSpace="pre-line">
              학습중입니다.
              <br />
              정말 나가시겠어요?
            </Text>
            <Text color="gray.400" fontSize="14px" mb={4}>
              학습이력은 임시저장 됩니다.
            </Text>
          </Box>
          <HStack w="full">
            <Button
              isFullWidth
              bg="#6666FF34"
              color="primary"
              onClick={() => {
                cancel();
                onClose();
              }}
              h="44px"
            >
              아니요
            </Button>
            <Button
              isFullWidth
              bg="primary"
              color="white"
              h="44px"
              onClick={() => {
                callback();
                onClose();
              }}
            >
              네
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CloseModal;

import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { uploadImage } from "@services/image";
import { useDropzone } from "react-dropzone";

const QuilEditor = ({
  value,
  onChange,
}: {
  value?: string;
  onChange: (value: string) => void;
}) => {
  const editorRef = React.useRef<any>(null);
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({});

  const onDrop = (acceptedFiles: File[]) => {
    try {
      acceptedFiles.forEach(async (file) => {
        if (!editorRef.current) return;
        const formData = new FormData();
        formData.append("files", file);
        const data = await uploadImage(formData);
        const quill = editorRef.current.getEditor();
        const range = quill.getSelection();
        quill.insertEmbed(range.index, "image", data[0].url);
        quill.setSelection(range.index + 1);
        quill.focus();
      });
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    onDrop(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <>
      <ReactQuill
        ref={editorRef}
        theme="snow"
        defaultValue={value || ""}
        modules={{
          toolbar: {
            container: [
              [{ header: [1, 2, false] }],
              ["bold", "italic", "underline", "strike"],
              ["image"],
            ],
            handlers: {
              image: open,
            },
          },
        }}
        onChange={(value) => onChange(value)}
      />
      <div className="container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
        </div>
      </div>
    </>
  );
};

export default QuilEditor;

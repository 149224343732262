import React from "react";
import {
  Button,
  Divider,
  Spacer,
  FormControl,
  FormErrorMessage,
  Input,
  Flex,
  Heading,
  Text,
  Select,
  IconButton,
  Alert,
  AlertIcon,
  FormLabel,
} from "@chakra-ui/react";
import { CheckCircleIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Helmet from "@layouts/Helmet";
import AuthContainer from "@components/AuthContainer";
import PasswordField from "@components/PasswordField";
import useServiceModal from "@hooks/useServiceModal";
import usePersonalModal from "@hooks/usePersonalModal";
import useMarketingModal from "@hooks/useMarketingModal";
import { signUp, login, getNickname } from "@services/auth";
import useUser from "@data/useUser";

const SingUpPage = () => {
  const { user, userMutate } = useUser();

  const [service, setService] = React.useState(false);
  const [personal, setPersonal] = React.useState(false);
  const [marketing, setMarketing] = React.useState(false);

  const { openService, ServiceModal } = useServiceModal(() => setService(true));
  const { openPersonal, PersonalModal } = usePersonalModal(() =>
    setPersonal(true)
  );
  const { openMarketing, MarketingModal } = useMarketingModal(() =>
    setMarketing(true)
  );

  const handleService = () => {
    setService((val) => !val);
  };

  const handlePersonal = () => {
    setPersonal((val) => !val);
  };

  const handleMarketing = () => {
    setMarketing((val) => !val);
  };

  return (
    <>
      <ServiceModal />
      <PersonalModal />
      <MarketingModal />
      <Helmet
        title="회원가입"
        keywords="회원가입"
        description="회원가입페이지 입니다."
      />
      <AuthContainer>
        <Formik
          initialValues={{
            email: "",
            password: "",
            passwordConfirm: "",
            name: "",
            nick_name: "",
            phone: "",
            grade: "",
            year: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("올바른 이메일 형식을 입력해주세요.")
              .required("이메일을 입력하세요."),
            password: Yup.string().required("비밀번호를 입력하세요."),
            passwordConfirm: Yup.string()
              .oneOf(
                [Yup.ref("password"), null],
                "처음 입력한 비밀번호와 일치하지 않습니다."
              )
              .required("비밀번호를 입력하세요"),
            name: Yup.string()
              .required("닉네임을 입력하세요.")
              .min(2, "2자 이상의 실명을 입력해주세요.")
              .max(10, "최대 10자까지 입력하세요"),
            nick_name: Yup.string()
              .required("닉네임을 입력하세요.")
              .min(2, "최소 2자 이상 입력하세요")
              .max(10, "최대 10자까지 입력하세요"),
            phone: Yup.number()
              .typeError("숫자만 입력하세요.")
              .test("len", "핸드폰 번호를 확인하세요.", (val: any) =>
                val ? val.toString().length === 10 : true
              )
              .required("전화번호를 입력하세요."),
            grade: Yup.string().required("등급을 선택하세요."),
            year: Yup.string().required("학년을 선택하세요."),
          })}
          onSubmit={async (values, { setStatus, setSubmitting }) => {
            if (!service || !personal) {
              setStatus("동의를 해주세요.");
              return;
            }
            setSubmitting(true);
            setStatus("");
            const {
              email,
              password,
              name,
              nick_name,
              phone,
              grade,
              year,
            } = values;
            try {
              const res = await getNickname(nick_name);
              if (res.data.length > 0)
                throw new Error("닉네임이 이미 존재합니다.");
              await signUp({
                email,
                username: email,
                password,
                name,
                nick_name,
                phone,
                grade: +grade,
                year: +year,
                marketing,
              });
              const { data } = await login({ identifier: email, password });
              const { jwt } = data;
              localStorage.setItem("jwt", jwt);
              userMutate();
            } catch (err) {
              setStatus("닉네임이 이미 존재합니다.");
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, isSubmitting, status }) => (
            <Form>
              {status && (
                <Alert status="error" borderRadius={4}>
                  <AlertIcon />
                  {status}
                </Alert>
              )}
              <Field name="email">
                {({ field, form }: any) => (
                  <FormControl
                    h="90px"
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <FormLabel color={"gray.400"} fontSize="14px">
                      이메일
                    </FormLabel>
                    <Input {...field} id="email" placeholder="이메일" />
                    <FormErrorMessage justifyContent="flex-end">
                      {form.errors.email}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="password">
                {({ field, form }: any) => (
                  <PasswordField
                    placeholder="패스워드"
                    field={field}
                    form={form}
                    passType="password"
                  />
                )}
              </Field>
              <Field name="passwordConfirm">
                {({ field, form }: any) => (
                  <PasswordField
                    placeholder="패스워드 확인"
                    field={field}
                    form={form}
                    passType="passwordConfirm"
                  />
                )}
              </Field>
              <Field name="name">
                {({ field, form }: any) => (
                  <FormControl
                    h="90px"
                    isInvalid={form.errors.name && form.touched.name}
                  >
                    <FormLabel color={"gray.400"} fontSize="14px">
                      이름
                    </FormLabel>
                    <Input
                      {...field}
                      id="name"
                      placeholder="2자 이상의 실명을 입력해주세요."
                    />
                    <FormErrorMessage justifyContent="flex-end">
                      {form.errors.name}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="nick_name">
                {({ field, form }: any) => (
                  <FormControl
                    h="90px"
                    isInvalid={form.errors.nick_name && form.touched.nick_name}
                  >
                    <FormLabel color={"gray.400"} fontSize="14px">
                      닉네임
                    </FormLabel>
                    <Input {...field} id="nick_name" placeholder="닉네임" />
                    <FormErrorMessage justifyContent="flex-end">
                      {form.errors.nick_name}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="phone">
                {({ field, form }: any) => (
                  <FormControl
                    h="90px"
                    isInvalid={form.errors.phone && form.touched.phone}
                  >
                    <FormLabel color={"gray.400"} fontSize="14px">
                      휴대폰 번호
                    </FormLabel>
                    <Input
                      {...field}
                      id="phone"
                      placeholder="전화번호를 입력하세요"
                    />
                    <FormErrorMessage justifyContent="flex-end">
                      {form.errors.phone}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="grade">
                {({ field, form }: any) => (
                  <FormControl
                    h="90px"
                    isInvalid={form.errors.grade && form.touched.grade}
                  >
                    <FormLabel color={"gray.400"} fontSize="14px">
                      등급
                    </FormLabel>
                    <Select
                      {...field}
                      id="grade"
                      placeholder="등급을 선택하세요"
                      color={values.grade ? "black" : "gray.400"}
                    >
                      <option value="1">1등급</option>
                      <option value="2">2등급</option>
                      <option value="3">3등급</option>
                      <option value="4">4등급</option>
                      <option value="5">5등급</option>
                      <option value="6">6등급</option>
                      <option value="7">7등급</option>
                      <option value="8">8등급</option>
                      <option value="9">9등급</option>
                    </Select>
                    <FormErrorMessage justifyContent="flex-end">
                      {form.errors.grade}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="year">
                {({ field, form }: any) => (
                  <FormControl
                    h="90px"
                    isInvalid={form.errors.year && form.touched.year}
                  >
                    <FormLabel color={"gray.400"} fontSize="14px">
                      학년
                    </FormLabel>
                    <Select
                      {...field}
                      id="year"
                      placeholder="학년을 선택해주세요."
                      color={values.year ? "black" : "gray.400"}
                    >
                      <option value="1">중1</option>
                      <option value="2">중2</option>
                      <option value="3">중3</option>
                      <option value="4">고1</option>
                      <option value="5">고2</option>
                      <option value="6">고3/재수</option>
                    </Select>
                    <FormErrorMessage justifyContent="flex-end">
                      {form.errors.year}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Divider mt={10} mb={6} />
              <Heading fontSize="20px" mb={6} textAlign="center">
                약관동의
              </Heading>
              <Flex align="center">
                <IconButton
                  aria-label="Search database"
                  icon={<CheckCircleIcon />}
                  variant="ghost"
                  onClick={handleService}
                  color={service ? "primary" : "gray.100"}
                />
                <Text fontSize="14px">[필수] 이용약관</Text>
                <Spacer />
                <IconButton
                  aria-label="Search database"
                  icon={<ChevronRightIcon />}
                  variant="ghost"
                  onClick={() => openService()}
                />
              </Flex>
              <Flex align="center">
                <IconButton
                  aria-label="Search database"
                  icon={<CheckCircleIcon />}
                  variant="ghost"
                  onClick={handlePersonal}
                  color={personal ? "primary" : "gray.100"}
                />
                <Text fontSize="14px">[필수] 개인정보의 수집 및 이용</Text>
                <Spacer />
                <IconButton
                  aria-label="Search database"
                  icon={<ChevronRightIcon />}
                  variant="ghost"
                  onClick={() => openPersonal()}
                />
              </Flex>
              <Flex align="center">
                <IconButton
                  aria-label="Search database"
                  icon={<CheckCircleIcon />}
                  variant="ghost"
                  onClick={handleMarketing}
                  color={marketing ? "primary" : "gray.100"}
                />
                <Text fontSize="14px">
                  [선택] 개인정보의 마케팅 및 광고에 이용
                </Text>
                <Spacer />
                <IconButton
                  aria-label="Search database"
                  icon={<ChevronRightIcon />}
                  variant="ghost"
                  onClick={() => openMarketing()}
                />
              </Flex>
              <Button
                px={8}
                bg={"primary"}
                color={"white"}
                isLoading={isSubmitting}
                type="submit"
                isFullWidth
                mt={8}
                h={"44px"}
                borderRadius={4}
              >
                회원가입하기
              </Button>
            </Form>
          )}
        </Formik>
      </AuthContainer>
    </>
  );
};

export default SingUpPage;

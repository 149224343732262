import useSWR from "swr";
import { Note } from "@type/note";
import Axios from "axios";

export const useNote2 = async () => {
  const {
    data,
  } = await Axios.get("https://api2.potenup.kr​/notes");

  return data;
};


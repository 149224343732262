import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import { FiChevronLeft, FiMenu } from "react-icons/fi";

interface Prop {
  title: string;
  theme: "primary" | "white";
  onOpen: () => void;
}

const Header: React.FC<Prop> = ({ theme, title, onOpen }) => {
  const history = useHistory();
  return (
    <Flex
      position="sticky"
      top="0"
      bg={theme === "primary" ? "#5544D4" : "white"}
      color={theme === "primary" ? "white" : "black"}
      minH="44px"
      zIndex={100}
      align="center"
      px={2}
      justify="space-between"
    >
      <Flex flex={1} h="full">
        <IconButton
          onClick={() => history.goBack()}
          aria-label="back"
          icon={
            <FiChevronLeft color={theme === "primary" ? "white" : "black"} />
          }
          variant="link"
          fontSize={20}
        />
      </Flex>
      <Link to={"/"}>
        <Text flex={1} fontWeight="bold" fontSize="lg" textAlign="center">
          {title}
        </Text>
      </Link>
      <Flex flex={1} justifyContent="flex-end" h="full">
        <IconButton
          onClick={onOpen}
          aria-label="menu"
          icon={<FiMenu />}
          variant="link"
          fontSize={20}
          color={theme === "primary" ? "white" : "black"}
        />
      </Flex>
    </Flex>
  );
};

export default Header;

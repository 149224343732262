import React from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Container, Box, Flex, Image, IconButton } from "@chakra-ui/react";
import useUser from "@data/useUser";
import logo from "@assets/images/logo_hotisontal.png";
import { AiOutlineLeft } from "react-icons/ai";

const AuthContainer: React.FC = ({ children }) => {
  const history = useHistory();
  const { user } = useUser();
  if (user) return <Redirect to="/" />;
  return (
    <Container maxW="768px">
      <IconButton
        position="absolute"
        flex={1}
        onClick={() => history.goBack()}
        aria-label="back"
        icon={<AiOutlineLeft />}
        variant="link"
        fontSize={16}
        justifyContent="flex-start"
        top="22px"
      />
      <Container w={{ base: "100%", md: "375px" }} mx="auto" p={0}>
        <Flex h={"60px"} justify="center" pt={5}>
          <Link to="/">
            <Image src={logo} w="104px" h="24px" />
          </Link>
        </Flex>
        <Box maxW="311px" mx="auto">
          {children}
        </Box>
      </Container>
    </Container>
  );
};

export default AuthContainer;

import { Box, Text, Flex } from "@chakra-ui/react";
import useUser from "@data/useUser";
import Avatar from "@components/Avatar";

const MyHeader = () => {
  const { user } = useUser();
  return (
    <Flex py={4} px={6}>
      <Flex flex={1} align="center" justify="center">
        <Avatar user={user} />
        <Box ml={2}>
          <Text color="gray.900" fontWeight="500">
            {user.username + "님"}{" "}
            <Text as="span" color="gray.400" fontSize={"12px"}>
              {`${user.grade}등급`}
            </Text>
          </Text>
          <Text color="gray.400" fontSize="14px">
            오늘 하루 화이팅!
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default MyHeader;

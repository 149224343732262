import React, { createRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Box,
  Text,
  Flex,
  Center,
  CircularProgress,
  Image,
  useDisclosure,
  Progress,
  HStack,
  Spacer,
  Button,
  useMergeRefs,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import ExamHeader from "@layouts/ExamHeader";
import ExamDrawer from "@layouts/ExamDrawer";
import AnswerButton from "@components/AnswerButton";
import AnswerInput from "@components/AnswerInput";
import NavButton from "@components/NavButton";
import useUser from "@data/useUser";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineStar,
  AiFillStar,
} from "react-icons/ai";

import useTimer from "@hooks/useTimer";
import usePreventBack from "@hooks/usePreventBack";
import { convertMin } from "@util/time";
import { updateProblem } from "@services/problem";
import useSolveProblems from "@data/useSolveProblems";
import { updateExam } from "@services/exam";
import SubmitModal from "@components/modal/SubmitModal";
import MathView2 from "@components/MathView2"
const URL = "https://daesung.s3.ap-northeast-2.amazonaws.com/";

export interface RouteParams {
  bookId: string;
  chapterId: string;
  examId: string;
}

const ExamPage = () => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const submit = useDisclosure();
  const { bookId, chapterId, examId } = useParams<RouteParams>();
  const { user } = useUser();
  const { solveProblems, solveProblemsMutate } = useSolveProblems({
    id: user?.id,
    exam: examId,
  });
  const totalTime = solveProblems?.reduce((a, c) => a + c.solve_time, 0);
  const { total, min, sec, handleReset } = useTimer();
  const { setPrevent, PreventModal } = usePreventBack();
  const [step, setStep] = React.useState(1);
  const [answer, setAnswer] = React.useState<number | null>(null);
  const ref = React.useRef<HTMLInputElement>(null);
  const ref2 = React.useRef<HTMLInputElement>(null);
  const internalRef = React.useRef()
  const refs = useMergeRefs(internalRef, ref)

  const notes = solveProblems?.sort(
    (a, b) => a.problem.number - b.problem.number
  );
  const fff = document.getElementById("_focus")

  const problem = notes?.[step - 1];
  const keyFunction = (event: any) => {
    if (event.keyCode === 49) {
      console.log('1')
      setAnswer(1)
    }
    if (event.keyCode === 50) {
      console.log('2')
      setAnswer(2)
    }
    if (event.keyCode === 51) {
      console.log('3')
      setAnswer(3)
    }
    if (event.keyCode === 52) {
      console.log('4')
      setAnswer(4)
    }
    if (event.keyCode === 53) {
      console.log('5')
      setAnswer(5)
    }
    if (event.keyCode === 13) {
      console.log('enter')
      handleSubmit()
      handleNext()
    }
    if (event.keyCode === 37) {
      console.log('left')
      handlePrev()
    }
    if (event.keyCode === 39) {
      console.log('right')
      handleSubmit()
      handleNext()
    }
 };
  const isReadied = false;
  const setReady = (isReady: boolean) =>{
    if (ref.current) {
      ref.current.focus()
      ref.current.value = problem?.selected?.toString();
    }
    handleReset();
    setAnswer(problem?.selected);
    document.addEventListener("keydown", keyFunction, false);
    return () => {
      document.removeEventListener("keydown", keyFunction, false);
    };

  }
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      console.log('sec' , sec)
      // if(!isIframeLoading)
        // setReady(true) 
    }, 100) 
    return () => clearTimeout(timeout);
    
    
  }, [handleReset, problem]);

  const handleCheck = async () => {
    const body = { check: !problem.check };
    await updateProblem({ id: problem.id, body });
    await solveProblemsMutate();
  };

  const handlePrev = async () => {
    if (step === 1) return;
    setStep((value) => value - 1);
    await solveProblemsMutate();
  };

  const handleNext = async () => {
    if (answer && answer !== problem.selected) {
      const body = {
        selected: answer,
        answer: problem.problem.answer === answer,
        timeover: false,
        resolve: true,
        solve_time: total,
        exam: examId,
      };
      await updateProblem({ id: problem.id, body });
      await solveProblemsMutate();
    }
    if (step !== notes.length) setStep((value) => value + 1);
  };

  const handleSubmit = async () => {
    await updateExam({ id: examId, body: { is_submit: true } });
    setPrevent(false);
    history.push(`/book/${bookId}/chapter/${chapterId}/exam/${examId}/submit`)
  };

  if (!solveProblems || !problem) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  const isLast = step === notes?.length;
  const isEnd = notes?.every((el) => el.resolve);
  const active = isLast && isEnd;
  let isIframeLoading = true;
  let problemView;
  if (problem.problem.content == null || problem.problem.content == '') {
    if (problem.problem.img2?.url == null) {
      problemView = <Image src={URL + problem.problem.img} w="440px" mx={"auto"} />
    } else {      
      problemView = <Image src={problem.problem.img2?.url} w="440px" mx={"auto"} />
    }
  } else {
    problemView = <MathView2 value={problem.problem.content} onLoad={
      () => {
        isIframeLoading = false;
        setReady(true) 
        console.log('onLoad')
        ref2?.current?.focus();
        console.log(ref2)
      }
    }></MathView2>
  }

  return (
    <>
      <SubmitModal
        isOpen={submit.isOpen}
        onClose={submit.onClose}
        callback={handleSubmit}
      />
      <PreventModal />
      
      <Helmet
        title="문제풀이"
        keywords="문제풀이"
        description="문제풀이페이지 입니다."
      />
      <Layout title="단원보기" hide={false}  onBack={()=>history.push('/')}>
        <ExamDrawer
          isOpen={isOpen}
          onClose={onClose}
          submit={submit}
          isEnd={isEnd}
          setStep={setStep}
        />
        <ExamHeader onOpen={onOpen} onBack={() => 
          history.push(`/book/${bookId}`)
            }/>
        <Progress
          w="full"
          value={(step / notes?.length) * 100}
          size="sm"
          h="2px"
        />
        <Flex px={6} justify="space-between" mt={"6px"}>
          <Text
            fontWeight="500"
            borderWidth={1}
            rounded="full"
            px={2}
            py={1}
            color="primary"
            fontSize="12px"
          >{`${step} / ${notes?.length}`}</Text>
          {/* <Text
            fontWeight="500"
            borderWidth={1}
            rounded="full"
            px={2}
            py={1}
            color="primary"
            fontSize="12px"
          >
            {convertMin(totalTime)}
          </Text> */}
        </Flex>
        <Box overflowY="auto" h="calc(100vh - 227px)" pb={10}>
          {problemView}
        </Box>
        <Box
          w="full"
          bg="#fff"
          position="absolute"
          bottom="0"
          h="151px"
          borderTopWidth={1}
          pt={4}
        >
          <Box w="327px" mx="auto">
            <Flex
              align="center"
              borderBottomWidth={1}
              borderBottomStyle="dashed"
            >
              <Center w="40px" h="40px" bg="gray.100" fontSize="14px">
                {step}
              </Center>
              <div id='_focus'></div>
              <HStack ml={4} spacing={4}>
                {problem.problem.type === 1 ? (
                  [1, 2, 3, 4, 5].map((el) => (
                    <AnswerButton
                      key={el}
                      text={el}
                      selected={el === answer}
                      handleClick={() => setAnswer(el)}
                    />
                  ))
                ) : (
                  <AnswerInput  ref={ref} value={answer} onChange={setAnswer} />
                )}
              </HStack>
              <Spacer />
              <Text fontSize="12px">{`${min}분 ${sec}초`}</Text>
            </Flex>
            <Flex align="center" mt={4}>
              <NavButton handleClick={handlePrev}>
                <AiOutlineLeft />
              </NavButton>
              <Spacer />
              <Center
        color="gray.400"
        w="144px"
        h="44px"
        fontSize="14px"
        borderWidth={1}
        rounded="sm"
        cursor="pointer"
        onClick={handleCheck}
      >
                  {problem?.check ? (
                  <div ><div style={{display: 'inline-block', marginBottom: '-2px'}}><AiFillStar color="#6666FF" /> </div> <span style={{color:"#6666FF"}} > 다시풀기</span></div>
                ) : (
                  <div ><div style={{display: 'inline-block', marginBottom: '-2px'}}><AiFillStar color="#DDDDDD" /> </div> <span style={{color:"#DDDDDD"}} > 다시풀기</span></div>
                )}
</Center>             
<Box mr="10px" />
              {active ? (
                <Button
                  bg="primary"
                  size="sm"
                  color="white"
                  onClick={submit.onOpen}
                >
                  제출하기
                </Button>
              ) : (
                <NavButton handleClick={handleNext}>
                  <AiOutlineRight />
                </NavButton>
              )}
            </Flex>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default ExamPage;

import axios from "axios";
import { CalendarPost } from "../types/calendar";

export const addCalendar = (body: CalendarPost) => {
  return axios.post(`calendars`, body);
};

export const getCalendars = (user: string, start: string, end: string) => {
  return axios.get(
    `calendars?user=${user}&created_at_gte=${start}&created_at_lte=${end}`
  );
};

import useSWR from "swr";
import { SolveProblem } from "@type/solveProblem";

const useSolveProblems = ({
  id,
  exam,
}: {
  id: string | undefined;
  exam: string | undefined;
}) => {
  const { data, error, mutate } = useSWR(
    id ? `solve-problems?user=${id}&exam=${exam}` : null
  );
  return {
    isLoading: !error && !data,
    isError: error,
    solveProblems: data,
    solveProblemsMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    solveProblems: SolveProblem[];
    solveProblemsMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useSolveProblems;

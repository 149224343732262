import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Center,
  CircularProgress,
  Button,
  Flex,
  Text,
  Divider,
  HStack,
  useDisclosure,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import PostHeader from "@layouts/PostHeader";
import useQuestions from "@data/useQuestions";
import QuestionCard from "@components/QuestionCard";
import { FiEdit } from "react-icons/fi";
import { FiXCircle, FiSearch } from "react-icons/fi";
import MobileDrawer from "@layouts/MobileDrawer";

const QuestionsPage = () => {
  const history = useHistory();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const inputRef = React.useRef<any>(null);
  const {
    questions,
    filter,
    setFilter,
    search,
    setSearch,
    setSize,
    questionsMutate,
    isReachingEnd,
    isLoading,
  } = useQuestions();

  React.useEffect(() => {
    questionsMutate();
  }, [questionsMutate]);

  if (!questions)
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );

  const handleMore = () => setSize((value: number) => value + 1);

  return (
    <>
      <MobileDrawer isOpen={isOpen} onClose={onClose} />
      <Helmet
        title="프로그램문의"
        keywords="프로그램문의"
        description="프로그램문의페이지 입니다."
      />
      <Layout title="프로그램문의" hide={false} onBack={()=>history.push('/')}>
        <PostHeader title="프로그램문의" onOpen={onOpen} theme="white" />
        <Box bg="gray.100" flex={1}>
          <Box h="80px" position="relative" overflowY="hidden" bg="white">
            <Center h="80px">
              <form>
                <InputGroup size="md" w="327px" zIndex={10}>
                  <Input
                    ref={inputRef}
                    rounded="full"
                    onChange={(e) => setSearch(e.target.value)}
                    _focus={{ borderColor: "primary" }}
                    placeholder="질문을 검색하세요."
                  ></Input>
                  <InputRightElement width="4.5rem">
                    <FiXCircle
                      onClick={() => {
                        setSearch("");
                        if (inputRef && inputRef.current) {
                          inputRef.current.value = "";
                        }
                      }}
                      color={search ? "#3C2AA9" : "#fff"}
                    />
                    <Box mx={1} />
                    <FiSearch size="20px" color="#3C2AA9" />
                  </InputRightElement>
                </InputGroup>
              </form>
            </Center>
          </Box>
          <Flex p={2} px={4} justify="space-between" bg="gray.100">
            <HStack divider={<Divider orientation="vertical" />}>
              {[
                { value: "all", label: "전체" },
                { value: "book", label: "교재" },
                { value: "homework", label: "과제" },
                { value: "test", label: "모의고사" },
              ].map((el) => (
                <Text
                  key={el.value}
                  fontSize="14px"
                  cursor={"pointer"}
                  color={filter === el.value ? "black" : "gray.400"}
                  onClick={() => setFilter(el.value)}
                >
                  {el.label}
                </Text>
              ))}
            </HStack>
          </Flex>
          <Box borderTopWidth={1} bg="gray.100">
            {questions.map((question) => (
              <Link to={`/questions/${question.id}`} key={question.id}>
                <QuestionCard question={question} />
              </Link>
            ))}
            {!isReachingEnd && !isLoading && (
              <Flex justify="center" my={4}>
                <Button bg="primary" color="white" onClick={handleMore}>
                  더보기
                </Button>
              </Flex>
            )}
          </Box>
          <Link to={`/question-add`}>
            <Center
              position="fixed"
              bg="primary"
              shadow="md"
              rounded="full"
              w="62px"
              h="62px"
              right="24px"
              bottom="30px"
              zIndex={100}
              cursor="pointer"
              onClick={() =>
                window.scroll({ top: 0, left: 0, behavior: "smooth" })
              }
            >
              <FiEdit color="white" fontSize="26px" />
            </Center>
          </Link>
        </Box>
      </Layout>
    </>
  );
};

export default QuestionsPage;

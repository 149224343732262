import useSWR from "swr";
import { Book } from "@type/book";

const useBooks = ({ ids }: { ids: string | undefined }) => {
  const { data, error, mutate } = useSWR(ids ? `books?${ids}` : null);
  return {
    isLoading: !error && !data,
    isError: error,
    books: data,
    booksMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    books: Book[];
    booksMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useBooks;

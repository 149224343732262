import axios from "axios";

interface LoginProp {
  identifier: string;
  password: string;
}

interface ForgetProp {
  email: string;
}

interface ResetProp {
  code: string;
  password: string;
}

export const login = (body: LoginProp) => {
  return axios.post(`auth/local`, body);
};

export const getMe = () => {
  return axios.get(`users/me`);
};

export const updateUser = ({ id, body }: any) => {
  return axios.put(`users/${id}`, body);
};

export const getNickname = (nickname: string) => {
  return axios.get(`users?nick_name=${nickname}`);
};

export const getMyBooks = (id: string) => {
  return axios.get(`enrollbooks?student=${id}`);
};

export const signUp = async (body: any) => {
  const result = await axios.post("auth/local/register", body);
  return result;
};

export const forgetPassword = ({ email }: ForgetProp) => {
  return axios.post("auth/forgot-password", { email });
};

export const resetPassword = ({ code, password }: ResetProp) => {
  return axios.post("auth/reset-password", {
    code: code,
    password: password,
    passwordConfirmation: password,
  });
};

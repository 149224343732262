import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";

import LoginPage from "@screens/auth/LoginPage";
import SignUpPage from "@screens/auth/SignUpPage";
import PasswordResetPage from "@screens/auth/PasswordResetPage";
import ResetPage from "@screens/auth/ResetPage";

import HomePage from "@screens/HomePage";
import HomeMyPage from "@screens/HomeBookPage";
import HomeNotePage from "@screens/HomeNotePage";
import HomeExamPage from "@screens/HomeExamPage";

import HistoryBookPage from "@screens/HistoryBookPage";
import HistoryExamPage from "@screens/HistoryExamPage";
import HistoryNotePage from "@screens/HistoryNotePage";
import HistoryDetailPage from "@screens/HistoryDetailPage";

import BookPage from "@screens/BookPage";
import ExamPage from "@screens/ExamPage";
import NotePage from "@screens/NotePage";
import ProblemPage from "@screens/ProblemPage";
import OmrPage from "@screens/OmrPage";
import ExamPreviewPage from "@screens/ExamPreviewPage";
import NotePreviewPage from "@screens/NotePreviewPage";
import OmrNotePage from "@screens/OmrNotePage";
import AnswerPage from "@screens/AnswerPage";
import AnswerNotePage from "@screens/AnswerNotePage";
import AnswerNotePrintPage from "@screens/AnswerNotePrintPage";
import PostPage from "@screens/PostPage";
import PostAddPage from "@screens/PostAddPage";
import PostEditPage from "@screens/PostEditPage";
import PostsPage from "@screens/PostsPage";
import QuestionPage from "@screens/QuestionPage";
import QuestionEditPage from "@screens/QuestionEditPage";
import QuestionAddPage from "@screens/QuestionAddPage";
import QuestionsPage from "@screens/QuestionsPage";
import MyQuestionPage from "@screens/MyQuestionPage";
import MyPostPage from "@screens/MyPostPage";
import MySettingPage from "@screens/MySettingPage";
import MyEditPage from "@screens/MyEditPage";
import MyPasswordPage from "@screens/MyPasswordPage";
import SubscriptionPage from "@screens/SubscriptionPage";
import NoticePage from "@screens/NoticePage";

import useUser from "@data/useUser";
import HistoryBookNotePage from "@screens/HistoryBookNotePage";
import AnswerBookNotePage from "@screens/AnswerBookNotePage";

const AuthRoute: React.FC<RouteProps> = ({
  exact,
  path,
  component,
  ...rest
}) => {
  const { user } = useUser();
  const Component = component as React.ElementType;
  return (
    <Route
      exact={exact}
      path={path}
      render={() => {
        if (!user) return <Redirect to="/login" />;
        return <Component {...rest} />;
      }}
    />
  );
};

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <AuthRoute exact path="/home-my" component={HomePage} />
        <AuthRoute exact path="/" component={HomeMyPage} />
        <AuthRoute exact path="/home-test" component={HomeExamPage} />
        <AuthRoute exact path="/home-note" component={HomeNotePage} />
        <AuthRoute exact path="/subscription" component={SubscriptionPage} />
        <AuthRoute exact path="/book/:bookid" component={BookPage} />
        <AuthRoute exact path="/history-book/:bookid" component={HistoryBookNotePage} />
        <AuthRoute exact path="/problem/:problemId" component={ProblemPage} />
        <AuthRoute exact path="/posts" component={PostsPage} />
        <AuthRoute exact path="/post-add" component={PostAddPage} />
        <AuthRoute exact path="/posts/:postId/edit" component={PostEditPage} />
        <AuthRoute exact path="/posts/:postId" component={PostPage} />
        <AuthRoute exact path="/questions" component={QuestionsPage} />
        <AuthRoute exact path="/question-add" component={QuestionAddPage} />
        <AuthRoute
          exact
          path="/question-add/:problemId"
          component={QuestionAddPage}
        />
        <AuthRoute
          exact
          path="/questions/:questionId/edit"
          component={QuestionEditPage}
        />
        <AuthRoute exact path="/history-book" component={HistoryBookPage} />
        <AuthRoute exact path="/history-exam" component={HistoryExamPage} />
        <AuthRoute exact path="/history-note" component={HistoryNotePage} />
        <AuthRoute
          exact
          path="/history/:examId"
          component={HistoryDetailPage}
        />
        <AuthRoute
          exact
          path="/questions/:questionId"
          component={QuestionPage}
        />
        <AuthRoute exact path="/book/:bookId/chapter/:chapterId/exam" component={ExamPreviewPage} />
        <AuthRoute exact path="/book/:bookId/chapter/:chapterId/exam/:examId/" component={ExamPage} />
        <AuthRoute exact path="/book/:bookId/chapter/:chapterId/exam/:examId/:isSubmit" component={ExamPreviewPage} />
        <AuthRoute exact path="/note/:noteId/" component={NotePage} />
        <AuthRoute exact path="/note/:noteId/preview" component={NotePreviewPage} />
        <AuthRoute exact path="/exam/:examId/omr" component={OmrPage} />
        <AuthRoute exact path="/note/:noteId/omr" component={OmrNotePage} />
        <AuthRoute
          exact
          path="/book/:bookid/chapter/:chapterid/answer"
          component={AnswerPage}
        />
        <AuthRoute
          exact
          path="/history-book/:bookid/chapter/:chapterid/answer"
          component={AnswerBookNotePage}
        />
        <AuthRoute exact path="/answer/:noteId" component={AnswerNotePage} />
        <AuthRoute exact path="/answer/:noteId/print" component={AnswerNotePrintPage} />
        <AuthRoute exact path="/notice" component={NoticePage} />
        <AuthRoute exact path="/my-question" component={MyQuestionPage} />
        <AuthRoute exact path="/my-post" component={MyPostPage} />
        <AuthRoute exact path="/my-setting" component={MySettingPage} />
        <AuthRoute exact path="/my-edit" component={MyEditPage} />
        <AuthRoute exact path="/my-password" component={MyPasswordPage} />
        <Route exact path="/login" component={LoginPage}></Route>
        <Route exact path="/signup" component={SignUpPage}></Route>
        <Route
          exact
          path="/password-reset"
          component={PasswordResetPage}
        ></Route>
        <Route exact path="/reset" component={ResetPage}></Route>
      </Switch>
    </Router>
  );
};

export default AppRouter;

import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Text,
  Modal,
  ModalContent,
  ModalBody,
  VStack,
} from "@chakra-ui/react";

const AuthModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const history = useHistory();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalContent w="320px" shadow="2xl">
        <ModalBody p={8}>
          <Box textAlign="center">
            <Text fontSize="18px" mb={3}>
              교재가 등록되었어요.
            </Text>
            <Text color="gray.400" fontSize="14px" mb={4}>
              나의 교재에서 확인하세요.
            </Text>
            <VStack w="full">
              <Button
                isFullWidth
                bg="primary"
                color="white"
                onClick={() => {
                  onClose();
                  history.push("/");
                }}
                h="44px"
              >
                나의 교재 보러가기
              </Button>
              <Button
                isFullWidth
                bg="#6666FF34"
                h="44px"
                onClick={() => onClose()}
              >
                <Text color="primary">교재 더 등록하기</Text>
              </Button>
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AuthModal;

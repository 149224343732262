import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Flex,
  Box,
  Divider,
  Heading,
  Text,
  Center,
  Spacer,
  IconButton,
  HStack,
  Menu,
  MenuButton,
  MenuList,Image,
  MenuItem,
  Button,
  Progress,
  CircularProgress,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import HomeTab from "@layouts/HomeTab";
import HomeHeader from "@layouts/HomeHeader";
import useUser from "@data/useUser";
import {
  useNote2,
} from "@data/useNote2";
import useEnrollBooks from "@data/useEnrollBooks";
import { AiOutlineRight } from "react-icons/ai";
import moment from "moment";
import { ChevronDownIcon } from "@chakra-ui/icons";
import useChapters from "@data/useChapters";
import useExams from "@data/useExams";

const HomeBookPage = () => {
  const history = useHistory();
  const [filter, setFilter] = React.useState("all");
  const [sort, setSort] = React.useState("recent");
  const [notes, setNotes] = React.useState([]);
  const { user } = useUser();
  const note = useNote2();
  const { enrollBooks } = useEnrollBooks({ id: user?.id, type: filter });
  const ids = enrollBooks?.map((el) => `book_in=${el.id}`).join("&");
  const { chapters } = useChapters({ ids: ids });
  const { exams } = useExams({ userId: user?.id });



  // useEffect(() => {
  //   const a = async () => {
  //     setNotes(await note);
  //   }
  //   a();
  // }, [])


  const total = chapters?.reduce((acc, cur) => acc + cur.problems.length, 0);

  const currentPoint = React.useMemo(() => {
    const chapterId = Array.from(
      new Set(
        exams
          ?.filter((el) => el.is_submit)
          .filter((el) => {
            if (filter === "book") return !el.book.name.includes("과제");
            if (filter !== "book") return el.book.name.includes("과제");
            return true;
          })
          .map((el) => el.chapter.id)
      )
    );
    return chapterId.reduce((acc, cur) => {
      if (!exams) return acc;
      const chapter = exams.find((el) => el.chapter.id === cur);
      return chapter ? acc + chapter.solve_problems.length : acc;
    }, 0);
  }, [exams, filter]);

  const totalBookId = (bookid: string) => {
    return enrollBooks?.filter((el) => ((el.id as unknown) as number) === +bookid)
    ?.reduce((acc, cur) => acc + cur.problems.length, 0);
  }

  const currentPointBookId = (bookid: string) => {
    const chapterId = Array.from(
      new Set(
        exams
          ?.filter((el) => el.is_submit)
          ?.filter((el) => ((el.book.id as unknown) as number) === +bookid)
          .map((el) => el.chapter.id)
      )
    );
    return chapterId.reduce((acc, cur) => {
      if (!exams) return acc;
      const chapter = exams.find((el) => el.chapter.id === cur);
      return chapter ? acc + chapter.solve_problems.length : acc;
    }, 0);
  };

  if (enrollBooks === undefined ) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  return (
    <>
      <Helmet
        title="홈페이지"
        keywords="홈페이지"
        description="홈페이지페이지 입니다."
      />
      <Layout title="나의 포텐셜" noArrow>
        <Box>
          <HomeHeader />

          <Box d={{ md: "flex" }}>
            <Box flex={1} mt={{ base: 4, md: 0 }} p={"1.5rem"} py={{ sm: "0", md: "1.5rem" }}>
              <Text fontWeight="500" fontSize="14px">
                {`${filter === "all"
                  ? "전체 "
                  : filter === "book"
                    ? "교재"
                    : "과제"
                  } 학습 진도`}
              </Text>
              <Text color="gray.400" fontSize={"10px"}>
                {`${total}문제 중 ${currentPoint}개 문제를 학습했어요.`}
              </Text>
              <Flex align="center">
                <Progress
                  w="full"
                  value={(currentPoint / total) * 100}
                  size="sm"
                  rounded="full"
                />
                <Text color="primary" fontWeight="700" ml={2}>
                  {`${((currentPoint / total) * 100).toFixed(0)}%`}
                </Text>
              </Flex>
            </Box>
            <Box flex={1} mt={{ base: 4, md: 0 }} p={"1.5rem"}>
              <Text fontWeight="500" fontSize="14px">
                <span><img src="/new.svg" style={{ display: "inline" }} /> 최신 오답 노트</span>
              </Text>
              <Box d={"flex"} mt={"5px"} overflow={"overlay"}>
                {/* <Box width={"auto"} bg={"#FFF5D1"} borderRadius={4} padding={"12px 14px"} flex={"0 0 auto"}>
                  <Text fontWeight="700" fontSize={14}>
                    나만의 오답노트 9회차
                  </Text>
                  <Text fontWeight="300" fontSize={12}>
                    21.04.08
                  </Text>
                </Box>
                <Box width={"auto"} bg={"#F7F7F7"} borderRadius={4} padding={"12px 14px"} flex={"0 0 auto"} marginLeft={"8px"}>
                  <Text fontWeight="700" fontSize={14}>
                    나만의 오답노트 9회차
                  </Text>
                  <Text fontWeight="300" fontSize={12}>
                    21.04.08
                  </Text>
                </Box> */}
                {notes.map((noteItem: any, key) => {
                  const noteDate = new Date(noteItem.updated_at);

                  function getFormatDate(date: Date) {
                    var year = date.getFullYear();
                    let month: number | string = 1 + date.getMonth();
                    month = month >= 10 ? month : '0' + month;
                    let day: number | string = date.getDate();
                    day = day >= 10 ? day : '0' + day;
                    return year + '-' + month + '-' + day;
                  }

                  return (
                    <Box width={"auto"} bg={key == 0 ? "#FFF5D1" : "#F7F7F7"} borderRadius={4} padding={"12px 14px"} flex={"0 0 auto"} marginLeft={"8px"}>
                      <Text fontWeight="700" fontSize={14}>
                        {noteItem.name}
                      </Text>
                      <Text fontWeight="300" fontSize={12}>
                        {getFormatDate(noteDate)}
                      </Text>
                    </Box>
                  )
                })}

                {notes.length == 0 && (
                  <Box width={"auto"} bg={"#F7F7F7"} borderRadius={4} padding={"12px 14px"} flex={"0 0 auto"}>
                    <Text fontWeight="700" fontSize={14}>
                      오답노트가 없습니다.
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <HomeTab />
          <Divider></Divider>
          <Flex p={4} px={6} justify="space-between">
            <HStack divider={<Divider orientation="vertical" />}>
              {[
                { value: "all", label: "전체" },
                { value: "book", label: "교재" },
                { value: "homework", label: "과제" },
              ].map((el) => (
                <Text
                  key={el.value}
                  fontSize="14px"
                  cursor={"pointer"}
                  color={filter === el.value ? "black" : "gray.400"}
                  onClick={() => setFilter(el.value)}
                >
                  {el.label}
                </Text>
              ))}
            </HStack>
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    size="sm"
                    bg="#fff"
                    borderWidth={1}
                    rounded="none"
                    color="gray.400"
                    fontSize="14px"
                  >
                    {sort === "recent" ? "최신순" : "이름순"}
                  </MenuButton>
                  <MenuList minW="auto" w="84px" rounded="none">
                    {[
                      { value: "recent", label: "최신순" },
                      { value: "name", label: "이름순" },
                    ].map((el) => (
                      <MenuItem
                        fontSize="14px"
                        color="gray.400"
                        key={el.value}
                        onClick={() => setSort(el.value)}
                      >
                        {el.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
          <Box>
          {enrollBooks == undefined ? 
            <Box px={6}><Text color="gray.600" fontSize={"15px"}>아직 등록된 교재가 없습니다. 등록해주세요.</Text></Box> 
            : enrollBooks
              .sort((a, b) => {
                if (sort === "name") {
                  return b.name < a.name ? 1 : -1;
                } else {
                  return +b.id - +a.id;
                }
              })
              .map((el) => (
                <Link to={`/book/${el.id}`} key={el.id}>
                  <Flex
                    px={6}
                    h="140px"
                    borderBottomWidth={1}
                    _hover={{ bg: "gray.50" }}
                    cursor="pointer"
                    align="left"
                    direction="column"
                  >
                    <Flex
                      h="90px"
                      align="center"
                    >
                      <Box w={"50px"} h={"50px"} bg="gray.300" mr={3}>
                        <Center h="full" fontSize="xs">
                        <Image 
                          src={el.image?.url} w="48px"
                          h="48px"
                        />
                        </Center>
                      </Box>
                      <Box>
                        <Heading fontSize="16px" fontWeight="500" mb={1}>
                          {el.name}
                        </Heading>
                        <Text color="gray.300" fontSize={"10px"}>
                          {moment(el.created_at).format("YY.MM.DD")}
                        </Text>
                      </Box>
                      <Spacer />
                      <IconButton
                        aria-label="menu"
                        icon={<AiOutlineRight />}
                        variant="link"
                        fontSize={"16px"}
                        color="gray.400"
                      />
                    </Flex>
                    <Box flex={1} mt={{ base: 4, md: 0 }}>
                      <Flex align="center">
                        <Progress
                          w="full"
                          value={(currentPointBookId(el.id) / totalBookId(el.id)) * 100}
                          size="sm"
                          rounded="full"
                        />
                        <Text color="primary" fontWeight="700" ml={2}>
                          {`${((currentPointBookId(el.id) / totalBookId(el.id)) * 100).toFixed(0)}%`}
                        </Text>
                      </Flex>
                    </Box>
                    </Flex>
                </Link>
              ))}
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default HomeBookPage;

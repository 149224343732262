import React from "react";
import { useSWRInfinite } from "swr";
import { Exam } from "@type/exam";

const SIZE = 10;

const usePosts = ({
  id,
  type,
  test = "book",
}: {
  id: string | undefined;
  type?: string | undefined;
  test?: "test" | "book";
}) => {
  const [sort, setSort] = React.useState("recent");
  const { data, error, mutate, size, setSize } = useSWRInfinite((idx) => {
    let query = "";
    if (type === "all") query = "";
    if (type === "book") query = "&book.name_ncontains=과제";
    if (type === "homework") query = "&book.name_contains=과제";
    if (sort === "recent") query += "&_sort=id:DESC";
    if (sort === "view") query += "&_sort=view:DESC";
    if (sort === "comment") query += "&_sort=comments_count:DESC";
    if (sort === "like") query += "&_sort=likes_count:DESC";
    if (test === "book") query += "&book.is_test=false";
    if (test !== "book") query += "&book.is_test=true";
    return `exams?user=${id}&_start=${idx * SIZE}&_limit=${SIZE}${query}`;
  });

  const issues = data ? [].concat(...data) : [];
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < SIZE);

  return {
    sort,
    setSort,
    size,
    setSize,
    exams: issues,
    isLoading: !error && !data,
    isError: error,
    isReachingEnd,
    examsMutate: mutate,
  } as {
    sort: string;
    setSort: any;
    size: number;
    setSize: any;
    exams: Exam[];
    isLoading: boolean;
    isError: any;
    isReachingEnd: boolean;
    examsMutate: any;
  };
};

export default usePosts;

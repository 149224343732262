import { Center } from "@chakra-ui/react";

interface Prop {
  text: number;
  selected: boolean;
  handleClick: () => void;
}

const AnswerButton = ({ text, selected, handleClick }: Prop) => {
  return (
    <Center
      w="20px"
      h="26px"
      bg={selected ? "primary" : "white"}
      color={selected ? "white" : "gray.300"}
      borderWidth={1}
      fontSize="14px"
      onClick={handleClick}
      rounded="full"
      cursor="pointer"
    >
      {text}
    </Center>
  );
};

export default AnswerButton;

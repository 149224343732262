import axios from "axios";

interface Problem {
  num: number;
  problem: string;
  user: string;
}

interface ProblemUpdate {
  num?: number;
  answer?: boolean;
  timeover?: boolean;
  resolve?: boolean;
  solve_time?: number;
  check?: boolean;
}

export const addProblem = (body: Problem) => {
  return axios.post(`solve-problems`, body);
};

export const updateProblem = ({
  id,
  body,
}: {
  id: string;
  body: ProblemUpdate;
}) => {
  return axios.put(`solve-problems/${id}`, body);
};

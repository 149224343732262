import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import { FiChevronLeft, FiBell, FiMenu } from "react-icons/fi";

interface Prop {
  title: string;
  noArrow?: boolean;
  noAction?: boolean;
  noNotiy?: boolean;
  onBack?: () => void;
  onOpen: () => void;
}

const Header: React.FC<Prop> = ({
  noArrow,
  title,
  noAction,
  noNotiy = true,
  onOpen,
  onBack
}) => {
  return (
    <Flex
      position="sticky"
      top="0"
      bg="white"
      minH="44px"
      zIndex={100}
      align="center"
      px={2}
      justify="space-between"
    >
      <Flex flex={1} h="full">
        {!noArrow && (
          <IconButton
            onClick={onBack}
            aria-label="back"
            icon={<FiChevronLeft />}
            variant="link"
            fontSize={20}
          />
        )}
      </Flex>
      <Link to={"/"}>
        <Text flex={1} fontWeight="bold" fontSize="lg" textAlign="center">
          {title}
        </Text>
      </Link>
      <Flex flex={1} justifyContent="flex-end" h="full">
        {!noAction && (
          <>
            {!noNotiy && (
              <IconButton
                onClick={onOpen}
                aria-label="nofication"
                icon={<FiBell />}
                variant="link"
                fontSize={20}
              />
            )}
            <IconButton
              onClick={onOpen}
              aria-label="menu"
              icon={<FiMenu />}
              variant="link"
              fontSize={20}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default Header;

import React from "react";
import { useHistory } from "react-router";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import { AiOutlineLeft } from "react-icons/ai";

const TestHeader: React.FC = ({ children }) => {
  const history = useHistory();
  return (
    <Flex
      position="sticky"
      top="0"
      bg="white"
      h="44px"
      zIndex={100}
      align="center"
      px={2}
      justify="space-between"
      borderBottomWidth={1}
    >
      <Flex flex={1} h="full">
        <IconButton
          onClick={() => history.goBack()}
          aria-label="back"
          icon={<AiOutlineLeft />}
          variant="link"
          fontSize={20}
        />
      </Flex>
      <Text flex={1} fontWeight="bold" fontSize="lg" textAlign="center">
        OMR
      </Text>
      <Flex flex={1} justifyContent="flex-end">
        {children}
      </Flex>
    </Flex>
  );
};

export default TestHeader;

import axios from "axios";
import { PostPost, PostPut } from "../types/post";

export const addPost = (body: PostPost) => {
  return axios.post(`posts`, body);
};

export const updatePost = ({ id, body }: { id: string; body: PostPut }) => {
  return axios.put(`posts/${id}`, body);
};

export const updateLikePost = ({ id, body }: { id: string; body: any }) => {
  return axios.put(`posts/${id}/like`, body);
};

export const deletePost = (id: string) => {
  return axios.delete(`posts/${id}`);
};

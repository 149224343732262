import useSWR from "swr";
import { Post } from "@type/post";

const usePost = ({ id }: { id: string | undefined }) => {
  const { data, error, mutate } = useSWR(id ? `posts/${id}` : null);
  return {
    post: data,
    isLoading: !error && !data,
    isError: error,
    postMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    post: Post;
    postMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default usePost;

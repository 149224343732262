import useSWR from "swr";
import { Answer } from "@type/answer";

const useAnswers = (id: string) => {
  const { data, error, mutate } = useSWR(`answers?question=${id}`);
  return {
    answers: data,
    isLoading: !error && !data,
    isError: error,
    answersMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    answers: Answer[];
    answersMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useAnswers;

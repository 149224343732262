import React, { useState } from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";
import {
  Box,
  Center,
  Divider,
  Flex,
  Text,Image,
  CircularProgress,
  Progress,
  Modal,
  ModalContent,
  ModalBody,
  Button,
  HStack,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import ExamInfoModal from "@components/modal/ExamInfoModal";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import useBook from "@data/useBook";
import useUser from "@data/useUser";
import useEnrollBooks from "@data/useEnrollBooks";
import useChapters from "@data/useChapters";
import { useTestModal } from "@hooks/useTestModal";

import { Chapter } from "@type/chapter";
import { convertDateTime } from "@util/time";
import useExams from "@data/useExams";
import ChapterCard from "@components/ChapterCard";
import { AiOutlineArrowUp } from "react-icons/ai";
import moment from "moment";
import "moment/locale/ko";

export interface RouteParams {
  bookid: string;
}

const BookPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const history = useHistory();
  const { bookid } = useParams<RouteParams>();
  const { user } = useUser();
  const { enrollBooks } = useEnrollBooks({ id: user?.id });
  const { exams } = useExams({ userId: user?.id });
  const { book } = useBook({ id: bookid });
  const { chapters } = useChapters({ id: bookid });
  const {
    setExam,
    setChapter,
    setType,
    setTitle,
    setSubTitle,
    setRoute,
    TestModal
  } = useTestModal({ testType: "chapter" });

  const disclosure = useDisclosure();

  const [els, setEl] = useState<Chapter | null>(null);
  const [notice, setNotice] = useState(false);

  const total = chapters?.reduce((acc, cur) => acc + cur.problems.length, 0);

  const currentPoint = React.useMemo(() => {
    const chapterId = Array.from(
      new Set(
        exams
          ?.filter((el) => el.is_submit)
          ?.filter((el) => ((el.book.id as unknown) as number) === +bookid)
          .map((el) => el.chapter.id)
      )
    );
    return chapterId.reduce((acc, cur) => {
      if (!exams) return acc;
      const chapter = exams.find((el) => el.chapter.id === cur);
      return chapter ? acc + chapter.solve_problems.length : acc;
    }, 0);
  }, [bookid, exams]);

  const handleTest = async (chapter: Chapter | null) => {
    setChapter(chapter);
    const currentExams = exams.filter((el) => el.chapter.id === chapter?.id);
    const completed = currentExams.every((el) => el.is_submit);
    const first = currentExams.length === 0;
    const exam = currentExams.find((el) => !el.is_submit);
    const current = exam?.solve_problems.filter((el) => el.resolve).length;
    if (first) {
      setTitle("학습을 시작하시겠습니까?");
      setSubTitle("시작 직후 학습 시간이 카운트 됩니다.");
      setType("new");
    } else if (completed) {
      setTitle(`학습이 완료되었습니다.\n다시 하겠습니까?`);
      setSubTitle("시작 직후 학습 시간이 카운트 됩니다.");
      setType("again");
    } else if (exam && current === 0) {
      setExam(exam);
      setTitle("임시 저장된 학습이력이 있습니다.\n이어서 하시겠어요?");
      const date = exam.solve_problems
        .map((el) => new Date(el.updated_at) as any)
        .sort((a, b) => b - a)
        .pop();
      const text = convertDateTime(date);
      setSubTitle(text);
      setType("never");
    } else if (exam && current) {
      setExam(exam);
      const date = exam.solve_problems
        .map((el) => new Date(el.updated_at) as any)
        .sort((a, b) => b - a)
        .pop();
      const text = convertDateTime(date);
      setTitle(`임시 저장된 학습이력이 있습니다.\n이어서 하시겠어요?`);
      setSubTitle(text);
      setType("continue");
    }
    onOpen();
  };

  if (!enrollBooks || !book)
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );

  if (!enrollBooks.map((el) => +el.id).includes(+bookid))
    return <Redirect to="/" />;

  const showToTop = chapters && chapters.length > 10;
  

  return (
    <>
      <ExamInfoModal isOpen={isOpen} onClose={onClose} />
      <Helmet
        title={`단원보기 - ${book.name}`}
        keywords="단원보기"
        description="단원보기페이지 입니다."
      />
      <Layout title="단원보기" onBack={()=>history.push('/')}>
        <Box pb={2}>
          <Flex
            p={3}
            px={6}
            align={{ base: "stretch", md: "center" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Flex flex={1}>
              <Box w={"50px"} h={"50px"} bg="gray.100" mr={3}>
                <Center h="full" fontSize="xs">
                <Image 
                  src={book.image?.url} w="48px"
                  h="48px"
                />
                </Center>
              </Box>
              <Box>
                <Text fontWeight="500" mb={1}>
                  {book.name} 
                </Text>
                <Text color="gray.400" fontSize={"12px"}>
                  {moment(book.created_at).format("YY.MM.DD")}
                </Text>
              </Box>
            </Flex>
            <Divider
              orientation="vertical"
              borderWidth={1}
              h="80px"
              mx={4}
              d={{ base: "none", md: "block" }}
            />
            <Box flex={1} mt={{ base: 4, md: 0 }}>
              <Text fontWeight="500" fontSize="14px">
                교재 학습 진도
              </Text>
              <Text color="gray.400" fontSize={"10px"}>
                {`${total}문제 중 ${currentPoint}개 문제를 학습했어요.`}
              </Text>
              <Flex align="center">
                <Progress
                  w="full"
                  value={(currentPoint / total) * 100}
                  size="sm"
                  rounded="full"
                />
                <Text color="primary" fontWeight="700" ml={2}>
                  {`${((currentPoint / total) * 100).toFixed(0)}%`}
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Divider borderTopWidth={1} py={1} bg="gray.100"></Divider>
          {chapters &&
            chapters.map((el) => (
              <ChapterCard
                key={el.id}
                chapter={el}
                handleAnswer={() => {
                  setEl(el)
                  history.push(`/book/${book.id}/chapter/${el.id}/answer`);
                }}
                handleOmr={() => {
                  setEl(el)
                  setRoute("omr");
                  handleTest(el);
                  history.push(`/book/${book.id}/chapter/${el.id}/exam`);

                }}
                handleChapter={() => {
                  setEl(el)
                  setNotice(true);
                  history.push(`/book/${book.id}/chapter/${el.id}/exam`); // preview 생략 


                }}
              />
            ))}
          {showToTop && <Box h="72px" />}
          {showToTop && (
            <Center
              position="fixed"
              bg="white"
              shadow="md"
              rounded="full"
              w="40px"
              h="40px"
              left="calc(50% - 20px)"
              top="calc(100vh - 56px)"
              zIndex={100}
              cursor="pointer"
              _hover={{ bg: "gray.50" }}
              onClick={() =>
                window.scroll({ top: 0, left: 0, behavior: "smooth" })
              }
            >
              <AiOutlineArrowUp />
            </Center>
          )}
        </Box>
      </Layout>

      <Modal isOpen={notice} onClose={() => { setNotice(false); }} isCentered>
        <ModalContent w="320px" shadow="2xl">
          <ModalBody p={8}>
            <Box textAlign="center">
              <Box py={8}>
                <Text fontSize="16px" fontWeight="700" mb={3} pb={3} whiteSpace="pre-line" borderBottom={"1px"} borderColor={"#e7e7e7"}>
                  학습하기 이렇게 사용하세요!
                </Text>
                <Text color="gray.400" fontSize="14px" mb={4}>
                  <img src="/work_01.svg" />
                </Text>
              </Box>
              <VStack w="full">
                <Button
                  isFullWidth
                  bg="primary"
                  color="white"
                  h="44px"
                  onClick={() => {
                    setNotice(false);
                    setRoute("chapter");
                    handleTest(els);
                  }}
                >
                  확인하기
                </Button>
                <Button
                  isFullWidth
                  bg="#6666FF34"
                  color="primary"
                  onClick={() => setNotice(false)}
                  h="44px"
                >
                  다신 보지 않기
                </Button>
              </VStack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BookPage;

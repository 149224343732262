import { useSWRInfinite } from "swr";
import { Notice } from "@type/notice";

const SIZE = 10;

const useNotices = () => {
  const url = "notices";
  const { data, error, mutate, size, setSize } = useSWRInfinite((idx) => {
    return `${url}?&_sort=id:DESC&_start=${idx * SIZE}&_limit=${SIZE} `;
  });

  const issues = data ? [].concat(...data) : [];
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < SIZE);

  return {
    size,
    setSize,
    notices: issues,
    isLoading: !error && !data,
    isError: error,
    isReachingEnd,
    noticesMutate: mutate,
  } as {
    size: number;
    setSize: any;
    notices: Notice[];
    isLoading: boolean;
    isError: any;
    isReachingEnd: boolean;
    noticesMutate: any;
  };
};

export default useNotices;

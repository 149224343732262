import useSWR from "swr";
import { Exam } from "@type/exam";

const useExams = ({
  userId,
  chapterId,
  type,
  test = "book",
}: {
  userId: string | undefined;
  chapterId?: string | undefined;
  type?: string | undefined;
  test?: "test" | "book";
}) => {
  const { data, error, mutate } = useSWR(() => {
    let query = "";
    let isTest = "";
    let queryChapter = chapterId? `&chapter=${chapterId}` : '';
    if (type === "all") query = "";
    if (type === "book") query = "&book.name_ncontains=과제";
    if (type === "homework") query = "&book.name_contains=과제";
    if (test === "book") isTest = "&book.is_test=false";
    if (test !== "book") isTest = "&book.is_test=true";
    return userId ? `exams?user=${userId}${queryChapter}&_sort=id:DESC${isTest}${query}` : null;
  });
  return {
    exams: data,
    isLoading: !error && !data,
    isError: error,
    examsMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    exams: Exam[];
    examsMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useExams;

import React from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  CircularProgress,
  HStack,
  Text,
  Center,
  Container,
  Flex,
  Button,
  IconButton,
  useDisclosure,
  Input,
  Spacer,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import OmrHeader from "@layouts/OmrHeader";
import useUser from "@data/useUser";
import usePreventBack from "@hooks/usePreventBack";
import useNoteProblems from "@data/useNoteProblems";
import { convertMin, convertTimeFormat } from "@util/time";
import { SolveProblem } from "@type/solveProblem";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import AnswerButton from "@components/AnswerButton";
import AnswerInput from "@components/AnswerInput";
import SubmitModal from "@components/modal/SubmitModal";
import { updateProblem } from "@services/noteProblem";
import { updateExam } from "@services/noteExam";

const AnswerContainer = React.memo(
  ({ problem, mutate }: { problem: SolveProblem; mutate: () => void }) => {
    const { min, sec } = convertTimeFormat(problem.solve_time);
    const [answer, setAnswer] = React.useState(problem.selected);
    const [minute, setMinute] = React.useState(min || 0);
    const [second, setSecond] = React.useState(sec || 0);

    const handleCheck = async (problem: any) => {
      const body = { check: !problem.check };
      await updateProblem({ id: problem.id, body });
      await mutate();
    };

    React.useEffect(() => {
      if (answer === problem.selected && minute === min && second === sec)
        return;
      const body = {
        selected: answer,
        answer: problem.problem.answer === answer,
        timeover: false,
        resolve: true,
        solve_time: minute * 60 + second,
      };
      updateProblem({ id: problem.id, body });
      mutate();
    }, [mutate, min, sec, problem, answer, minute, second]);

    return (
      <Flex align="center">
        <IconButton
          onClick={() => handleCheck(problem)}
          variant="ghost"
          aria-label="check"
          icon={
            problem.check ? (
              <AiFillStar color="#6666FF" />
            ) : (
              <AiOutlineStar color="#DDDDDD" />
            )
          }
        />
        <Center
          w="40px"
          h="44px"
          bg="gray.100"
          fontSize="14px"
          textDecoration="underline"
        >
          {problem.num}
        </Center>
        <HStack ml={2}>
          {problem.problem.type === 1 ? (
            [1, 2, 3, 4, 5].map((selected) => (
              <AnswerButton
                key={selected}
                text={+selected}
                selected={selected === answer}
                handleClick={() => setAnswer(selected)}
              />
            ))
          ) : (
            <AnswerInput value={answer} onChange={setAnswer} omr />
          )}
        </HStack>
        <Spacer />
        <Input
          defaultValue={min}
          fontSize="12px"
          h={6}
          w={6}
          p={1}
          rounded="md"
          textAlign="center"
          placeholder="0"
          type="number"
          onChange={(e) => setMinute(+e.target.value)}
        />
        <Text fontSize="sm" mx={1}>
          분
        </Text>
        <Input
          defaultValue={sec}
          fontSize="12px"
          h={6}
          w={6}
          p={1}
          rounded="md"
          textAlign="center"
          placeholder="0"
          mx={1}
          type="number"
          onChange={(e) => setSecond(+e.target.value)}
        />
        <Text fontSize="sm">초</Text>
      </Flex>
    );
  }
);

export interface RouteParams {
  noteId: string;
}

const OmrNotePage = () => {
  const history = useHistory();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { noteId } = useParams<RouteParams>();
  const { user } = useUser();
  const { noteProblems, noteProblemsMutate } = useNoteProblems({
    id: user?.id,
    note: noteId,
  });
  const { setPrevent, PreventModal } = usePreventBack();

  const handleSubmit = async () => {
    await updateExam({ id: noteId, body: { is_submit: true } });
    setPrevent(false);
    history.goBack();
  };

  if (!noteProblems) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }
  const isEnd = noteProblems?.every((el) => el.resolve);
  const total = noteProblems.reduce((acc, cur) => acc + cur.solve_time, 0);

  return (
    <>
      <PreventModal />
      <SubmitModal isOpen={isOpen} onClose={onClose} callback={handleSubmit} />
      <Helmet
        title="문제풀이"
        keywords="문제풀이"
        description="문제풀이페이지 입니다."
      />
      <Layout title="OMR" hide={false} onBack={()=>history.push('/')}>
        <OmrHeader>
          <Text
            fontWeight="500"
            borderWidth={1}
            rounded="full"
            px={2}
            py={1}
            color="primary"
            fontSize="12px"
          >
            {convertMin(total)}
          </Text>
        </OmrHeader>
        <Container maxW="375px" h="calc(100% - 100px)" overflowY="auto">
          {noteProblems
            .sort((a, b) => a.problem.number - b.problem.number)
            .map((el) => (
              <AnswerContainer
                problem={el}
                key={el.id}
                mutate={noteProblemsMutate}
              />
            ))}
        </Container>
        <Flex
          justify="flex-end"
          w="full"
          bg="#fff"
          position="absolute"
          bottom="0"
          left="0"
          h="100px"
          borderTopWidth={1}
          pt={4}
          px={4}
        >
          <Button
            disabled={!isEnd}
            bg="primary"
            color="white"
            size="sm"
            onClick={onOpen}
          >
            제출하기
          </Button>
        </Flex>
      </Layout>
    </>
  );
};

export default OmrNotePage;

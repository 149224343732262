import React, { useRef } from 'react';
import { useParams, Redirect, useHistory } from "react-router-dom";
import {
  CircularProgress,
  Center,
  Flex,
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,Heading,
  AccordionIcon,
  Divider,IconButton,Button,Image,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import { TriangleDownIcon } from '@chakra-ui/icons'
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import useUser from "@data/useUser";
import useEnrollBooks from "@data/useEnrollBooks";
import useChapter from "@data/useChapter";
import AnswerHeader from "@layouts/AnswerHeader";
import useBook from "@data/useBook";
import moment from "moment";
import "moment/locale/ko";
import { FiXCircle, FiSearch } from "react-icons/fi";
import { AiOutlineArrowUp } from "react-icons/ai";
import ReactToPdf from "react-to-pdf";
import styled, { css } from "styled-components";
import { generateMedia } from "styled-media-query";
import MathView from "@components/MathView"
import MathJaxMarkdownRender from "@components/MathView2"
import MathView2 from '@components/MathView2';

export interface RouteParams {
  bookid: string;
  chapterid: string;
}
const URL = "https://daesung.s3.ap-northeast-2.amazonaws.com/";

const AnswerPage = () => {
  const history = useHistory();
  const ref = useRef<HTMLDivElement>(null);
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [4,2]
  };
  const [search, setSearch] = React.useState("");
  const { bookid, chapterid } = useParams<RouteParams>();
  const { chapter } = useChapter({ id: chapterid });
  const { user } = useUser();
  const { book } = useBook({ id: bookid });

  const { enrollBooks } = useEnrollBooks({ id: user?.id, test: "all" });
  const print = () =>{
    window.print();
  }
    
  if (!enrollBooks || !book || !chapter) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  if (!enrollBooks.map((el) => +el.id).includes(+bookid)) {
    return <Redirect to="/" />;
  }


  
  

  const filterList = chapter.problems.filter((el) =>
    search ? el.number === +search : true
  );
  const showToTop = filterList && filterList.length > 10;
  return (
    <>
      <Helmet
        title="해설보기"
        keywords="해설보기"
        description="해설보기페이지 입니다."
      />
      <Layout title="해설보기(A)" onBack={()=>history.push('/')}>
        <Flex p={3} pb={6} px={6} borderBottomWidth={1}>
          <Box w={"50px"} h={"50px"} bg="gray.100" mr={3}>
            <Center h="full" fontSize="xs">
              Book
            </Center>
          </Box>
          <Box>
            <Text fontWeight="500" mb={1}>
              {book.name}
            </Text>
            <Text color="gray.400" fontSize={"12px"}>
              {moment(book.created_at).format("YY.MM.DD")}
            </Text>
          </Box>
        </Flex>
        <Box flex={1} bg="gray.100" p={4}>
          <Button onClick={print} leftIcon={<TriangleDownIcon />} 
          colorScheme="teal" variant="solid">
            PDF
          </Button>
          <div ref={ref}>
            {
            filterList.map((el) => (
              <div key={el.id}>

                <Box  p="6" flex="1" textAlign="left" borderWidth="1px" borderRadius="lg" >
                <Heading as="h4" size="md">{`${el.number}번`} 문제</Heading>
                {el.id}
                {
                (el.content == null || el.content == '') ?
                  (el.img2?.url == null) ?
                    <Image src={URL + el.img} w="440px" mx={"auto"} /> 
                    : <Image src={el.img2?.url} w="440px" mx={"auto"} />
                  : <MathView2 value={el.content}></MathView2>
                }
                <Divider p="6"/>
                <Heading pt="6" as="h4" size="md">정답 : {`${el.answer}`}</Heading>
              
                <Divider  p="6"/>
                <Heading pt="6" as="h4" size="md">해설</Heading>

                {
                (el.solution == null || el.solution == '') ?  
                <Image src={el.solutionPic?.url} w="440px" mx={"auto"}/>
                :<MathView2 value={el.solution}></MathView2>
                }
                </Box>
                <div style={{pageBreakBefore:"always"}}></div>
                <br style={{height:0, lineHeight:0}}/>
              </div>
            ))}
          </div>
          {showToTop && (
            <>
              <Box h="72px" />
              <Center
                position="fixed"
                bg="white"
                shadow="md"
                rounded="full"
                w="40px"
                h="40px"
                left="calc(50% - 20px)"
                top="calc(100vh - 56px)"
                zIndex={100}
                cursor="pointer"
                _hover={{ bg: "gray.50" }}
                onClick={() =>
                  window.scroll({ top: 0, left: 0, behavior: "smooth" })
                }
              >
                <AiOutlineArrowUp />
              </Center>
            </>
          )}
        </Box>
      </Layout>
    </>
  );
};

export default AnswerPage;

import React, { useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import {
  CircularProgress,
  Center,
  Flex,
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
  InputGroup,
  Input,
  InputRightElement,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import useUser from "@data/useUser";
import useEnrollBooks from "@data/useEnrollBooks";
import useChapter from "@data/useChapter";
import AnswerHeader from "@layouts/AnswerHeader";
import useBook from "@data/useBook";
import moment from "moment";
import "moment/locale/ko";
import { FiXCircle, FiSearch } from "react-icons/fi";
import { AiOutlineArrowUp } from "react-icons/ai";
import Axios from "axios"
import { useEffect } from "react";
import { Problem } from "@type/problem";
import {
  groupBy, meanBy
} from "lodash";
import dayjs from "dayjs";

export interface RouteParams {
  bookid: string;
  chapterid: string;
}

const AnswerPage = () => {
  const [search, setSearch] = React.useState("");
  
  const [loading, setLoading] = useState(true);
  const { bookid, chapterid } = useParams<RouteParams>();
  const { chapter } = useChapter({ id: chapterid });
  const { user } = useUser();
  const { book } = useBook({ id: bookid });

  const { enrollBooks } = useEnrollBooks({ id: user?.id, test: "all" });

  const [data, setData] = React.useState([]);
  const [params, setParams] = React.useState("");
  

  const filterList = chapter?.problems.filter((el) => {
    return (search ? el.number === +search : true);
  });  

  // fetch("https://api2.potenup.kr/solve-problems?problem_in=" + bookid, {
  //       headers: {
  //         "authorization": "Bearer " + localStorage.getItem("jwt")
  //       }
  //     }).then((res) => {
  //       return res.json();
  //     }).then((json) => {
  //       console.log(json)
  //     })

  useEffect(() => {
    if(chapter != undefined) { 
      let a = `user=${user?.id}`;    
      chapter?.problems.map((problem) => {
        a += `&problem_in=${problem.id}`
      })
      
      setParams(a);
    }
  }, [chapter])

  useEffect(() => {    
    if(params == "") return;

    fetch("https://api2.potenup.kr/solve-problems?" + params, {
      headers: {
        "authorization": "Bearer " + localStorage.getItem("jwt")
      }
    }).then((res) => {
      return res.json();
    }).then((json) => {
      console.log(groupBy(json, 'problem.id') as any)
      setData(groupBy(json, 'problem.id') as any)
    })
  }, [params]);

  if (!enrollBooks || !book || !chapter) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  if (!enrollBooks.map((el) => +el.id).includes(+bookid)) {
    return <Redirect to="/" />;
  }

  
  const showToTop = filterList && filterList.length > 10;  


  return (
    <>
      <Helmet
        title="해설보기"
        keywords="해설보기"
        description="해설보기페이지 입니다."
      />
      <Layout title="해설보기">
        {/* <AnswerHeader onOpen={onOpen} /> */}
        <Flex p={3} pb={6} px={6} borderBottomWidth={1}>
          <Box w={"50px"} h={"50px"} bg="gray.100" mr={3}>
            <Center h="full" fontSize="xs">
              Book
            </Center>
          </Box>
          <Box>
            <Text fontWeight="500" mb={1}>
              {book.name}
            </Text>
            <Text color="gray.400" fontSize={"12px"}>
              {moment(book.created_at).format("YY.MM.DD")}
            </Text>
          </Box>
        </Flex>
        <Box flex={1} bg="gray.100" p={4}>
          <Box px={10} pb={4}>
            <InputGroup size="md">
              <Input
                value={search}
                placeholder="문항번호를 조회하세요."
                bg="white"
                rounded="full"
                onChange={(e) => setSearch(e.target.value)}
                _focus={{ borderColor: "primary" }}
              />
              <InputRightElement width="4.5rem">
                <FiXCircle
                  onClick={() => setSearch("")}
                  color={search ? "#6666FF" : "#fff"}
                />
                <Box mx={1} />
                <FiSearch size="20px" color="#6666FF" />
              </InputRightElement>
            </InputGroup>
          </Box>
          <Accordion allowMultiple>
            {console.log(filterList.length)}
            {data.length != 0 && filterList.map((el, key) => {
              const item = data[Number(el.id)][0] as any;
              const created_time = item.problem.created_at;
              const answer = item.answer;
              const timeover = item.timeover;
              const answerTime = item.problem.answerTimes[key];

              const formatSec = (t: number) => {
                return (
                  Math.floor(t / 60) > 9 ?
                    Math.floor(t / 60) :
                    "0" + Math.floor(t / 60) + ":" + (t % 60 > 9 ? t % 60 : "0" + t % 60)
                )
              }
              console.log(data[Number(el.id)])
              return (<AccordionItem
                key={el.id}
                bg="white"
                borderWidth={1}
                mb={2}
                rounded="md"
              >
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {`${el.number}번`}
                    </Box>
                    <Box flex="1" justifyContent={"flex-end"} d={"flex"} >
                      <Box width={"22px"} backgroundColor={answer ? "#5544D4" : "#EDEDED"} color={answer ? "white" : "#B0B0B0"} borderRadius={4} marginLeft={"4px"} fontWeight={"500"}>O</Box> { /* 비활성화 if else 로 하면 될 거 같음 */ }
                      <Box width={"22px"} backgroundColor={timeover ? "#5544D4" : "#EDEDED"} color={timeover ? "white" : "#B0B0B0"} borderRadius={4} marginLeft={"4px"} fontWeight={"500"}>T</Box>
                      <Box width={"22px"} backgroundColor={answer ? "#EDEDED" : "#5544D4"} color={answer ? "#B0B0B0" : "white"} borderRadius={4} marginLeft={"4px"} fontWeight={"500"}>X</Box>
                    </Box>
                    <AccordionIcon marginLeft={"12px"}/>
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  <Divider />
                  <Box pt={4}>
                    <Table size="sm">
                      <Thead>
                        <Tr>
                          <Th>학습일</Th>
                          <Th>회차</Th>
                          <Th>채점</Th>
                          <Th>나의시간</Th>
                          <Th>평균시간</Th>
                        </Tr>
                      </Thead>
                      <Tbody color={"#B0B0B0"}>
                        <Tr>
                          <Td>{dayjs(created_time).format("YY.MM.DD")}</Td>
                          <Td>2회차</Td>
                          <Td color={answer ? "#5544D4" : ""}>{answer ? "정답" : "오답"}</Td>
                          <Td>{formatSec(answerTime)}</Td>
                          <Td>{formatSec(Math.floor(meanBy(item.problem.answerTimes, (p) => p)))}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                </AccordionPanel>
              </AccordionItem> )
            })}
          </Accordion>
          {showToTop && (
            <>
              <Box h="72px" />
              <Center
                position="fixed"
                bg="white"
                shadow="md"
                rounded="full"
                w="40px"
                h="40px"
                left="calc(50% - 20px)"
                top="calc(100vh - 56px)"
                zIndex={100}
                cursor="pointer"
                _hover={{ bg: "gray.50" }}
                onClick={() =>
                  window.scroll({ top: 0, left: 0, behavior: "smooth" })
                }
              >
                <AiOutlineArrowUp />
              </Center>
            </>
          )}
        </Box>
      </Layout>
    </>
  );
};

// const groupBy = function (data: any, key: any) {
//   return data.reduce(function (carry: any, el: any) {
//     var group = el[key];

//     if (carry[group] === undefined) {
//         carry[group] = []
//     }

//     carry[group].push(el)
//     return carry
//   }, {})
// }

export default AnswerPage;

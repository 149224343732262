import React from "react";

const useTimer = () => {
  const [min, setMin] = React.useState(0);
  const [sec, setSec] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [pause, setPause] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (pause) return;
      if (sec === 59) {
        setSec(0);
        setMin((val) => val + 1);
      } else {
        setSec((val) => val + 1);
      }
      setTotal((val) => val + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [pause, total, sec]);

  const onFocus = () => {
    setPause(false);
  };

  const onBlur = () => {
    setPause(true);
  };

  React.useEffect(() => {
    // window.addEventListener("focus", onFocus);
    // window.addEventListener("blur", onBlur);
    return () => {
      // window.removeEventListener("focus", onFocus);
      // window.removeEventListener("blur", onBlur);
    };
  }, []);

  const convertSec = sec < 10 ? `0${sec}` : sec;

  const handleReset = React.useCallback(() => {
    setMin(0);
    setSec(0);
    setTotal(0);
  }, []);

  return {
    total,
    min,
    sec: convertSec,
    handleReset,
  };
};

export default useTimer;

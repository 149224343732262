import * as React from "react";
import {
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { HiEye, HiEyeOff } from "react-icons/hi";

const PasswordField = React.forwardRef<
  HTMLInputElement,
  { field: any; form: any; passType?: string } & InputProps
>(({ passType, field, form, ...props }, ref) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const mergeRef = useMergeRefs(inputRef, ref);

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  const isValid =
    passType === "password"
      ? form.errors.password && form.touched.password
      : form.errors.passwordConfirm && form.touched.passwordConfirm;

  const error =
    passType === "password"
      ? form.errors.password
      : form.errors.passwordConfirm;

  return (
    <FormControl isInvalid={isValid} h="110px">
      <FormLabel color={"gray.400"} fontSize="14px">
        비밀번호
      </FormLabel>
      <InputGroup>
        <InputRightElement>
          <IconButton
            bg="transparent !important"
            variant="ghost"
            aria-label={isOpen ? "Mask password" : "Reveal password"}
            icon={isOpen ? <HiEyeOff /> : <HiEye />}
            onClick={onClickReveal}
            color="gray.300"
          />
        </InputRightElement>
        <Input
          ref={mergeRef}
          name={passType}
          type={isOpen ? "text" : "password"}
          autoComplete="current-password"
          required
          {...props}
          {...field}
        />
      </InputGroup>
      <FormErrorMessage justifyContent="flex-end" mt={1}>
        {error}
      </FormErrorMessage>
    </FormControl>
  );
});

export default PasswordField;

import React from "react";
import { useHistory, RouteComponentProps } from "react-router-dom";
import {
  Box,
  Button,
  Heading,
  Text,
  Alert,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Helmet from "@layouts/Helmet";
import AuthContainer from "@components/AuthContainer";
import PasswordField from "@components/PasswordField";
import { resetPassword } from "@services/auth";

interface MatchParams {
  search: string;
}

type Prop = RouteComponentProps<MatchParams>;

const ResetPage: React.FC<Prop> = ({ location }) => {
  const history = useHistory();
  const toast = useToast();

  return (
    <>
      <Helmet
        title="비밀번호 재설정"
        keywords="비밀번호 재설정"
        description="비밀번호 재설정페이지 입니다."
      />
      <AuthContainer>
        <Box textAlign="center" py={4} color={"gray.800"}>
          <Heading fontSize={"20px"}>비밀번호 재설정</Heading>
          <Text mt={2} fontSize="14px" color="gray.400" px={"50px"}>
            새로운 비밀번호를 입력하세요.
          </Text>
        </Box>
        <Formik
          initialValues={{ password: "", passwordConfirm: "" }}
          validationSchema={Yup.object({
            password: Yup.string().required("비밀번호를 입력하세요."),
            passwordConfirm: Yup.string()
              .oneOf(
                [Yup.ref("password"), null],
                "패스워드가 일치하지 않습니다."
              )
              .required("비밀번호를 입력하세요"),
          })}
          onSubmit={async (values, { setStatus, setSubmitting }) => {
            setSubmitting(true);
            setStatus("");
            try {
              const query = new URLSearchParams(location.search);
              const code = query.get("code");
              if (!code) throw new Error("error");
              const { password } = values;
              await resetPassword({ code, password });
              localStorage.setItem("reset", "done");
              history.push("/login");
            } catch (err) {
              toast({
                title: "오류",
                position: "top",
                description: "에러가 있습니다.",
                status: "error",
                duration: 4000,
                isClosable: true,
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isValid, dirty, isSubmitting, status }) => (
            <Form>
              {status && (
                <Alert status="error" borderRadius={4}>
                  <AlertIcon />
                  {status}
                </Alert>
              )}
              <Field name="password">
                {({ field, form }: any) => (
                  <PasswordField
                    placeholder="패스워드"
                    field={field}
                    form={form}
                    passType="password"
                  />
                )}
              </Field>
              <Field name="passwordConfirm">
                {({ field, form }: any) => (
                  <PasswordField
                    placeholder="패스워드 확인"
                    field={field}
                    form={form}
                    passType="passwordConfirm"
                  />
                )}
              </Field>
              <Button
                px={8}
                bg={isValid && dirty ? "primary" : "gray.300"}
                color={"white"}
                isLoading={isSubmitting}
                type="submit"
                isFullWidth
                h={"44px"}
                borderRadius={4}
                disabled={!(isValid && dirty) || isSubmitting}
              >
                비밀번호 재설정 완료
              </Button>
            </Form>
          )}
        </Formik>
      </AuthContainer>
    </>
  );
};

export default ResetPage;

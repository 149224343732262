import React from "react";
import { Input } from "@chakra-ui/react";

interface Prop {
  value: number | null;
  onChange: (value: number) => void;
  omr?: boolean;
  disabled?: boolean;
}

const AnswerInput = React.forwardRef<HTMLInputElement, Prop>(
  ({ value, onChange, omr, disabled = false }, ref) => {
    return (
      <Input
        disabled={disabled}
        ref={ref}
        w={omr ? "132px" : "164px"}
        h={omr ? "26px" : "38px"}
        borderWidth={1}
        fontSize="14px"
        rounded="sm"
        cursor="pointer"
        px={2}
        placeholder="답을 입력해주세요."
        defaultValue={value?.toString() || ""}
        onBlur={(e) => onChange(+e.target.value)}
        type="number"
      />
    );
  }
);

export default AnswerInput;

import React from "react";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalContent,
  ModalBody,
  useDisclosure,
  Button,
  Box,
  Text,
  HStack,
} from "@chakra-ui/react";
import { addExam } from "@services/noteExam";
import useUser from "@data/useUser";
import useNoteExams from "@data/useNoteExams";
import { getProblems, addProblem } from "@services/noteProblem";
import { addCalendar } from "@services/calendar";
import { Note } from "@type/note";
import { NoteExam } from "@type/noteExam";
import { CalendarPost } from "@type/calendar";
import { NoteProblem } from "../types/noteProblem";

type RouteType = "chapter" | "omr" | "answer";
type ExamType = "new" | "never" | "continue" | "again";

export const useNoteModal = () => {
  const history = useHistory();
  const { user } = useUser();
  const [title, setTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [type, setType] = React.useState<ExamType>("new");
  const [route, setRoute] = React.useState<RouteType>("chapter");
  const [note, setNote] = React.useState<Note | null>(null);
  const [noteExam, setNoteExam] = React.useState<NoteExam | null>(null);
  const { noteExams, noteExamsMutate } = useNoteExams({ userId: user?.id });

  const handleSubmit = React.useCallback(async () => {
    if (!note) return;
    const ids = note.problems?.map((el) => `problem_in=${el.id}`).join("&");
    const state = await getProblems({ id: user.id, ids });
    let currentExam = noteExam;
    if (type === "new") {
      const noteProblems = await Promise.all(
        note.problems.map(async (problem) => {
          const noteP = state?.find((el) => el.problem.id === problem?.id);
          const body = {
            count: noteP?.count ? noteP.count + 1 : 1,
            num: problem.number,
            problem: problem.id,
            user: user?.id,
          } as { count: number; num: number; problem: string; user: string };
          const { data }: { data: NoteProblem } = await addProblem(body);
          return data.id;
        })
      );
      const body = {
        round: 1,
        user: user.id,
        note: note.id,
        note_problems: noteProblems,
      };
      const { data } = await addExam(body);
      currentExam = data;
      const calendar = {
        user: user.id,
        title: "노트 학습",
        type: "note_learn",
        desc: note?.name,
      } as CalendarPost;
      await addCalendar(calendar);
    } else if (type === "again") {
      const noteProblems = await Promise.all(
        note.problems.map(async (problem) => {
          const noteP = state?.find((el) => el.problem.id === problem?.id);
          const body = {
            count: noteP?.count ? noteP.count + 1 : 1,
            num: problem.number,
            problem: problem.id,
            user: user?.id,
          } as { count: number; num: number; problem: string; user: string };
          const { data }: { data: NoteProblem } = await addProblem(body);
          return data.id;
        })
      );
      const current = noteExams
        .filter((el) => el.id === note?.id)
        .map((el) => el.round)
        .sort((a, b) => a - b)
        .pop();
      const body = {
        round: current ? current + 1 : 2,
        user: user.id,
        note: note.id,
        note_problems: noteProblems,
      };
      const { data } = await addExam(body);
      currentExam = data;
      const calendar = {
        user: user.id,
        title: "노트 학습",
        type: "note_learn",
        desc: note?.name,
      } as CalendarPost;
      await addCalendar(calendar);
    }
    await noteExamsMutate();
    if (route === "chapter") {
      history.push(`/note/${currentExam?.id}/preview`);
    } else if (route === "omr") {
      history.push(`/note/${currentExam?.id}/omr`);
    }
  }, [note, noteExam, noteExams, type, user, history, noteExamsMutate, route]);

  const TestModal = React.memo(() => (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalContent w="320px" shadow="2xl">
          <ModalBody p={8}>
            <Box textAlign="center">
              <Box py={8}>
                <Text fontSize="16px" mb={3} whiteSpace="pre-line">
                  {title}
                </Text>
                <Text color="gray.400" fontSize="14px" mb={4}>
                  {subTitle}
                </Text>
              </Box>
              <HStack w="full">
                <Button
                  isFullWidth
                  bg="#6666FF34"
                  color="primary"
                  onClick={() => onClose()}
                  h="44px"
                >
                  아니오
                </Button>
                <Button
                  isFullWidth
                  bg="primary"
                  color="white"
                  onClick={handleSubmit}
                  h="44px"
                >
                  네
                </Button>
              </HStack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  ));
  return {
    setNoteExam,
    setNote,
    setType,
    setTitle,
    setSubTitle,
    setRoute,
    TestModal,
    onOpen,
  };
};

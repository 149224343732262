import React from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Box,
  Input,
  Button,
  Flex,
  Divider,
  Checkbox,
  useToast,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Container,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import useUser from "@data/useUser";
import useQuestions from "@data/useQuestions";
import { addQuestion } from "@services/question";
import { QuestionPost } from "@type/question";
import PostAddModal from "@components/modal/PostAddModal";
import usePreventEditBack from "@hooks/usePreventEditBack";
import { ChevronDownIcon } from "@chakra-ui/icons";
import QuillEditor from "@components/QuillEditor";
import useProblem from "@data/useProblem";

const URL = "https://daesung.s3.ap-northeast-2.amazonaws.com/";

export interface RouteParams {
  problemId: string;
}

const QuestionAddPage = () => {
  const { problemId } = useParams<RouteParams>();
  const { problem } = useProblem({ id: problemId });
  const postAdd = useDisclosure();
  const { setFinishStatus, PreventModal } = usePreventEditBack();
  const toast = useToast();
  const { user } = useUser();
  const { questionsMutate } = useQuestions();
  const history = useHistory();
  const [title, setTitle] = React.useState("");
  const [showGrade, setShowGrade] = React.useState(false);
  const [hide, setHide] = React.useState(false);
  const [desc, setDesc] = React.useState("");
  const [questionType, setQuestionType] = React.useState("book");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!title || !desc) throw new Error("error");
      postAdd.onOpen();
    } catch (err) {
      toast({
        title: "포텐업",
        description: "내용을 입력해주세요.",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      console.log(err);
    }
  };

  const handleAdd = async () => {
    try {
      const data = {
        title,
        desc,
        hide_grade: !showGrade,
        writer: user?.id,
        hide,
        type: questionType,
        problem: problemId,
      } as QuestionPost;
      await addQuestion(data);
      await questionsMutate();
      setFinishStatus(true);
      history.goBack();
    } catch (err) {
      toast({
        title: "포텐업",
        description: "내용을 입력해주세요.",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      console.log(err);
    }
  };

  return (
    <>
      <PostAddModal
        isOpen={postAdd.isOpen}
        onClose={postAdd.onClose}
        callback={handleAdd}
      />
      <PreventModal />
      <Helmet
        title="프로그램문의"
        keywords="프로그램문의"
        description="프로그램문의페이지 입니다."
      />
      <Layout title="질문하기" noAction onBack={()=>history.push('/')}>
        {problem && (
          <Container maxW="md" mb={4} py={4}>
            <Image src={URL + problem.img} />
          </Container>
        )}
        <Flex px={4}>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  isActive={isOpen}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  size="sm"
                  bg="#fff"
                  borderWidth={1}
                  rounded="none"
                  color="gray.400"
                  fontSize="14px"
                >
                  {questionType === "book" && "교재"}
                  {questionType === "homework" && "과제"}
                  {questionType === "test" && "모의고사"}
                </MenuButton>
                <MenuList minW="auto" w="84px" rounded="none">
                  {[
                    { value: "book", label: "교재" },
                    { value: "homework", label: "과제" },
                    { value: "test", label: "모의고사" },
                  ].map((el) => (
                    <MenuItem
                      fontSize="14px"
                      color="gray.400"
                      key={el.value}
                      onClick={() => setQuestionType(el.value)}
                    >
                      {el.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </>
            )}
          </Menu>
        </Flex>
        <form onSubmit={handleSubmit}>
          <Box p={4} pt={1}>
            <Input
              fontSize="20px"
              placeholder="제목"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              border="none"
            />
            <Divider mb={"4px"} />
            <QuillEditor onChange={(value) => setDesc(value)} />
            <Flex
              justify="space-between"
              w="full"
              bg="gray.100"
              position="absolute"
              bottom="0"
              left="0"
              h="80px"
              borderTopWidth={1}
              pt={4}
              px={4}
              zIndex={10}
            >
              <Flex>
                <Checkbox
                  defaultChecked={showGrade}
                  checked={showGrade}
                  onChange={(e) => setShowGrade(e.target.checked)}
                  h="20px"
                  mr={3}
                >
                  등급 공개
                </Checkbox>
                <Checkbox
                  defaultChecked={hide}
                  checked={hide}
                  onChange={(e) => setHide(e.target.checked)}
                  h="20px"
                >
                  질문 비공개
                </Checkbox>
              </Flex>
              <Button type="submit" bg="primary" color="white" size="sm">
                등록하기
              </Button>
            </Flex>
          </Box>
        </form>
      </Layout>
    </>
  );
};

export default QuestionAddPage;

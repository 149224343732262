import { Center } from "@chakra-ui/react";

interface Prop {
  handleClick: () => void;
}

const NavButton: React.FC<Prop> = ({ children, handleClick }) => {
  return (
    <Center
      color="gray.400"
      w="44px"
      h="44px"
      fontSize="14px"
      borderWidth={1}
      rounded="sm"
      cursor="pointer"
      onClick={handleClick}
    >
      {children}
    </Center>
  );
};

export default NavButton;

import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Flex, Box, Divider, Text, Container, VStack } from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import HomeTab from "@layouts/HomeTab";
import HomeHeader from "@layouts/HomeHeader";
import useUser from "@data/useUser";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction"; // needed for dayClick
import { AiOutlineNotification } from "react-icons/ai";
import CalendarCard from "@components/CalendarCard";
import moment from "moment";
import { getCalendars } from "@services/calendar";
import { Calandar } from "@type/calendar";
import useNotices from "@data/useNotices";

const MyCaledar = React.memo(({ setCalendars, handleDate }: any) => {
  const { user } = useUser();

  const bgColor = (type: string) => {
    if (type === "note_create") return "primary";
    if (type === "note_learn") return "#EB7AA7";
    if (type === "exam_create") return "#8DD57B";
    if (type === "exam_learn") return "#2CB49E";
    if (type === "book_create") return "#58CBFC";
    if (type === "book_learn") return "#3B9AF5";
  };
  return (
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      events={(fetchInfo, successCallback) => {
        getCalendars(
          user?.id as string,
          moment(fetchInfo.start).format("YYYY-MM-DD"),
          moment(fetchInfo.end).format("YYYY-MM-DD")
        ).then(({ data }) => {
          setCalendars(data);
          successCallback(
            data.map((el: any) => {
              return {
                title: el.title,
                date: el.created_at,
                color: bgColor(el.type),
              };
            })
          );
        });
      }}
      locale="ko"
      dateClick={handleDate}
      eventDisplay="list-item"
      headerToolbar={{
        start: "today",
        center: "prev,title,next",
        end: "",
      }}
      buttonText={{
        today: "오늘",
      }}
      selectable={true}
    />
  );
});

const HomePage = () => {
  const history = useHistory();

  const { notices } = useNotices();
  const [date, setDate] = React.useState(new Date());
  const [calendars, setCalendars] = React.useState<Calandar[]>([]);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 4);

  const handleDate = React.useCallback((arg: DateClickArg) => {
    setDate(arg.date);
  }, []);

  const selectedCalendars = calendars?.filter(
    (el) => moment(el.created_at).format("LL") === moment(date).format("LL")
  );

  return (
    <>
      <Helmet
        title="홈페이지"
        keywords="홈페이지"
        description="홈페이지페이지 입니다."
      />
      <Layout title="학습달력" noArrow >
        <HomeHeader />
        <HomeTab />
        <Divider mb={2}></Divider>
        <Flex px={4} pt={2} pb={3} align="center">
          <Box borderWidth={1} p={1} rounded="full">
            <AiOutlineNotification color="#B0B0B0" />
          </Box>
          <Text
            ml={2}
            fontSize="12px"
            color="gray.900"
            fontWeight="500"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {notices?.[0]?.title}
          </Text>
        </Flex>
        <Box>
          <MyCaledar setCalendars={setCalendars} handleDate={handleDate} />
        </Box>
        <Box flex={1} bg="gray.100" py={4}>
          <Container maxW="md">
            <Text>{moment(date).format("DD.ddd")}</Text>
            <VStack w="full" mt={2}>
              {selectedCalendars?.map((el) => (
                <CalendarCard key={el.id} item={el} />
              ))}
            </VStack>
          </Container>
        </Box>
      </Layout>
    </>
  );
};

export default HomePage;

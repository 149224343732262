import React from "react";
import { useSWRInfinite } from "swr";
import { Question } from "@type/question";
import { useDebounce } from "@react-hook/debounce";

const SIZE = 10;

const useQuestions = () => {
  const [search, setSearch] = useDebounce("", 200);
  const [filter, setFilter] = React.useState("all");
  const url = "questions?_sort=id:DESC";
  const { data, error, mutate, size, setSize } = useSWRInfinite((idx) => {
    let query = "";
    if (search) {
      query += "&_where[_or][0][title_contains]=" + search;
      query += "&_where[_or][1][desc_contains]=" + search;
      query += "&_where[_or][2][writer.nick_name_contains]=" + search;
    }
    if (filter !== "all") query += `&type=${filter}`;

    return `${url}&_start=${idx * SIZE}&_limit=${SIZE}${query}`;
  });

  const issues = data ? [].concat(...data) : [];
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < SIZE);

  return {
    filter,
    setFilter,
    search,
    setSearch,
    size,
    setSize,
    questions: issues,
    isLoading: !error && !data,
    isError: error,
    isReachingEnd,
    questionsMutate: mutate,
  } as {
    filter: string;
    setFilter: any;
    search: string;
    setSearch: any;
    size: number;
    setSize: any;
    questions: Question[];
    isLoading: boolean;
    isError: any;
    isReachingEnd: boolean;
    questionsMutate: any;
  };
};

export default useQuestions;

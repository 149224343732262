import React from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import {
  CircularProgress,
  Center,
  Flex,
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
  Button,
  Container,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import useProblem from "@data/useProblem";

const URL = "https://daesung.s3.ap-northeast-2.amazonaws.com/";

export interface RouteParams {
  problemId: string;
}

const ProblemPage = () => {
  const history = useHistory();
  const { problemId } = useParams<RouteParams>();
  const { problem } = useProblem({ id: problemId });

  if (!problem) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  return (
    <>
      <Helmet
        title="문제보기"
        keywords="문제보기"
        description="문제보기페이지 입니다."
      />
      <Layout title="문제보기" onBack={()=>history.push('/')}>
        <Container maxW="md" mb={4} py={4}>
          <Image src={URL + problem.img} />
        </Container>
        <Box flex={1} bg="gray.100" p={4}>
          <Container maxW="340px">
            <Accordion allowToggle>
              <AccordionItem borderWidth={0}>
                <AccordionButton p={0} borderWidth={0}>
                  <Flex
                    w="full"
                    h="56px"
                    bg="white"
                    align="center"
                    px={4}
                    justify="space-between"
                    rounded="sm"
                  >
                    <Text>정답 및 해설보기</Text>
                    <AccordionIcon borderWidth={1} rounded="full" />
                  </Flex>
                </AccordionButton>
                <AccordionPanel pb={4} bg="white" fontSize="sm">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Link to={`/question-add/${problem.id}`}>
              <Button
                bg="primary"
                color="white"
                isFullWidth
                mt={3}
                rounded="sm"
                h="44px"
              >
                프로그램문의 하기
              </Button>
            </Link>
          </Container>
        </Box>
      </Layout>
    </>
  );
};

export default ProblemPage;

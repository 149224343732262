import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Input,
  Button,
  Flex,
  Divider,
  Checkbox,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import useUser from "@data/useUser";
import usePosts from "@data/usePosts";
import { addPost } from "@services/post";
import { PostPost } from "@type/post";
import PostAddModal from "@components/modal/PostAddModal";
import QuillEditor from "@components/QuillEditor";
import usePreventEditBack from "@hooks/usePreventEditBack";

const PostAddPage = () => {
  const postAdd = useDisclosure();
  const { setFinishStatus, PreventModal } = usePreventEditBack();
  const toast = useToast();
  const { user } = useUser();
  const { postsMutate } = usePosts();
  const history = useHistory();

  const [title, setTitle] = React.useState("");
  const [showGrade, setShowGrade] = React.useState(true);
  const [desc, setDesc] = React.useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!title || !desc) throw new Error("error");
      postAdd.onOpen();
    } catch (err) {
      toast({
        title: "포텐업",
        description: "내용을 입력해주세요.",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      console.log(err);
    }
  };

  const handleAdd = async () => {
    try {
      const data = {
        title,
        desc,
        hide_grade: !showGrade,
        writer: user?.id,
      } as PostPost;
      await addPost(data);
      await postsMutate();
      setFinishStatus(true);
      history.goBack();
    } catch (err) {
      toast({
        title: "포텐업",
        description: "내용을 입력해주세요.",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      console.log(err);
    }
  };

  return (
    <>
      <PostAddModal
        isOpen={postAdd.isOpen}
        onClose={postAdd.onClose}
        callback={handleAdd}
      />
      <PreventModal />
      <Helmet
        title="자유게시판"
        keywords="자유게시판"
        description="자유게시판페이지 입니다."
      />
      <Layout title="글쓰기" noAction onBack={()=>history.push('/')}>
        <form onSubmit={handleSubmit}>
          <Box p={4}>
            <Input
              fontSize="20px"
              placeholder="제목"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              border="none"
            />
            <Divider mb={"4px"} />
            <QuillEditor onChange={(value) => setDesc(value)} />
            <Flex
              justify="space-between"
              w="full"
              bg="gray.100"
              position="absolute"
              bottom="0"
              left="0"
              h="100px"
              borderTopWidth={1}
              pt={4}
              px={4}
              zIndex={10}
            >
              <Checkbox
                defaultChecked={showGrade}
                checked={showGrade}
                onChange={(e) => setShowGrade(e.target.checked)}
                h="20px"
              >
                등급 공개하기
              </Checkbox>
              <Button type="submit" bg="primary" color="white" size="sm">
                등록하기
              </Button>
            </Flex>
          </Box>
        </form>
      </Layout>
    </>
  );
};

export default PostAddPage;

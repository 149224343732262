import React from "react";
import { Container, Box, useDisclosure } from "@chakra-ui/react";
import MobileDrawer from "./MobileDrawer";
import Header from "./Header";
import { useHistory } from "react-router";

interface Prop {
  title: string;
  noArrow?: boolean;
  hide?: boolean;
  noAction?: boolean;
  noNotiy?: boolean;
  onBack?: () => void;
}

const Layout: React.FC<Prop> = ({
  title,
  noArrow = false,
  hide = true,
  noAction,
  noNotiy,
  children,
  onBack
}) => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Container maxW={{ base: "100vw", md: "768px" }} shadow="sm" p={0}>
      <Box d="flex" flexDirection="column" h={"100vh"} position="relative">
        {hide && <MobileDrawer isOpen={isOpen} onClose={onClose} />}
        {hide && (
          <Header
            noArrow={noArrow}
            title={title}
            onOpen={onOpen}
            onBack={onBack}
            noAction={noAction}
            noNotiy={noNotiy}
          />
        )}
        {children}
      </Box>
    </Container>
  );
};

export default Layout;

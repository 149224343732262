import React from "react";
import { useHistory } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import CloseEditModal from "@components/modal/CloseEditModal";

const usePreventEditBack = () => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [finishStatus, setFinishStatus] = React.useState(false);

  const onBackButtonEvent = React.useCallback(
    (e: any) => {
      e.preventDefault();
      if (!finishStatus) {
        onOpen();
      } else {
        history.goBack();
      }
    },
    [finishStatus, history, onOpen]
  );

  React.useEffect(() => {
    window.history.pushState(null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [finishStatus, onBackButtonEvent]);

  const PreventModal = React.memo(() => (
    <CloseEditModal
      isOpen={isOpen}
      onClose={onClose}
      cancel={() => {
        window.history.pushState(null, window.location.pathname);
        setFinishStatus(false);
      }}
      callback={() => {
        setFinishStatus(true);
        history.goBack();
      }}
    />
  ));
  return { setFinishStatus, PreventModal };
};

export default usePreventEditBack;

import useSWR from "swr";
import { Exam } from "@type/exam";

const useExam = ({ id }: { id: string | undefined }) => {
  const { data, error, mutate } = useSWR(id ? `exams/${id}` : null);
  return {
    isLoading: !error && !data,
    isError: error,
    exam: data,
    examMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    exam: Exam;
    examMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useExam;

import axios from "axios";
import { AnswerPost, AnswerPut } from "../types/answer";

export const addAnswer = (body: AnswerPost) => {
  return axios.post(`answers`, body);
};

export const updateAnswer = ({ id, body }: { id: string; body: AnswerPut }) => {
  return axios.put(`answers/${id}`, body);
};

export const selectAnswer = (id: string) => {
  return axios.put(`answers/${id}`, { selected: true });
};

export const deleteAnswer = (id: string) => {
  return axios.delete(`answers/${id}`);
};

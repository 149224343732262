import React from "react";
import { useHistory } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import CloseModal from "@components/modal/CloseModal";

const usePreventBack = () => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [finishStatus, setfinishStatus] = React.useState(false);
  const [prevent, setPrevent] = React.useState(true);
  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    if (!finishStatus) {
      onOpen();
    }
  };

  React.useEffect(() => {
    if (prevent) {
      window.history.pushState(null, window.location.pathname);
      window.addEventListener("popstate", onBackButtonEvent);
    } else {
      // window.history.back(); 
      // 빼본다. 
    }
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [prevent]);

  const PreventModal = React.memo(() => (
    <CloseModal
      isOpen={isOpen}
      onClose={onClose}
      cancel={() => {
        window.history.pushState(null, window.location.pathname);
        setfinishStatus(false);
      }}
      callback={() => {
        setfinishStatus(true);
        history.goBack();
      }}
    />
  ));
  return { setPrevent, PreventModal };
};

export default usePreventBack;

import {
  Box,
  Button,
  Text,
  Modal,
  ModalContent,
  ModalBody,
  VStack,
} from "@chakra-ui/react";

const AlertModal = ({
  isOpen,
  onClose,
  title,
  subTitle,
}: {
  title: string;
  subTitle: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalContent w="320px" shadow="2xl">
        <ModalBody p={8}>
          <Box textAlign="center">
            <Box py={8}>
              <Text fontSize="16px" mb={3}>
                {title}
              </Text>
              <Text color="gray.400" fontSize="14px" mb={4}>
                {subTitle}
              </Text>
            </Box>
            <VStack w="full">
              <Button
                isFullWidth
                bg="primary"
                color="white"
                onClick={() => onClose()}
                h="44px"
              >
                확인
              </Button>
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AlertModal;

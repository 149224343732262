import useSWR from "swr";
import { Comment } from "@type/comment";

const useComments = (id: string) => {
  const { data, error, mutate } = useSWR(`comments?post=${id}`);
  return {
    comments: data,
    isLoading: !error && !data,
    isError: error,
    commentsMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    comments: Comment[];
    commentsMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useComments;

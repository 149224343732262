import useSWR from "swr";
import { Problem } from "@type/problem";

const useProblem = ({ id }: { id: string | undefined }) => {
  const { data, error, mutate } = useSWR(id ? `problems/${id}` : null);
  return {
    isLoading: !error && !data,
    isError: error,
    problem: data,
    problemMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    problem: Problem;
    problemMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useProblem;

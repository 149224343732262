import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  Box,
  Center,
  Divider,
  Heading,
  Text,
  CircularProgress,
  Flex,
  Button,
  Textarea,
  Avatar as Face,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import useUser from "@data/useUser";
import usePost from "@data/usePost";
import useComments from "@data/useComments";
import { deleteComment, addComment } from "@services/comment";
import { updateLikePost, deletePost } from "@services/post";
import { useCommentModal } from "@hooks/useCommentModal";
import { CommentPost } from "@type/comment";
import Avatar from "@components/Avatar";
import moment from "moment";
import { FiThumbsUp } from "react-icons/fi";
import PostDeleteModal from "@components/modal/PostDeleteModal";

export interface RouteParams {
  postId: string;
}

const PostPage = () => {
  const history = useHistory();
  const toast = useToast();
  const postDelete = useDisclosure();
  const { postId } = useParams<RouteParams>();
  const { user } = useUser();
  const { post, postMutate } = usePost({ id: postId });
  const { comments, commentsMutate } = useComments(postId);
  const [comment, setComment] = React.useState("");
  const { handleEdit, EditModal } = useCommentModal();

  if (!post || !comments)
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );

  const handleAddComment = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!comment)
      return toast({
        title: "포텐업",
        description: "내용을 입력해주세요.",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    const data = {
      post: postId,
      title: comment,
      writer: user?.id,
    } as CommentPost;
    await addComment(data);
    setComment("");
    commentsMutate();
  };

  const handleDeleteComment = async (id: string) => {
    await deleteComment(id);
    commentsMutate();
  };

  const handleLike = async () => {
    await updateLikePost({ id: postId, body: { user: user?.id } });
    postMutate();
  };

  const handleDelete = async () => {
    await deletePost(postId);
    postMutate();
    history.goBack();
  };

  const isOwner = post.writer.id === user.id;
  const isLike = post.users.some((el) => el.id === user.id);

  return (
    <>
      <PostDeleteModal
        isOpen={postDelete.isOpen}
        onClose={postDelete.onClose}
        callback={handleDelete}
      />
      <EditModal />
      <Helmet
        title={`자유게시판 - ${post.title}`}
        keywords="자유게시판"
        description="자유게시판페이지 입니다."
      />
      <Layout title="자유게시판" onBack={()=>history.push('/')}>
        <Box pb={2}>
          <Box px={4}>
            <Heading size="lg" fontWeight="500" fontSize="20px" mt={5} mb={3}>
              {post.title}
            </Heading>
            <Flex align="center">
              {post.hide_grade ? (
                <Face src={post.writer.avatar} />
              ) : (
                <Avatar user={post.writer} />
              )}
              <Box ml={3} fontSize="12px" color="gray.400">
                <Text>{post.writer.nick_name}</Text>
                <Text>{moment(post.created_at).format("YY.MM.DD")}</Text>
                {isOwner && (
                  <Flex align="center">
                    <Link to={`/posts/${post.id}/edit`}>
                      <Button variant="ghost" size="xs" px={0} fontSize="12px">
                        수정
                      </Button>
                    </Link>
                    <Divider
                      orientation="vertical"
                      mx={1}
                      h="10px"
                      w={"2px"}
                      bg="gray.300"
                    />
                    <Button
                      variant="ghost"
                      size="xs"
                      px={0}
                      fontSize="12px"
                      onClick={postDelete.onOpen}
                    >
                      삭제
                    </Button>
                  </Flex>
                )}
              </Box>
            </Flex>
          </Box>
          <Divider my={2}></Divider>
          <Box py={2} px={4}>
            <div
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{ __html: post.desc }}
            ></div>
          </Box>
          <Flex justify="flex-end">
            <Button
              w="68px"
              h="38px"
              onClick={handleLike}
              variant="outline"
              rounded="full"
              color={isLike ? "primary" : "#ddd"}
              leftIcon={<FiThumbsUp fontSize="14px" />}
              fontWeight="400"
            >
              {post.likes_count}
            </Button>
          </Flex>
          <Divider my={2}></Divider>
          <Text p={2} px={4}>
            {`댓글 ${comments.length}`}
          </Text>
          <Box px={2} mb={8}>
            <form onSubmit={handleAddComment}>
              <Box p={2} bg="gray.100">
                <Textarea
                  placeholder="댓글을 입력하세요"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  border="none"
                />
                <Divider my={2}></Divider>
                <Flex justify="flex-end">
                  <Button type="submit" bg="primary" color="white">
                    등록하기
                  </Button>
                </Flex>
              </Box>
            </form>
          </Box>
          <Box px={4}>
            {comments.map((comment) => (
              <Box py={2} borderBottomWidth={1} key={comment.id}>
                <Flex align="center" justify="space-between">
                  <Flex align="center" fontSize="12px" color="gray.400">
                    <Text mr={4}>
                      {comment?.writer?.nick_name || "닉네임없음"}
                    </Text>
                    <Text>{moment(comment.created_at).format("YY.MM.DD")}</Text>
                  </Flex>
                  <Flex align="center" color="gray.400">
                    {comment?.writer?.id === user.id && (
                      <>
                        <Button
                          variant="ghost"
                          size="xs"
                          px={1}
                          fontSize="12px"
                          onClick={() =>
                            handleEdit({ id: comment.id, value: comment.title })
                          }
                        >
                          수정
                        </Button>
                        <Divider
                          orientation="vertical"
                          mx={1}
                          h="10px"
                          w={"2px"}
                          bg="gray.300"
                        />
                        <Button
                          variant="ghost"
                          size="xs"
                          px={1}
                          fontSize="12px"
                          onClick={() => handleDeleteComment(comment.id)}
                        >
                          삭제
                        </Button>
                      </>
                    )}
                  </Flex>
                </Flex>
                <Text color="gray.900">{comment.title}</Text>
              </Box>
            ))}
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default PostPage;

import {
  Box,
  Button,
  Text,
  Modal,
  ModalContent,
  ModalBody,
  HStack,
} from "@chakra-ui/react";

const LogoutModal = ({
  isOpen,
  onClose,
  callback,
}: {
  isOpen: boolean;
  onClose: () => void;
  callback: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalContent w="320px" shadow="2xl">
        <ModalBody p={8}>
          <Box textAlign="center">
            <Box py={8}>
              <Text fontSize="18px" mb={3} py={6}>
                정말 로그아웃 하시겠어요?
              </Text>
            </Box>
            <HStack w="full">
              <Button
                isFullWidth
                bg="primary"
                color="white"
                h="44px"
                onClick={() => {
                  callback();
                  onClose();
                }}
              >
                네
              </Button>
              <Button
                isFullWidth
                bg="#6666FF34"
                color="primary"
                onClick={() => onClose()}
                h="44px"
              >
                아니요
              </Button>
            </HStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LogoutModal;

import React from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Flex,
  Heading,
  Text,
  Image,
  useToast,
  FormLabel,
  useDisclosure,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import useUser from "@data/useUser";
import Helmet from "@layouts/Helmet";
import PasswordField from "@components/PasswordField";
import kakao from "@assets/icons/kakao.png";
import moment from "moment";
import AuthModal from "@components/modal/AuthModal";
import logo from "@assets/images/logo_vertical.png";
import KakaoLogin from "react-kakao-login";
import { LoginResponse, UserProfile } from "@type/kakao";
import { signUp, login } from "@services/auth";

// const token = "9a03cc6095cacde21d36b5ed2d642e6d";
const token = "202e190de159d4370c8d533fc122d51d"; // vinmath@daum.net

const LoginPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, userMutate } = useUser();
  const toast = useToast();

  React.useEffect(() => {
    const handleModal = () => {
      const reset = localStorage.getItem("reset");
      if (reset) return;
      const modal = localStorage.getItem("modal");
      if (modal) {
        const date = JSON.parse(modal);
        const diff = moment.duration(moment().diff(moment(date))).asDays();
        if (diff > 3) onOpen();
      } else {
        return onOpen();
      }
    };
    handleModal();
  }, [onOpen]);

  if (user) return <Redirect to="/" />;

  const handleLogin = ({
    profile,
  }: {
    response: LoginResponse;
    profile?: UserProfile;
  }) => {
    console.log(profile?.kakao_account.email);
  };

  const handleError = () => {
    toast({
      title: "오류",
      position: "top",
      description: "로그인에 실패했습니다.",
      status: "error",
      duration: 4000,
      isClosable: true,
    });
  };

  return (
    <>
      <AuthModal isOpen={isOpen} onClose={onClose} />
      <Helmet
        title="로그인"
        keywords="로그인"
        description="로그인페이지 입니다."
      />
      <Box w={{ base: "100%", md: "375px" }} mx="auto">
        <Flex justify="center" pt={10}>
          <Link to="/">
            <Image src={logo} w="140px" h="158px" />
          </Link>
        </Flex>
        <Box maxW="311px" mx="auto">
          <Box textAlign="center" py={4} color={"gray.800"}>
            <Heading fontSize={"20px"}>문제풀이의 포텐셜을 올리자!</Heading>
            <Text mt={1}>나만을 위한 학습가이드, 포텐셜</Text>
          </Box>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("이메일을 확인하세요.")
                .required("이메일을 입력하세요."),
              password: Yup.string().required("비밀번호를 입력하세요."),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const { email, password } = values;
              try {
                const { data } = await login({ identifier: email, password });
                const { jwt } = data;
                localStorage.setItem("jwt", jwt);
                userMutate();
              } catch (err) {
                toast({
                  title: "오류",
                  position: "top",
                  description: "이메일 / 비밀번호를 확인하세요",
                  status: "error",
                  duration: 4000,
                  isClosable: true,
                });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isValid, dirty, isSubmitting }) => (
              <Form>
                <Field name="email">
                  {({ field, form }: any) => (
                    <FormControl
                      h="90px"
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel color={"gray.400"} fontSize="14px">
                        이메일
                      </FormLabel>
                      <Input {...field} id="email" placeholder="이메일" />
                      <FormErrorMessage justifyContent="flex-end" mt={1}>
                        {form.errors.email}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {({ field, form }: any) => (
                    <PasswordField
                      id="password"
                      placeholder="패스워드"
                      field={field}
                      form={form}
                      passType="password"
                    />
                  )}
                </Field>
                <Button
                  px={8}
                  bg={isValid && dirty ? "primary" : "gray.300"}
                  color={"white"}
                  isLoading={isSubmitting}
                  type="submit"
                  isFullWidth
                  h={"44px"}
                  borderRadius={4}
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  로그인하기
                </Button>
                <Flex justify="flex-end" mt={4}>
                  {/* <Flex align="flex-start">
                    <Checkbox colorScheme="orange">
                      <Text color={"black"} textAlign="right" fontSize="12px">
                        로그인 상태 유지하기
                      </Text>
                    </Checkbox>
                  </Flex> */}
                  <Box>
                    <Text color={"gray.400"} textAlign="right" fontSize="12px">
                      비밀번호를 잊으셨나요?{" "}
                    </Text>
                    <Link to="password-reset">
                      <Text
                        color={"gray.600"}
                        textAlign="right"
                        fontSize="12px"
                        fontWeight="semibold"
                      >
                        비밀번호 재설정하기
                      </Text>
                    </Link>
                  </Box>
                </Flex>
                <KakaoLogin
                  token={token}
                  onSuccess={handleLogin}
                  onFail={handleError}
                  style={{
                    background: "#fee500",
                    width: "100%",
                    marginTop: "40px",
                  }}
                >
                  <Button
                    bg={"#FEE500"}
                    mx="auto"
                    isFullWidth
                    h={"44px"}
                    borderRadius={4}
                  >
                    <Image src={kakao} />
                    <Text ml={2}>카카오로 빠르게 시작하기</Text>
                  </Button>
                </KakaoLogin>
                <Text
                  color={"gray.500"}
                  mt={4}
                  textAlign="center"
                  fontSize="12px"
                >
                  아직 포텐셜 회원이 아닌가요?
                </Text>
                <Link to="signup">
                  <Text fontSize="12px" textAlign="center" color={"black"}>
                    회원가입하기
                  </Text>
                </Link>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default LoginPage;

import moment from "moment";
import "moment/locale/ko";

export const convertMin = (time: number) => {
  const min = Math.floor(time / 60);
  const sec = time % 60;
  return `${min}분 ${sec}초`;
};

export const convertMinSep = (time: number) => {
  const min = Math.floor(time / 60);
  const sec = time % 60;
  const convertSec = sec < 10 ? `0${sec}` : sec;
  return `${min}:${convertSec}`;
};

export const convertTimeFormat = (time: number) => {
  const min = Math.floor(time / 60);
  const sec = time % 60;
  return { min, sec };
};

export const convertDateTime = (date: Date) => {
  return `${moment(date).format("YY.MM.DD")} ${moment(date).format("LT")}`;
};

import useSWR from "swr";
import { Book } from "@type/book";

const useEnrollBooks = ({
  id,
  type,
  test = "book",
}: {
  id: string | undefined;
  type?: string;
  test?: "test" | "book" | "all";
}) => {
  const { data, error, mutate } = useSWR(() => {
    let query = "";
    let isTest = "";
    if (type === "all") query = "";
    if (type === "book") query = "&name_ncontains=과제";
    if (type === "homework") query = "&name_contains=과제";
    if (test === "book") isTest = "&is_test=false";
    if (test === "test") isTest = "&is_test=true";
    return id
      ? `books?users_in=${id}&_sort=id:DESC${isTest}${query}`
      : null;
  });
  return {
    isLoading: !error && !data,
    isError: error,
    enrollBooks: data,
    enrollBooksMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    enrollBooks: Book[];
    enrollBooksMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useEnrollBooks;

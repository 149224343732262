import useSWR from "swr";
import { NoteExam } from "@type/noteExam";

const useNoteExams = ({
  userId,
  type,
}: {
  userId: string | undefined;
  type?: string | undefined;
}) => {
  const { data, error, mutate } = useSWR(() => {
    let query = "";
    let isTest = "";
    if (type === "all") query = "";
    if (type === "book") query = "&book.name_ncontains=과제";
    if (type === "homework") query = "&book.name_contains=과제";
    return userId ? `note-exams?user=${userId}&_sort=id:DESC${isTest}${query}` : null;
  });
  return {
    noteExams: data,
    isLoading: !error && !data,
    isError: error,
    noteExamsMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    noteExams: NoteExam[];
    noteExamsMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useNoteExams;

import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Text,
  Modal,
  ModalContent,
  ModalBody,
  VStack,
} from "@chakra-ui/react";

const ExamInfoModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const history = useHistory();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalContent w="320px" shadow="2xl">
        <ModalBody p={8}>
          <Box textAlign="center">
            <Text fontSize="18px" mb={3}>
              학습하기 이렇게 사용하세요!
            </Text>
            <Text color="gray.400" fontSize="13px" mb={4}>
            문항의 답을 클릭하거나.
              <br />
              키보드의 숫자키(      )를 누르면
답이 입력되고
Enter(      )를 누르면
다음 문제로 이동합니다.
            </Text>
            <VStack w="full">
              <Button
                isFullWidth
                bg="primary"
                color="white"
                onClick={() => {
                  onClose();
                }}
                h="44px"
              >
                확인하기
              </Button>
              <Button
                isFullWidth
                bg="#6666FF34"
                h="44px"
                onClick={() => {
                  onClose();
                  localStorage.setItem("exammodal", JSON.stringify(new Date()));
                }}
              >
                <Text color="primary">다신 보지 않기</Text>
              </Button>
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ExamInfoModal;

import React from "react";
import { useSWRInfinite } from "swr";
import { Post } from "@type/post";

const SIZE = 10;

const usePostsMy = (id: string | undefined) => {
  const [sort, setSort] = React.useState("recent");
  const url = `posts?type=post&writer=${id}`;
  const { data, error, mutate, size, setSize } = useSWRInfinite((idx) => {
    let query = "";
    if (sort === "recent") query += "&_sort=id:DESC";
    if (sort === "view") query += "&_sort=view:DESC";
    if (sort === "comment") query += "&_sort=comments_count:DESC";
    if (sort === "like") query += "&_sort=likes_count:DESC";
    return `${url}&_start=${idx * SIZE}&_limit=${SIZE}${query}`;
  });

  const issues = data ? [].concat(...data) : [];
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < SIZE);

  return {
    sort,
    setSort,
    size,
    setSize,
    posts: issues,
    isLoading: !error && !data,
    isError: error,
    isReachingEnd,
    postsMutate: mutate,
  } as {
    sort: string;
    setSort: any;
    size: number;
    setSize: any;
    posts: Post[];
    isLoading: boolean;
    isError: any;
    isReachingEnd: boolean;
    postsMutate: any;
  };
};

export default usePostsMy;

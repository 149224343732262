import { Box, Text, Flex } from "@chakra-ui/react";
import useUser from "@data/useUser";
import Avatar from "@components/Avatar";

const HomeHeader: React.FC = ({ children }) => {
  const { user } = useUser();

  return (
    <Flex
      py={4}
      px={6}
      align={{ base: "stretch", md: "center" }}
      flexDirection={{ base: "column", md: "row" }}
    >
      <Flex flex={1} alignItems="center">
        <Avatar user={user} />
        <Box fontSize={"14px"} ml={2}>
          <Text>
            {user.username}{" "}
            <Text as="span" color="gray.400" fontSize={"12px"}>
              {`${user.grade}등급`}
            </Text>
          </Text>
          <Text color="gray.400">오늘 하루 화이팅!</Text>
        </Box>
      </Flex>
      {children}
    </Flex>
  );
};

export default HomeHeader;

import useSWR from "swr";
import { Chapter } from "@type/chapter";

const useChapters = ({ id, ids }: { id?: string; ids?: string }) => {
  const { data, error, mutate } = useSWR(() => {
    if (ids) return `chapters?${ids}`;
    if (id) return `chapters?book=${id}`;
    return null;
  });
  return {
    isLoading: !error && !data,
    isError: error,
    chapters: data,
    chaptersMutate: mutate,
  } as {
    isLoading: boolean;
    isError: any;
    chapters: Chapter[];
    chaptersMutate: (
      data?: any,
      shouldRevalidate?: boolean | undefined
    ) => Promise<any>;
  };
};

export default useChapters;

import React from "react";
import { useSWRInfinite } from "swr";
import { Question } from "@type/question";

const SIZE = 10;

const useQuestionsMy = (id: string | undefined) => {
  const [sort, setSort] = React.useState("recent");
  const [filter, setFilter] = React.useState("all");
  const url = `questions?writer=${id}&_sort=id:DESC`;
  const { data, error, mutate, size, setSize } = useSWRInfinite((idx) => {
    let query = "";
    if (filter === "selected") query += `&answer_null=false`;
    if (filter === "notSelected") query += `&answer_null=true`;
    return `${url}&_start=${idx * SIZE}&_limit=${SIZE}${query}`;
  });

  const issues = data ? [].concat(...data) : [];
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < SIZE);

  return {
    filter,
    setFilter,
    sort,
    setSort,
    size,
    setSize,
    questions: issues,
    isLoading: !error && !data,
    isError: error,
    isReachingEnd,
    questionsMutate: mutate,
  } as {
    filter: string;
    setFilter: any;
    sort: string;
    setSort: any;
    size: number;
    setSize: any;
    questions: Question[];
    isLoading: boolean;
    isError: any;
    isReachingEnd: boolean;
    questionsMutate: any;
  };
};

export default useQuestionsMy;

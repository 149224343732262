import axios from "axios";
import { NoteProblem } from "../types/noteProblem";
interface Problem {
  num: number;
  problem: string;
  user: string;
}

interface ProblemUpdate {
  num?: number;
  answer?: boolean;
  timeover?: boolean;
  resolve?: boolean;
  solve_time?: number;
  check?: boolean;
}

export const getProblems = async ({
  id,
  ids,
}: {
  id: string | undefined;
  ids: string;
}): Promise<NoteProblem[]> => {
  const { data } = await axios.get(`note-problems?user=${id}&${ids}`);
  return data;
};

export const addProblem = (body: Problem) => {
  return axios.post(`note-problems`, body);
};

export const updateProblem = ({
  id,
  body,
}: {
  id: string;
  body: ProblemUpdate;
}) => {
  return axios.put(`note-problems/${id}`, body);
};

import React from "react";
import { Center, CircularProgress } from "@chakra-ui/react";
import AppRouter from "./navigation/Router";
import Helmet from "./layouts/Helmet";
import useUser from "./datas/useUser";
import "./utils/style.css";

const App = () => {
  const [loading, setLoading] = React.useState(true);
  useUser();
  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  if (loading)
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  return (
    <>
      <Helmet
        title="포텐셜"
        keywords="테스트"
        description="테스트페이지 입니다."
      />
      <AppRouter />
    </>
  );
};

export default App;

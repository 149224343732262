import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import useUser from "@data/useUser";
import AlertModal from "@components/modal/AlertModal";
import SubscriptionModal from "@components/modal/SubscriptionModal";
import { verifyBookCode } from "@services/bookCode";
import { BookCode } from "@type/bookCode";
import { useHistory } from "react-router";

const SubscriptionPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: open,
    onOpen: handleOpen,
    onClose: handleClose,
  } = useDisclosure();
  const history = useHistory();
  const { user } = useUser();

  return (
    <>
      <AlertModal
        title="유효하지 않은 등록코드입니다."
        subTitle="등록코드를 다시 확인해주세요."
        isOpen={isOpen}
        onClose={onClose}
      />
      <SubscriptionModal isOpen={open} onClose={handleClose} />
      <Helmet
        title="교재등록"
        keywords="교재등록"
        description="교재등록페이지 입니다."
      />
      <Layout title="교재 등록" onBack={()=>history.push('/')}>
        <Box w={{ base: "100%", md: "400px" }} mx="auto">
          <Heading
            pt={14}
            pb={8}
            textAlign="center"
            fontSize={"xl"}
            color={"gray.900"}
          >
            교재를 학습하기 위해
            <br />
            등록코드를 입력해주세요.
          </Heading>
          <Formik
            initialValues={{ code: "" }}
            validationSchema={Yup.object({
              code: Yup.string().required("코드를 입력하세요."),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const { code } = values;
              const userId = user?.id || '';
              try {
                const bookcode = {
                  user: userId,
                  bookcode: code
                } as BookCode;
                await verifyBookCode(bookcode);
                handleOpen();
              } catch (err) {
                onOpen();
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box py={4} px={4}>
                  <Field name="code">
                    {({ field, form }: any) => (
                      <FormControl
                        h="100px"
                        isInvalid={form.errors.code && form.touched.code}
                      >
                        <FormLabel color={"gray.400"} fontSize="14px">
                          등록코드
                        </FormLabel>
                        <Input {...field} id="code" placeholder="등록코드" />
                        <FormErrorMessage justifyContent="flex-end" mt={1}>
                          {form.errors.code}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Button
                    bg="primary"
                    color="white"
                    isLoading={isSubmitting}
                    type="submit"
                    isFullWidth
                    h={"44px"}
                    borderRadius={4}
                  >
                    교재등록하기
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Layout>
    </>
  );
};

export default SubscriptionPage;

import { useLocation, Link } from "react-router-dom";
import { Text, HStack , Badge} from "@chakra-ui/react";

const TABS = [
  { value: "/", name: "나의 교재" },
  { value: "/home-test", name: "모의고사" },
  { value: "/home-note", name: "오답노트" },
  { value: "/home-my", name: "학습달력" },
];

const HomeTab = () => {
  const location = useLocation();
  return (
    <HStack px={4}>
      {TABS.map((el) => (
        <Link to={el.value} key={el.value}>
          <Text
            p={2}
            borderBottomWidth={location.pathname === el.value ? 2 : 2}
            borderBottomColor={
              location.pathname === el.value ? "primary" : "transparent"
            }
            color={location.pathname === el.value ? "black" : "gray.400"}
            cursor="pointer"
            _hover={{ color: "gray.600" }}
            fontSize={{ base: "13px", md: "16px" }}
          >
            {el.name}
            {/* 오답노트에만 표시 */}
            {
            el.name == '오답노트' ? 
            (
            <Badge style={{marginBottom: "20px"}} ml="1" fontSize="0.7em" variant="subtle"  colorScheme="purple">N</Badge>)
            : ("")}
          </Text>
        </Link>
      ))}
    </HStack>
  );
};

export default HomeTab;

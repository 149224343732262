import React from "react";
import {
  Flex,
  Box,
  Divider,
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Center,
  CircularProgress,
  Heading,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import HomeTab from "@layouts/HomeTab";
import HomeHeader from "@layouts/HomeHeader";
import useUser from "@data/useUser";
import { ChevronDownIcon } from "@chakra-ui/icons";
import useNotes from "@data/useNotes";
import moment from "moment";
import useNoteExams from "@data/useNoteExams";
import { useNoteModal } from "@hooks/useNoteModal";
import { getNote } from "@services/note";
import { Note } from "@type/note";
import { useHistory } from "react-router-dom";
import { convertDateTime } from "@util/time";

const HomeNotePage = () => {
  const history = useHistory();
  const [sort, setSort] = React.useState("recent");
  const { user } = useUser();
  const { notes } = useNotes({ id: user?.id });
  const { noteExams } = useNoteExams({ userId: user?.id });

  const {
    setNoteExam,
    setNote,
    setType,
    setTitle,
    setSubTitle,
    setRoute,
    TestModal,
    onOpen,
  } = useNoteModal();

  const handleTest = async (note: Note) => {
    const { data } = await getNote(note.id);
    setNote(data);
    const currentExams = noteExams.filter((el) => el.note.id === data.id);
    const completed = currentExams.every((el) => el.is_submit);
    const first = currentExams.length === 0;
    const noteExam = currentExams.find((el) => !el.is_submit);
    const current = noteExam?.note_problems.filter((el) => el.resolve).length;
    if (first) {
      setTitle("학습을 시작하시겠습니까?");
      setSubTitle("시작 직후 학습 시간이 카운트 됩니다.");
      setType("new");
    } else if (completed) {
      setTitle(`학습이 완료되었습니다.\n다시 하겠습니까?`);
      setSubTitle("시작 직후 학습 시간이 카운트 됩니다.");
      setType("again");
    } else if (noteExam && current === 0) {
      setNoteExam(noteExam);
      setTitle("임시 저장된 학습이력이 있습니다.\n이어서 하시겠어요?");
      const date = noteExam.note_problems
        .map((el) => new Date(el.updated_at) as any)
        .sort((a, b) => b - a)
        .pop();
      const text = convertDateTime(date);
      setSubTitle(text);
      setType("never");
    } else if (noteExam && current) {
      setNoteExam(noteExam);
      const date = noteExam.note_problems
        .map((el) => new Date(el.updated_at) as any)
        .sort((a, b) => b - a)
        .pop();
      const text = convertDateTime(date);
      setTitle(`임시 저장된 학습이력이 있습니다.\n이어서 하시겠어요?`);
      setSubTitle(text);
      setType("continue");
    }
    onOpen();
  };

  if (!notes || !noteExams) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  return (
    <>
      <TestModal />
      <Helmet
        title="홈페이지"
        keywords="홈페이지"
        description="홈페이지페이지 입니다."
      />
      <Layout title="오답노트" noArrow>
        <Box>
          <HomeHeader />
          <HomeTab />
          <Divider />
          <Flex p={4} px={6} justify="flex-end">
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    size="sm"
                    bg="#fff"
                    borderWidth={1}
                    rounded="none"
                    color="gray.400"
                    fontSize="14px"
                  >
                    {sort === "recent" ? "최신순" : "이름순"}
                  </MenuButton>
                  <MenuList minW="auto" w="84px" rounded="none">
                    {[
                      { value: "recent", label: "최신순" },
                      { value: "name", label: "이름순" },
                    ].map((el) => (
                      <MenuItem
                        fontSize="14px"
                        color="gray.400"
                        key={el.value}
                        onClick={() => setSort(el.value)}
                      >
                        {el.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
          <Box>
            {notes.length == 0 ? 
            <Box px={6}><Text color="gray.600" fontSize={"15px"}>아직 오답노트가 없습니다.</Text></Box> 
            : notes.map((el) => (
              <Flex
                key={el.id}
                px={6}
                borderBottomWidth={1}
                _hover={{ bg: "gray.50" }}
                cursor="pointer"
                h="100px"
                align="center"
                backgroundColor={el.is_submit? 'gray.100':''} 
              >
                <Box w="full">
                  <Heading fontSize="16px" fontWeight="500" mb={1}>
                  {el.name} 
                  </Heading>
                  <Text color="gray.400" fontSize={"12px"}>
                    {`${el.problems.length}문항`}
                  </Text>
                  <Text color="gray.300" fontSize={"10px"}>
                    {moment(el.created_at).format("YY.MM.DD")}
                  </Text>
                  <HStack justify="flex-end">
                  <Button
                      color="primary"
                      size="xs"
                      bg="white"
                      borderWidth={1}
                      borderColor="primary"
                      rounded="sm"
                      fontSize="10px"
                      _hover={{ bg: "gray.100" }}
                      onClick={() => history.push(`/answer/${el.id}`)}
                    >
                      해설보기
                    </Button>
                    <Button
                      color="primary"
                      size="xs"
                      bg="white"
                      borderWidth={1}
                      borderColor="primary"
                      rounded="sm"
                      fontSize="10px"
                      _hover={{ bg: "gray.100" }}
                      onClick={() => history.push(`/answer/${el.id}/print`)}
                    >
                      노트출력
                    </Button>
                    <Button
                      color="primary"
                      size="xs"
                      bg="white"
                      borderWidth={1}
                      borderColor="primary"
                      rounded="sm"
                      fontSize="10px"
                      _hover={{ bg: "gray.100" }}
                      onClick={() => {
                        setRoute("omr");
                        handleTest(el);
                      }}
                    >
                      OMR
                    </Button>
                    <Button
                      color="white"
                      size="xs"
                      bg="primary"
                      borderWidth={1}
                      borderColor="primary"
                      rounded="sm"
                      fontSize="10px"
                      _hover={{ bg: "primary", opacity: 0.8 }}
                      onClick={() => {
                        setRoute("chapter");
                        handleTest(el);
                      }}
                    >
                      학습하기
                    </Button>
                  </HStack>
                </Box>
              </Flex>
            ))}
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default HomeNotePage;

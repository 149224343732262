import { Box, Text } from "@chakra-ui/react";
import { Calandar } from "@type/calendar";

const CalendarCard = ({ item }: { item: Calandar }) => {
  const bgColor = () => {
    if (item.type === "note_create") return "primary";
    if (item.type === "note_learn") return "#EB7AA7";
    if (item.type === "exam_create") return "#8DD57B";
    if (item.type === "exam_learn") return "#2CB49E";
    if (item.type === "book_create") return "#58CBFC";
    if (item.type === "book_learn") return "#3B9AF5";
  };
  return (
    <Box w="full">
      <Box
        h={2}
        bg={bgColor()}
        roundedTopLeft="full"
        roundedTopRight="full"
      ></Box>
      <Box bg="white" py={"10px"} px="18px" fontSize="14px">
        <Text>{item.title}</Text>
        <Text color="gray.400">{item.desc}</Text>
      </Box>
    </Box>
  );
};

export default CalendarCard;

import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Flex,
  Box,
  Divider,
  Text,
  Center,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  CircularProgress,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import HistoryTab from "@layouts/HistoryTab";
import useUser from "@data/useUser";
import moment from "moment";
import { ChevronDownIcon } from "@chakra-ui/icons";
import useExamsPage from "@data/useExamsPage";

const Item = ({ label, value }: { label: string; value: string }) => (
  <Flex align="center" justify="space-between" fontSize="12px">
    <Text mr={2} color="gray.400">
      {label}
    </Text>
    <Text color="gray.900">{value}</Text>
  </Flex>
);

const HistoryBookPage = () => {
  const history = useHistory();

  const [filter, setFilter] = React.useState("all");
  const { user } = useUser();
  const {
    exams,
    sort,
    setSort,
    setSize,
    isReachingEnd,
    isLoading,
  } = useExamsPage({
    id: user?.id,
    type: filter,
  });

  if (!exams) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }
  const handleMore = () => setSize((value: number) => value + 1);

  return (
    <>
      <Helmet
        title="학습이력 - 교재"
        keywords="학습이력"
        description="학습이력페이지 입니다."
      />
      <Layout title="학습이력" onBack={()=>history.push('/')}>
        <HistoryTab />
        <Divider />
        <Box bg="gray.100" p={4} pt={2} flex={1}>
          <Flex mb={2} justify="space-between">
            <HStack pl={1} divider={<Divider orientation="vertical" />}>
              {[
                { value: "all", label: "전체" },
                { value: "book", label: "교재" },
                { value: "homework", label: "과제" },
              ].map((el) => (
                <Text
                  key={el.value}
                  fontSize="14px"
                  cursor={"pointer"}
                  color={filter === el.value ? "black" : "gray.400"}
                  onClick={() => setFilter(el.value)}
                >
                  {el.label}
                </Text>
              ))}
            </HStack>
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    size="sm"
                    bg="#fff"
                    borderWidth={1}
                    rounded="none"
                    color="gray.400"
                    fontSize="14px"
                  >
                    {sort === "recent" ? "최신순" : "이름순"}
                  </MenuButton>
                  <MenuList minW="auto" w="84px" rounded="none">
                    {[
                      { value: "recent", label: "최신순" },
                      { value: "name", label: "이름순" },
                    ].map((el) => (
                      <MenuItem
                        fontSize="14px"
                        color="gray.400"
                        key={el.value}
                        onClick={() => setSort(el.value)}
                      >
                        {el.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
          {exams.map((el) => (
            <Link to={`/history/${el.id}`} key={el.id}>
              <Flex
                w="full"
                bg="white"
                flexDir={{ base: "column", md: "row" }}
                p={3}
                mb={2}
                borderBottomWidth={1}
                _hover={{ bg: "gray.50" }}
                cursor="pointer"
                align={{ base: "flex-start", md: "center" }}
                justify="space-between"
              >
                <Flex align="center">
                  <Box w={"50px"} h={"50px"} bg="gray.100" mr={3}>
                    <Center h="full" fontSize="xs">
                      Book
                    </Center>
                  </Box>
                  <Box fontSize="14px">
                    <Text fontWeight="500" mb={1}>
                      {el.book.name}
                    </Text>
                    <Text color="gray.400">{el.chapter.name}</Text>
                  </Box>
                </Flex>
                <Flex mt={{ base: 2, md: 0 }}>
                  <Divider orientation="vertical" h="50px" mr={2} />
                  <Box>
                    <Item
                      label="학습일시"
                      value={moment(el.updated_at).format("YY.MM.DD")}
                    />
                    <Item label="제출여부" value={el.is_submit ? "O" : "X"} />
                    <Item label="평가여부" value={el.is_submit ? "O" : "X"} />
                  </Box>
                </Flex>
              </Flex>
            </Link>
          ))}
          {!isReachingEnd && !isLoading && (
            <Flex justify="center" my={4}>
              <Button bg="primary" color="white" onClick={handleMore}>
                더보기
              </Button>
            </Flex>
          )}
        </Box>
      </Layout>
    </>
  );
};

export default HistoryBookPage;

import axios from "axios";

export const setAxios = () => {
  
  axios.defaults.baseURL = process.env.REACT_APP_URL;
  axios.interceptors.request.use(
    (config) => {
      const jwt = localStorage.getItem("jwt");
      if (jwt) {
        config.headers.Authorization = `Bearer ${jwt}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );
};

export const fetcher = (url: string) => axios.get(url).then((res) => res.data);

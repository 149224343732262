import { Box, Flex, Text, HStack, Button } from "@chakra-ui/react";
import { Chapter } from "@type/chapter";

export interface Prop {
  chapter: Chapter;
  handleOmr: () => void;
  handleChapter: () => void;
  handleAnswer: () => void;
}

const ChapterCard = ({
  chapter,
  handleAnswer,
  handleOmr,
  handleChapter,
}: Prop) => {
  return (
    <Flex
      px={6}
      borderBottomWidth={1}
      _hover={{ bg: "gray.50" }}
      cursor="pointer"
      h="100px"
      align="center"
    >
      <Box w="full">
        <Text fontWeight="500">{chapter.name}</Text>
        <Text color="gray.400" fontSize={"12px"}>
          [{`${chapter.id}`}] {`${chapter.problems.length}문항`}
          
        </Text>
        <HStack justify="flex-end">
          <Button
            color="primary"
            size="xs"
            bg="white"
            borderWidth={1}
            borderColor="primary"
            rounded="sm"
            fontSize="10px"
            _hover={{ bg: "gray.100" }}
            onClick={handleAnswer}
          >
            해설보기
          </Button>
          <Button
            color="primary"
            size="xs"
            bg="white"
            borderWidth={1}
            borderColor="primary"
            rounded="sm"
            fontSize="10px"
            _hover={{ bg: "gray.100" }}
            onClick={handleOmr}
          >
            OMR
          </Button>
          <Button
            color="white"
            size="xs"
            bg="primary"
            borderWidth={1}
            borderColor="primary"
            rounded="sm"
            fontSize="10px"
            _hover={{ bg: "primary", opacity: 0.8 }}
            onClick={handleChapter}
          >
            학습하기
          </Button>
        </HStack>
      </Box>
    </Flex>
  );
};

export default ChapterCard;

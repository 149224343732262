import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Select,
  Alert,
  AlertIcon,
  FormLabel,
  Container,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import { updateUser, getNickname } from "@services/auth";
import useUser from "@data/useUser";

const MyEditPage = () => {
  const history = useHistory();
  const { user, userMutate } = useUser();
  const toast = useToast();

  return (
    <>
      <Helmet
        title="내정보"
        keywords="내정보"
        description="내정보페이지 입니다."
      />
      <Layout title="내정보" onBack={()=>history.push('/')
      }>
        <Container maxW="375px">
          <Heading fontSize="20px" mt={10} mb={5} textAlign="center">
            수정하기
          </Heading>
          <Formik
            initialValues={{
              nick_name: user.nick_name,
              phone: user.phone,
              year: user?.year as number,
            }}
            validationSchema={Yup.object({
              nick_name: Yup.string()
                .required("닉네임을 입력하세요.")
                .min(2, "최소 2자 이상 입력하세요")
                .max(10, "최대 10자까지 입력하세요"),
              phone: Yup.number()
                .typeError("숫자만 입력하세요.")
                .test("len", "핸드폰 번호를 확인하세요.", (val: any) =>
                  val ? val.toString().length === 10 : true
                )
                .required("전화번호를 입력하세요."),
              year: Yup.string().required("학년을 선택하세요."),
            })}
            onSubmit={async (values, { setStatus, setSubmitting }) => {
              setSubmitting(true);
              setStatus("");
              const { nick_name, phone, year } = values;
              try {
                let body: any = { phone, year: +year };
                if (nick_name !== user.nick_name) {
                  const res = await getNickname(nick_name as string);
                  if (res.data.length > 0) throw new Error("nick_name");
                  body["nick_name"] = nick_name;
                }
                await updateUser({ id: user.id, body });
                userMutate();
                toast({
                  title: "POTENTIAL",
                  description: "정보를 성공적으로 수정하였습니다.",
                  status: "success",
                  duration: 4000,
                  isClosable: true,
                  position: "top",
                });
              } catch (err) {
                setStatus("닉네임이 이미 존재합니다.");
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ values, isSubmitting, status }) => (
              <Form>
                {status && (
                  <Alert status="error" borderRadius={4}>
                    <AlertIcon />
                    {status}
                  </Alert>
                )}

                <Field name="nick_name">
                  {({ field, form }: any) => (
                    <FormControl
                      h="90px"
                      isInvalid={
                        form.errors.nick_name && form.touched.nick_name
                      }
                    >
                      <FormLabel color={"gray.400"} fontSize="14px">
                        닉네임
                      </FormLabel>
                      <Input {...field} id="nick_name" placeholder="닉네임" />
                      <FormErrorMessage justifyContent="flex-end">
                        {form.errors.nick_name}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="phone">
                  {({ field, form }: any) => (
                    <FormControl
                      h="90px"
                      isInvalid={form.errors.phone && form.touched.phone}
                    >
                      <FormLabel color={"gray.400"} fontSize="14px">
                        휴대폰 번호
                      </FormLabel>
                      <Input
                        {...field}
                        id="phone"
                        placeholder="전화번호를 입력하세요"
                      />
                      <FormErrorMessage justifyContent="flex-end">
                        {form.errors.phone}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="year">
                  {({ field, form }: any) => (
                    <FormControl
                      h="90px"
                      isInvalid={form.errors.year && form.touched.year}
                    >
                      <FormLabel color={"gray.400"} fontSize="14px">
                        학년
                      </FormLabel>
                      <Select
                        {...field}
                        id="year"
                        placeholder="학년을 선택해주세요."
                        color={values.year ? "black" : "gray.400"}
                      >
                        <option value="1">중1</option>
                        <option value="2">중2</option>
                        <option value="3">중3</option>
                        <option value="4">고1</option>
                        <option value="5">고2</option>
                        <option value="6">고3/재수</option>
                      </Select>
                      <FormErrorMessage justifyContent="flex-end">
                        {form.errors.year}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  px={8}
                  bg={"primary"}
                  color={"white"}
                  isLoading={isSubmitting}
                  type="submit"
                  isFullWidth
                  mt={8}
                  h={"44px"}
                  borderRadius={4}
                >
                  수정 완료
                </Button>
              </Form>
            )}
          </Formik>
        </Container>
      </Layout>
    </>
  );
};

export default MyEditPage;

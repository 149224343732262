import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Text,
  Modal,
  ModalContent,
  ModalBody,
  VStack,
} from "@chakra-ui/react";

const AuthModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const history = useHistory();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalContent w="320px" shadow="2xl">
        <ModalBody p={8}>
          <Box textAlign="center">
            <Text fontSize="18px" mb={3}>
              기존 회원이신가요?
            </Text>
            <Text color="gray.400" fontSize="13px" mb={4}>
              포텐셜 버전 업그레이드로 인해
              <br />
              기존 회원의 비밀번호 재설정이 필요합니다.
              <br />
              재설정 후 새로워진 포텐셜을 만나보세요.
            </Text>
            <VStack w="full">
              <Button
                isFullWidth
                bg="primary"
                color="white"
                onClick={() => {
                  onClose();
                  localStorage.setItem("reset", "reset");
                  history.push("password-reset");
                }}
                h="44px"
              >
                비밀번호 재설정 하기
              </Button>
              <Button
                isFullWidth
                bg="#6666FF34"
                h="44px"
                onClick={() => {
                  onClose();
                  localStorage.setItem("modal", JSON.stringify(new Date()));
                }}
              >
                <Text color="primary">3일간 보지 않기</Text>
              </Button>
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AuthModal;

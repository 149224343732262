import React from "react";
import { Box, Flex, Text, HStack, Image } from "@chakra-ui/react";
import { FiEye, FiThumbsUp, FiMessageSquare } from "react-icons/fi";
import { Post } from "@type/post";
import { extractSrc } from "@util/reg";

const PostCard = ({ post }: { post: Post }) => {
  const image = extractSrc(post.desc);
  return (
    <Box w="full" bg="white" p={4} shadow="sm" borderBottomWidth={1}>
      <Flex align="center" justify="space-between">
        <Text
          color="gray.900"
          fontWeight="500"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {post.title}
        </Text>
        {image && <Image src={image} h={10} w={10} mr={2}></Image>}
      </Flex>
      <HStack>
        <Flex align="center">
          <FiEye color="#ddd" />
          <Text color="gray.400" fontSize="10px" ml={1}>
            {post.writer.nick_name}
          </Text>
        </Flex>
        <Flex align="center">
          <FiEye color="#ddd" />
          <Text color="gray.400" fontSize="10px" ml={1}>
            {post.view}
          </Text>
        </Flex>
        <Flex align="center">
          <FiThumbsUp color="#ddd" />
          <Text color="gray.400" fontSize="10px" ml={1}>
            {post.likes_count}
          </Text>
        </Flex>
        <Flex align="center">
          <FiMessageSquare color="#ddd" />
          <Text color="gray.400" fontSize="10px" ml={1}>
            {post.comments_count}
          </Text>
        </Flex>
      </HStack>
    </Box>
  );
};

export default PostCard;

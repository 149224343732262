import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
} from "@chakra-ui/react";
import usePostsMy from "@data/usePostsMy";
import useUser from "@data/useUser";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import MyHeader from "@layouts/MyHeader";
import MyTab from "@layouts/MyTab";
import { ChevronDownIcon } from "@chakra-ui/icons";
import PostCard from "@components/PostCard";

const MyPostPage = () => {
  const { user } = useUser();
  const {
    sort,
    setSort,
    posts,
    setSize,
    postsMutate,
    isReachingEnd,
    isLoading,
  } = usePostsMy(user.id);

  React.useEffect(() => {
    postsMutate();
  }, [postsMutate]);

  const handleMore = () => setSize((value: number) => value + 1);
  const history = useHistory();

  return (
    <>
      <Helmet
        title="나의 게시판"
        keywords="나의 게시판"
        description="나의 게시판페이지 입니다."
      />
      <Layout title="계정관리" onBack={()=> history.push('/')}>
        <MyHeader />
        <MyTab />
        <Box bg="gray.100" flex={1}>
          <Flex px={4} py={2} justify="flex-end">
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    size="sm"
                    bg="#fff"
                    borderWidth={1}
                    rounded="none"
                    color="gray.400"
                    fontSize="14px"
                  >
                    {sort === "view" && "조회순"}
                    {sort === "like" && "좋아요순"}
                    {sort === "comment" && "댓글순"}
                    {sort === "recent" && "최신순"}
                  </MenuButton>
                  <MenuList minW="auto" w="84px" rounded="none">
                    {[
                      { value: "view", label: "조회순" },
                      { value: "like", label: "좋아요순" },
                      { value: "comment", label: "댓글순" },
                      { value: "recent", label: "최신순" },
                    ].map((el) => (
                      <MenuItem
                        fontSize="14px"
                        color="gray.400"
                        key={el.value}
                        onClick={() => setSort(el.value)}
                      >
                        {el.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
          <Box borderTopWidth={1}>
            {posts &&
              posts.map((post) => (
                <Link to={`/posts/${post.id}`} key={post.id}>
                  <PostCard post={post} />
                </Link>
              ))}
            {!isReachingEnd && !isLoading && (
              <Flex justify="center" my={4}>
                <Button bg="primary" color="white" onClick={handleMore}>
                  더보기
                </Button>
              </Flex>
            )}
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default MyPostPage;

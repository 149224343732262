import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Text,
  Container,
  Flex,
  IconButton,
  useDisclosure,
  Center,
  CircularProgress,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import useUser from "@data/useUser";
import MyHeader from "@layouts/MyHeader";
import MyTab from "@layouts/MyTab";
import { FiChevronRight } from "react-icons/fi";
import LogoutModal from "@components/modal/LogoutModal";
import SignoutModal from "@components/modal/SignoutModal";

const mapYear: { [el: number]: string } = {
  1: "중1",
  2: "중2",
  3: "중3",
  4: "고1",
  5: "고2",
  6: "고3/재수",
};
const Item = ({ label, value }: { label: string; value?: string | number }) => {
  return (
    <Flex h={"44px"} px={6} borderBottomWidth={1} align="center">
      <Text fontSize="14px" w={100} color="gray.400">
        {label}
      </Text>
      <Text fontSize="14px">{value}</Text>
    </Flex>
  );
};

const ItemButton = ({
  color = "gray.900",
  handleClick,
  label,
  arrow = true,
}: {
  color?: string;
  handleClick?: () => void;
  label: string;
  arrow?: boolean;
}) => {
  return (
    <Flex
      h={"44px"}
      px={6}
      borderBottomWidth={1}
      align="center"
      justify="space-between"
      onClick={handleClick}
      cursor="pointer"
    >
      <Text fontSize="14px" color={color} fontWeight="500">
        {label}
      </Text>
      {arrow && (
        <IconButton
          m={0}
          p={0}
          size="sm"
          variant="ghost"
          aria-label={label}
          icon={<FiChevronRight />}
        />
      )}
    </Flex>
  );
};

const MySettingPage = () => {
  const history = useHistory();

  const logout = useDisclosure();
  const signout = useDisclosure();
  const { user, userMutate } = useUser();

  const handleLogout = () => {
    localStorage.removeItem("jwt");
    userMutate(null, false);
  };

  const handleSignout = () => {};

  if (!user) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  const ITEMS = [
    { label: "이름", value: user?.name },
    { label: "등급", value: user?.grade + "등급" },
    { label: "이메일", value: user?.email },
    { label: "닉네임", value: user?.nick_name },
    { label: "전화번호", value: user?.phone },
    { label: "학년", value: user?.year ? mapYear[user.year] : "" },
  ];

  return (
    <>
      <LogoutModal
        isOpen={logout.isOpen}
        onClose={logout.onClose}
        callback={handleLogout}
      />
      <SignoutModal
        isOpen={signout.isOpen}
        onClose={signout.onClose}
        callback={handleSignout}
      />
      <Helmet title="설정" keywords="설정" description="설정페이지 입니다." />
      <Layout title="계정관리" onBack={()=>history.push('/')}>
        <MyHeader />
        <MyTab />
        <Box p={5} bg="gray.100" flex={1}>
          <Container maxW="375px">
            <Text fontSize="12px" ml={4} color="gray.400">
              계정정보
            </Text>
            <Box bg="white" borderWidth={1}>
              {ITEMS.map((el) => (
                <Item label={el.label} value={el.value} key={el.label} />
              ))}
              <Link to={"/my-password"}>
                <ItemButton
                  label="비밀번호 재설정하기"
                  arrow={false}
                  color="primary"
                />
              </Link>
              <Link to={"/my-edit"}>
                <ItemButton
                  label="계정 정보 수정하기"
                  arrow={false}
                  color="primary"
                />
              </Link>
            </Box>
            <Text fontSize="12px" ml={4} color="gray.400" mt={8}>
              약관 및 정책
            </Text>
            <Box bg="white" borderWidth={1}>
              <ItemButton label="포텐셜 이용약관" handleClick={() => {}} />
              <ItemButton
                label="개인정보 수집 및 이용"
                handleClick={() => {}}
              />
              <ItemButton
                label="개인정보의 마케팅 및 광고에 이용"
                handleClick={() => {}}
              />
            </Box>
            <Text fontSize="12px" ml={4} color="gray.400" mt={8}>
              로그인 정보
            </Text>
            <Box bg="white" borderWidth={1}>
              <ItemButton
                label="로그아웃"
                handleClick={logout.onOpen}
                arrow={false}
              />
              <ItemButton
                label="회원탈퇴"
                handleClick={signout.onOpen}
                arrow={false}
              />
            </Box>
          </Container>
        </Box>
      </Layout>
    </>
  );
};

export default MySettingPage;

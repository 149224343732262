import React from "react";
import { useParams } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  Button,
  Textarea,
  Box,
  Flex,
} from "@chakra-ui/react";
import { updateComment } from "@services/comment";
import useComments from "@data/useComments";

export interface RouteParams {
  postId: string;
}

export const useCommentModal = () => {
  const { postId } = useParams<RouteParams>();
  const { commentsMutate } = useComments(postId);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setId] = React.useState("");
  const [comment, setComment] = React.useState("");
  const initialRef = React.useRef<any>();
  const finalRef = React.useRef<any>();

  const handleEdit = ({ id, value }: { id: string; value: string }) => {
    setComment(value);
    setId(id);
    onOpen();
  };

  const handleSubmit = async () => {
    const title = initialRef.current.value;
    await updateComment({ id, body: { title } });
    await commentsMutate();
    onClose();
  };

  const EditModal = React.memo(() => (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{""}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <Box bg="gray.100">
                <Textarea
                  ref={initialRef}
                  placeholder="댓글을 입력하세요"
                  defaultValue={comment}
                  border="none"
                />
              </Box>
            </FormControl>
            <Flex justify="flex-end" mt={2}>
              <Button
                type="submit"
                bg="primary"
                color="white"
                onClick={handleSubmit}
                mr={2}
              >
                등록하기
              </Button>
              <Button onClick={onClose}>취소</Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  ));
  return { handleEdit, EditModal };
};

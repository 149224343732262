import { Link, useParams, useHistory } from "react-router-dom";
import {
  Box,
  Center,
  CircularProgress,
  Flex,
  Text,
  VStack,
  Button,
  Spacer,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import useUser from "@data/useUser";
import useExam from "@data/useExam";
import useSolveProblems from "@data/useSolveProblems";
import moment from "moment";
import "moment/locale/ko";
import { convertMinSep } from "@util/time";

export interface RouteParams {
  examId: string;
}

const HistoryExamPage = () => {
  const history = useHistory();

  const { examId } = useParams<RouteParams>();
  const { user } = useUser();
  const { exam } = useExam({ id: examId });
  const { solveProblems } = useSolveProblems({
    id: user?.id,
    exam: examId,
  });

  if (!exam || !solveProblems) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  return (
    <>
      <Helmet
        title="상세 학습이력"
        keywords="학습이력"
        description="학습이력페이지 입니다."
      />
      <Layout title="교재 학습이력 상세보기" onBack={()=>history.push('/history-exam')}>
        <Flex p={3} px={6}>
          <Box w={"50px"} h={"50px"} bg="gray.100" mr={3}>
            <Center h="full" fontSize="xs">
              Book
            </Center>
          </Box>
          <Box>
            <Text fontWeight="500" mb={1}>
              {exam.book.name}
            </Text>
            <Text color="gray.400" fontSize={"12px"}>
              {moment(exam.created_at).format("YY.MM.DD")}
            </Text>
          </Box>
        </Flex>
        <Box flex={1} bg="gray.100" p={4}>
          <VStack w="full">
            {solveProblems
              .sort((a, b) => a.num - b.num)
              .map((el) => (
                <Flex
                  key={el.id}
                  bg="white"
                  shadow="sm"
                  w="full"
                  p={4}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Flex
                    justify="space-between"
                    d={{ base: "flex", md: "none" }}
                    mb={2}
                  >
                    <Center w="14">
                      <Text fontSize="14px">{`${el.problem.number}번`}</Text>
                    </Center>
                    <Link to={`/problem/${el.problem.id}`}>
                      <Button bg="primary" color="white" size="xs" rounded="sm">
                        문제보기
                      </Button>
                    </Link>
                  </Flex>
                  <Flex align="center" w="full">
                    <Center w="14" d={{ base: "none", md: "flex" }}>
                      <Text fontSize="14px">{`${el.problem.number}번`}</Text>
                    </Center>
                    <Box
                      fontSize="12px"
                      w="80px"
                      pl={3}
                      ml={2}
                      borderLeftWidth={1}
                    >
                      <Flex align="center" justify="space-between">
                        <Text color="gray.400">정답</Text>
                        <Text>{`${el.problem.answer}`}</Text>
                      </Flex>
                      <Flex align="center" justify="space-between">
                        <Text color="gray.400">배점</Text>
                        <Text>{`${el.problem.score}점`}</Text>
                      </Flex>
                    </Box>
                    <Box
                      fontSize="12px"
                      w="95px"
                      pl={3}
                      ml={2}
                      borderLeftWidth={1}
                    >
                      <Flex align="center" justify="space-between">
                        <Text color="gray.400">나의답</Text>
                        <Text>{`${el.selected || ""}`}</Text>
                      </Flex>
                      <Flex align="center" justify="space-between">
                        <Text color="gray.400">나의시간</Text>
                        <Text>{`${convertMinSep(el.solve_time)}`}</Text>
                      </Flex>
                    </Box>
                    <Box
                      fontSize="12px"
                      w="90px"
                      pl={3}
                      ml={2}
                      borderLeftWidth={1}
                    >
                      <Flex align="center" justify="space-between">
                        <Text color="gray.400">오답율</Text>
                        <Text>{`${el.answer}%`}</Text>
                      </Flex>
                      <Flex align="center" justify="space-between">
                        <Text color="gray.400">평균시간</Text>
                        <Text>{`${el.solve_time}`}</Text>
                      </Flex>
                    </Box>
                    <Spacer />
                    <Link to={`/problem/${el.problem.id}`}>
                      <Button
                        bg="primary"
                        color="white"
                        size="xs"
                        rounded="sm"
                        d={{ base: "none", md: "flex" }}
                      >
                        문제보기
                      </Button>
                    </Link>
                  </Flex>
                </Flex>
              ))}
          </VStack>
        </Box>
      </Layout>
    </>
  );
};

export default HistoryExamPage;

import { useLocation, Link } from "react-router-dom";
import { Text, HStack } from "@chakra-ui/react";

const TABS = [
  { value: "/history-book", name: "교재 학습이력" },
  { value: "/history-exam", name: "모의고사 이력" },
  { value: "/history-note", name: "오답이력" },
];

const HomeTab = () => {
  const location = useLocation();
  return (
    <HStack px={4}>
      {TABS.map((el) => (
        <Link to={el.value} key={el.value}>
          <Text
            p={2}
            borderBottomWidth={location.pathname === el.value ? 2 : 2}
            borderBottomColor={
              location.pathname === el.value ? "primary" : "transparent"
            }
            color={location.pathname === el.value ? "black" : "gray.400"}
            cursor="pointer"
            _hover={{ color: "gray.600" }}
            fontSize={{ base: "13px", md: "16px" }}
          >
            {el.name}
          </Text>
        </Link>
      ))}
    </HStack>
  );
};

export default HomeTab;

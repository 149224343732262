import React from "react";
import { Link } from "react-router-dom";
import {
  Flex,
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
} from "@chakra-ui/react";
import {
  FiUser,
  FiFileText,
  FiBook,
  FiCopy,
  FiEdit,
  FiSettings,
  FiVolume,
  FiHelpCircle,
} from "react-icons/fi";
import useUser from "@data/useUser";
import Avatar from "@components/Avatar";

interface Prop {
  onClose: () => void;
  isOpen: boolean;
}

const MENU = [
  { to: "/", label: "나의 포텐셜", Icon: FiUser },
  { to: "/history-book", label: "학습이력", Icon: FiFileText },
  { to: "/subscription", label: "교재 등록하기", Icon: FiBook },
  { to: "/questions", label: "프로그램문의", Icon: FiCopy },
  { to: "/posts", label: "자유게시판", Icon: FiEdit },
  { to: "/my-setting", label: "설정", Icon: FiSettings },
  { to: "/notice", label: "공지사항", Icon: FiVolume },
];

const MobileDrawer: React.FC<Prop> = ({ onClose, isOpen }) => {
  const { user } = useUser();
  return (
    <Drawer placement={"right"} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            <Flex alignItems="center" pt={8} pb={2}>
              <Avatar user={user} />
              <Box fontSize={"14px"} ml={2}>
                <Text>
                  {user.username}{" "}
                  <Text as="span" color="gray.500" fontSize={"12px"}>
                    {user.email}
                  </Text>
                </Text>
                <Text color="gray.500">오늘 하루 화이팅!</Text>
              </Box>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            {MENU.map((el) => (
              <Link to={el.to} key={el.label} onClick={onClose}>
                <Flex w="100%" alignItems="center" py={3}>
                  <el.Icon size={20} color="gray" />
                  <Text fontSize={16} ml={4} color="gray.500">
                    {el.label}
                  </Text>
                </Flex>
              </Link>
            ))}
            <Flex
              as="a"
              w="100%"
              alignItems="center"
              py={3}
              href="https://www.notion.so/6b4a80f8a05e4874ab81525d803691d5"
              target="_blank"
            >
              <FiHelpCircle size={20} color="gray" />
              <Text fontSize={16} ml={4} color="gray.500">
                고객센터
              </Text>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default MobileDrawer;

import { Text } from "@chakra-ui/react";
import { useLocation, Link } from "react-router-dom";
import { HStack } from "@chakra-ui/react";

const TABS = [
  { value: "/my-post", name: "나의 게시판" },
  { value: "/my-question", name: "나의 프로그램문의" },
  { value: "/my-setting", name: "설정" },
];

const MyTab = () => {
  const location = useLocation();
  return (
    <HStack px={4}>
      {TABS.map((el) => (
        <Link to={el.value} key={el.value}>
          <Text
            p={2}
            borderBottomWidth={location.pathname === el.value ? 2 : 2}
            borderBottomColor={
              location.pathname === el.value ? "primary" : "transparent"
            }
            color={location.pathname === el.value ? "black" : "gray.400"}
            cursor="pointer"
            _hover={{ color: "gray.600" }}
            fontSize={{ base: "13px", md: "16px" }}
          >
            {el.name}
          </Text>
        </Link>
      ))}
    </HStack>
  );
};

export default MyTab;

import axios from "axios";
import { CommentPost, CommentPut } from "../types/comment";

export const addComment = (body: CommentPost) => {
  return axios.post(`comments`, body);
};

export const updateComment = ({
  id,
  body,
}: {
  id: string;
  body: CommentPut;
}) => {
  return axios.put(`comments/${id}`, body);
};

export const deleteComment = (id: string) => {
  return axios.delete(`comments/${id}`);
};

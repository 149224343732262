import {
  Flex,
  Box,
  Text,
  Center,
  CircularProgress,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
} from "@chakra-ui/react";
import Helmet from "@layouts/Helmet";
import Layout from "@layouts/Layout";
import moment from "moment";
import useNotices from "@data/useNotices";
import { useHistory } from "react-router";

const NoticePage = () => {
  const history = useHistory();
  const { setSize, notices, isReachingEnd, isLoading } = useNotices();

  if (!notices) {
    return (
      <Center h={"100vh"}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  const handleMore = () => setSize((value: number) => value + 1);

  return (
    <>
      <Helmet
        title="공지사항"
        keywords="공지사항"
        description="공지사항페이지 입니다."
      />
      <Layout title="공지사항" onBack={()=>history.push('/')}>
        <Accordion allowMultiple>
          {notices.map((el) => (
            <AccordionItem el={el.id}>
              <AccordionButton>
                <Flex w="full" justify="space-between" align="center">
                  <Flex flexDir="column" align="flex-start" textAlign="left">
                    <Text fontSize="sm">{el.title}</Text>
                    <Text color="gray.400" fontSize="12px">
                      {moment(el.created_at).format("YY-MM-DD")}
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </Flex>
              </AccordionButton>
              <AccordionPanel>
                <Box pt={2}>{el.desc}</Box>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
        {!isReachingEnd && !isLoading && (
          <Flex justify="center" my={4}>
            <Button bg="primary" color="white" onClick={handleMore}>
              더보기
            </Button>
          </Flex>
        )}
      </Layout>
    </>
  );
};

export default NoticePage;

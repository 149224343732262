import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Flex, IconButton, Button, Text, Box,   useDisclosure} from "@chakra-ui/react";
import { AiOutlineSliders } from "react-icons/ai";
import { FiChevronLeft } from "react-icons/fi";
import CloseModal from "@components/modal/CloseModal";

interface Prop {
  onOpen: () => void,
  onBack: () => void;
}

const TestHeader: React.FC<Prop> = ({ onOpen, onBack }) => {
  const history = useHistory();
  const doingModal = useDisclosure();

  return (
    <>
    <CloseModal
        isOpen={doingModal.isOpen}
        onClose={doingModal.onClose}
        callback={onBack}
        cancel={()=> {
        }}
      />
    <Flex
      position="sticky"
      top="0"
      bg="white"
      h="44px"
      zIndex={100}
      align="center"
      px={2}
      justify="space-between"
    >
      <Flex flex={1}>
        <IconButton
          onClick={doingModal.onOpen}
          aria-label="back"
          icon={<FiChevronLeft />}
          variant="link"
          fontSize={20}
        />
      </Flex>
      <Link to={"/"}>
        <Text flex={1} fontWeight="bold" fontSize="lg" textAlign="center">
          학습하기
        </Text>
      </Link>
      <Flex flex={1} justifyContent="flex-end">
      <Box
  as="button" onClick={onOpen} 
      color="black.900"
      height="24px"
      bg="white"
      borderRadius="20px"
      padding="7px"
      paddingTop="4px"
      borderWidth={1}
      borderColor="gray.400"
      fontSize="10px"
      _hover={{ bg: "gray.100", opacity: 0.8 }}
        >문항바로가기</Box>
        
        {/* <IconButton
          onClick={onOpen}
          aria-label="notification"
          icon={<AiOutlineSliders />}
          variant="link"
          fontSize={20}
        /> */}
      </Flex>
    </Flex>
    </>
  );
};

export default TestHeader;
